import {
  Button,
  Input,
  message,
  Modal,
  Upload,
  Collapse,
  Select,
  Spin,
  Popover,
  notification,
} from "antd";
import React, { useContext, useRef, useState } from "react";
import { FaArrowLeft, FaMinus } from "react-icons/fa";
// import VideoAdd30 from "../../../components/VideoAdd30";
import { v4 as uuidv4 } from "uuid";

import { BsArrowDownShort, BsFillCloudUploadFill } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import TextArea from "antd/lib/input/TextArea";
import { AiOutlineClose } from "react-icons/ai";
import ReactPlayer from "react-player";
import { MdClose } from "react-icons/md";
import SearchContext from "../../../Context/SearchContext";
const { Option } = Select;
const { Dragger } = Upload;
const { Panel } = Collapse;

let all = [];
export default function UploadAssets({ isHome, type2 }) {
  const playerRef = useRef();

  const {
    setFormType,
    setassist,
    assist,
    organization,
    setOrganization,
    pActive,
    right,
    setRight,
    user,
    images,
    setImages,
  } = useContext(SearchContext);
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purpose, setPurpose] = useState();
  const [UploadedFile, setUplaoded] = useState([]);
  const [type, setType] = useState("image");
  const [mData, setMData] = useState("");
  const [videoErr, setVideoErr] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [lengthS, setLengthS] = useState(0);
  const [remainingUploads, setRemainingUploads] = useState(0);
const [completedUploads, setCompletedUploads] = useState(0);

  const saveFileTune = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.originFileObj);

    formData.append("purpose", "misc");
    formData.append("headclip_id", id);
    formData.append("meta_data", mData);
    formData.append("media_type", "fine_tune");
    formData.append("user_id", user ? user.user_id : "map");

    setDisplay(false);
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/upload`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setUplaoded([]);
        setFileList([]);
        setImageUrl();
        setMData("");
        setLoading(false);
        if (data.message === "Insufficient compute available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        if (data && data.isSuccess) {
          if (i + 1 === UploadedFile.length) {
            const allimgs = images && images.tune ? images.tune : [];
            allimgs.push(data.data);
            setImages({ ...images, tune: allimgs });
            all.push(data.data);
            if (organization) {
              setOrganization({ ...organization, compute: data.compute });
            }
          } else {
            all.push(data.data);
          }
        } else if (data.message === "Detected as adult or racy content") {
          notification.error({
            message:
              "We couldn't accept this visual content at this moment. Please contact us if there are any questions ",
          });
        } else if (data.message === "video_duration is required") {
          notification.error({
            message:
              "Upload Failed. Please try again. ",
          });
        }
      });
  };
  const saveFile = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.originFileObj);
    formData.append("assistant_id", pActive ? pActive._id : "");

    formData.append("purpose", "misc");
    formData.append("headclip_id", id);
    formData.append("meta_data", mData);
    formData.append("media_type", "visual");
    formData.append("user_id", user ? user.user_id : "map");

    setDisplay(false);
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/upload`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setUplaoded([]);
        setFileList([]);
        setImageUrl();
        setMData("");
        setLoading(false);
        if (data.message === "Compute is not available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        if (data && data.isSuccess) {
          if (i + 1 === UploadedFile.length) {
            const allimgs = images && images.data ? images.data : [];
            allimgs.unshift(data.data);
            setImages({ ...images, data: allimgs });
            all.push(data.data);
            setassist({
              ...assist,
              upImage: undefined,
              uploadView: undefined,
              selAssetID: data.data,
              selAssetID2: undefined,
              isPopup: false,
            });

            setRight({
              ...right,
              activeIcon: right.upOpen ? right.activeIcon : "chat",
              isUpload: undefined,
              activeAI: right.upOpen ? right.activeAI : "message",
            });
            if (organization) {
              setOrganization({ ...organization, compute: data.compute });
            }
          } else {
            all.push(data.data);
          }
        } else if (data.message === "Detected as adult or racy content") {
          notification.error({
            message:
              "We couldn't accept this visual content at this moment. Please contact us if there are any questions ",
          });
        }
      });
  };
  const saveCSV = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.originFileObj);
    formData.append("assistant_id", pActive ? pActive._id : "");

    formData.append("user_id", user ? user.user_id : "map");

    setDisplay(false);
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://api.axv.ai/context/upload_csv`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Compute is not available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        setRight({
          ...right,
          activeIcon: right.upOpen ? right.activeIcon : "chat",
          isUpload: undefined,
          activeAI: right.upOpen ? right.activeAI : "message",
        });

        if (data && data.isSuccess) {
          if (i + 1 === UploadedFile.length) {
            setassist({
              ...assist,

              isPopup: false,
            });
            setUplaoded([]);
            setFileList([]);
            setMData("");
            setLoading(false);
            if (organization) {
              setOrganization({ ...organization, compute: data.compute });
            }
            message.success("CSV saved in assets. ");
          }
        }
      });
  };
  const saveVideo = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.originFileObj);
    formData.append("assistant_id", pActive ? pActive._id : "");

    formData.append("video_duration", lengthS);
    formData.append("purpose", "video");
    formData.append("meta_data", mData);
    formData.append("user_id", user ? user.user_id : "map");

    setDisplay(false);
    const vidId = Math.random();

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/image/upload/video`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message === "Insufficient compute available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        if (data.message === "video_duration is required") {
          message.success("Upload Failed. Please try again.");
        }
        
        if (data && data.isSuccess) {
          if (i + 1 === UploadedFile.length) {
            const allvid = images && images.videos ? images.videos : [];
            allvid.unshift(data.data);
            setImages({ ...images, videos: allvid });
            setassist({
              ...assist,
              isPopup: false,
              selAssetID2: undefined,
              selAssetID: undefined,
              selAssetID2Load: data.data,
            });
            setRight({
              ...right,
              activeIcon: right.upOpen ? right.activeIcon : undefined,
              isUpload: undefined,
              activeAI: right.upOpen ? right.activeAI : "message",
            });

            setTimeout(
              () =>
                setassist({
                  ...assist,
                  isPopup: false,
                  selAssetID2: data.data,
                  selAssetID2Load: undefined,
                  selAssetID: undefined,
                }),
              12000
            );
            if (organization) {
              setOrganization({ ...organization, compute: data.compute });
            }

            setUplaoded([]);
            setFileList([]);
            setLengthS(0);
            setMData("");
            setLoading(false);
            message.success("Uploading Your Video...");
          }
        }
      });
  };

  const getVideoLength = (vLength) => {
    setLengthS(vLength);
    // if (vLength / 60 > 3) {
    //   setUplaoded([]);
    //   setVideoErr(true);
    // } else {
    //   setVideoErr(false);
    // }
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const props = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
      // console.log(.url);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };

  const handleBeforeUpload = (file, fileList) => {
    setFileList([file]); // save the file object in state for later use
    return false; // don't actually upload the file
  };

  const renderFileName = () => {
    if (fileList.length > 0) {
      const file = fileList[0];
      return (
        <>
          {imageUrl && type === "image" ? (
            <img className="img-prev-ibc" src={imageUrl} alt="" />
          ) : imageUrl && type === "video" ? (
            <div className="vd-nm-pr img-prev-ibc">
              <ReactPlayer url={imageUrl} controls={false} />
            </div>
          ) : imageUrl && type === "fine_tune" ? (
            <img className="img-prev-ibc" src={imageUrl} alt="" />
          ) : (
            <span>{file.name}</span>
          )}
        </>
      );
    } else {
      return <span>Drag or click to upload</span>;
    }
  };

  const removeFile = () => {
    setFileList([]);
  };

  function handleRemove() {
    setImageUrl(null);
    message.success("Image removed successfully!");
  }

  return (
    <div>
      <div className={`upload-pop-mbc `}>
        {/* {display30 ? <VideoAdd30 setDisplay30={(v) => setDisplay30(v)} /> : null} */}
        {/* {renderType === "assets" ? ( */}
        {right.upOpen ? (
          <div className="close-as-bn">
            <MdClose onClick={() => setRight({ ...right, upOpen: false })} />
          </div>
        ) : null}
        {/* ) : null} */}
        {isHome ? (
          <div className="ty-nw-bx-tnx">
            <b>Try Now</b>
            <BsArrowDownShort className="arrow-dwn" />
          </div>
        ) : null}

        <div className="upload-bx-mnn01">
          <div className="upload-bx-mnn">
            <div
              className="bx-main-sel-prf-attach"
              style={{ gridColumn: "1 / span 1" }}
            >
              <div className="bx-main-sel-prf-attach21">
                <span
                  onClick={() => {
                    setType("image");
                    setImageUrl();
                    setUplaoded([]);
                    setFileList([]);
                  }}
                  className={`btn-appr select-tcbn-a ${
                    type === "image" ? " btn-apr-s" : ""
                  }`}
                >
                  <span>Images</span>
                </span>
                <span
                  onClick={() => {
                    setType("video");
                    setUplaoded([]);
                    setImageUrl();
                    setFileList([]);
                  }}
                  className={`btn-appr select-tcbn-a   ${
                    type === "video" ? " btn-apr-s" : ""
                  } `}
                >
                  <span>Videos</span>
                </span>

                {isHome ? null : (
                  <span
                    onClick={() => {
                      setType("fine_tune");
                      setUplaoded([]);
                      setImageUrl();
                      setFileList([]);
                    }}
                    className={`btn-appr select-tcbn-a   ${
                      type === "fine_tune" ? " btn-apr-s" : ""
                    } `}
                  >
                    <span>Fine Tune</span>
                  </span>
                )}
              </div>
            </div>
            <div
              className="vid-ad-vx tag-grou-lake-c"
              style={{ gridColumn: "2 / span 1", marginBottom: "1px" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {user && user.id ? (
                  <Dragger
                    showUploadList={false}
                    maxCount={1}
                    beforeUpload={handleBeforeUpload}
                    {...props}
                  >
                    {renderFileName()}
                  </Dragger>
                ) : (
                  <div
                    onClick={() => {
                      setFormType("signup");
                    }}
                    className="no-login-up"
                  >
                    Drag or click to upload
                  </div>
                )}
                {/* {videoErr && type === "video" ? (
                  <h6>Video is longer than 3 minutes</h6>
                ) : null} */}
              </div>
              <div className="up-bx-mnbcs">
                {UploadedFile.length ? (
                  <Button type="link" onClick={removeFile}>
                    Change
                  </Button>
                ) : null}
                {UploadedFile.length ? (
                  loading ? (
                    <Spin />
                  ) : (
                    <button
                    className="custom-button"
                      onClick={() => {
                        setLoading(true);
                        all = [];

                        let id = uuidv4();
                        for (let i = 0; i < UploadedFile.length; i++) {
                          if (type2 === "fine_tune") {
                            saveFileTune(UploadedFile[i], i, id);
                          } else if (type === "image") {
                            saveFile(UploadedFile[i], i, id);
                          } else if (type === "video") {
                            saveVideo(UploadedFile[i], i, id);
                          } else {
                            saveCSV(UploadedFile[i], i, id);
                          }
                        }
                      }}
                    >
                      Attach
                    </button>
                  )
                ) : null}
              </div>
              <br />
            </div>
            {UploadedFile.length ? (
              <div className="pic-2vid0c">
                <ReactPlayer
                  url={URL.createObjectURL(UploadedFile[0].originFileObj)}
                  className=""
                  controls
                  playing
                  muted
                  ref={playerRef}
                  onProgress={(e) => getVideoLength(e.loadedSeconds)}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

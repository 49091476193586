import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { FaUser, FaLock } from "react-icons/fa";
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  message,
  notification,
  Modal,
  Spin,
} from "antd";

import { Link, useNavigate } from "react-router-dom";
import { AiFillMail } from "react-icons/ai";
import bcrypt from "bcryptjs";
import GreenTick from "../../GreenTick";
import SearchContext from "../../../Context/SearchContext";

const NormalLoginForm = () => {
  const {
    assist,
    setassist,
    setLoading,
    setUser,
    user,
    loginChange,
    setLoginChange,
    resetAll,
    setFormType,
    setOrganization,
  } = useContext(SearchContext);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [verifyD, setVerifyD] = useState(false);
  const [res, setRes] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [vError, setVError] = useState();
  const [u, setU] = useState();
  const [loadingS, setloadingS] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    setloadingS(true);
    addUserLogin(form);
  };

  const addUserLogin = (user) => {
    const pass = bcrypt.hashSync(
      user.password,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    const obj = {
      password: pass,
      email: user.email,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/consumer/auth/login`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        // if (response && response.isLogged && response.email_verified) {
        if (response && response.isLogged) {
          window.localStorage.setItem("user", JSON.stringify(response));
          setForm({
            email: "",
            password: "",
          });
          navigate("/")
          setFormType();
          setLoading(false);
          setUser(response);
          resetAll();
          // setPActive();
          setLoginChange(!loginChange);
          getOrganization(response);

          // } else if (!response.email_verified && response.isLogged) {
          //   setVerifyD(true);
          //   setU(response);
        } else {
          notification.error({
            message: "Error",
            description: response.msgError,
          });
        }
        setloadingS(false);
      });
  };

  const changeField = (key, val, row) => {
    // console.log(key, val, row);
    row[key] = val;
    setForm({ ...form, [key]: val });
  };

  const resentCode = () => {
    const obj = {
      email: form.email,
      verification_for: "new_registration",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/verification/resend`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setRes(true);
        }
      });
  };

  const verifyEmail = () => {
    const obj = {
      code: Number(verifyCode),
      email: form.email,
      verification_for: "new_registration",
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/verification/verify`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let usr = u;
          usr.email_verified = true;
          window.localStorage.setItem("user", JSON.stringify(usr));
          setForm({
            email: "",
            password: "",
          });
          setFormType();
          setLoading(false);
          setUser(usr);
        } else {
          setVError(response.msgError);
        }
      });
  };
  const getOrganization = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/organization/${u.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let org = response.organization;
          setOrganization(org);
        }
      });
  };
  return (
    <>
      <Form onSubmit={handleSubmit} className="login-form">
        <h4>Log In</h4>
        <Form.Item>
          <Input
            onChange={(e) =>
              changeField("email", e.target.value.toLowerCase(), form)
            }
            size="large"
            prefix={<AiFillMail className="input-icon-l" />}
            placeholder="Email"
            value={form.email}
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            onChange={(e) => changeField("password", e.target.value, form)}
            size="large"
            prefix={<FaLock className="input-icon-l" />}
            type="password"
            placeholder="Password"
            value={form.password}
          />
        </Form.Item>
        <Form.Item>
          <div className="for-reg-c">
            <span
              onClick={() => setFormType("forget")}
              className="login-form-forgot"
            >
              Forgot Password
            </span>
            <div>
              Or{" "}
              <Link to="#" onClick={() => setFormType("signup")}>
                Sign Up
              </Link>
            </div>
          </div>
          <div className="login-btn-c">
            {loadingS ? (
              <Spin />
            ) : (
              <button
                onClick={handleSubmit}
              
                htmlType="submit"
                className="custom-button"
              >
                Log in
              </button>
            )}
          </div>
        </Form.Item>
      </Form>
      <Modal
        width={400}
        className="no-h-bo-b-modal no-close-model"
        open={verifyD}
      >
        <div className="verifybtn-c">
          <h5> Enter Verify Code</h5>
          <p>
            Please check your email <b>{form.email}</b> for verification code
          </p>
          <input
            onChange={(e) => setVerifyCode(e.target.value)}
            className={`form-control ${vError ? " error-input" : ""} `}
            type="number"
            value={verifyCode}
            placeholder="Verify code"
          />
          {vError ? <i>{vError}</i> : null}
          <div className="code-veri-lg">
            {res ? (
              <div>
                <GreenTick width="30px" height="30px" />
              </div>
            ) : (
              <Button onClick={resentCode} type="link">
                Resend Code
              </Button>
            )}
            <Button onClick={verifyEmail}>Verify</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NormalLoginForm;

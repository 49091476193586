import {
  Table,
  DatePicker,
  Spin,
  Tag,
  Modal,
  message,
  Button as ButtonA,
  Alert,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import SearchContext from "../../Context/SearchContext";
const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";

export default function MyTeam() {
  const { user, setUser, organization } = useContext(SearchContext);

  const [team, setTeam] = useState();
  const [teamInvite, setTeamInvite] = useState([]);
  const [loadeing, setLoadeing] = useState(true);
  const [loadV, setLoadV] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageNo2, setPageNo2] = useState(1);
  const [callAPI, setCallAPI] = useState(1);
  const [invite, setInvite] = useState();
  const [err, setErr] = useState();
  const [status, setStatus] = useState("");
  const [dDelete, displayDelete] = useState();
  const [RLoadig, setRLoadig] = useState(false);
  const [LoadingS, setLoadingS] = useState(false);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      getTeam(u);
      setLoadeing(true);
    }
  }, [pageNo, callAPI]);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      getInvites(u);
    }
  }, [pageNo2, status]);

  const getTeam = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/consumer/all?page=${pageNo}&limit=10&user_id=${user.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setTeam(data);
        }
        setLoadeing(false);
        console.log(data);
      });
  };
  const getInvites = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/invite?page=${pageNo2}&limit=10&user_id=${
        user.user_id
      }&sort_by=${-1}&status=${status}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setTeamInvite(data);
        }
        setLoadingS(false);
      });
  };

  const editeamInvite = (obj, row) => {
    row.permission = obj.permission;
    const all = teamInvite.inviteList.map((t) => (t._id === row._id ? row : t));
    setTeamInvite({ ...teamInvite, inviteList: all });
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/invite/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isPatched) {
          message.success("Updated");
        } else {
          message.error("Something went wrong");
        }
      });
  };
  const editeam = (obj, row) => {
    row.permission = obj.permission;
    const all = team.consumerList.map((t) => (t._id === row._id ? row : t));
    setTeam({ ...team, consumerList: all });
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-auth-token": user.ACCESS_TOKEN,
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://embed.axvi.ai/api/consumer/${row._id}/patch`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isPatched) {
          message.success("Updated");
          if (user.email === row.email) {
            setUser({ ...user, permission: obj.permission });
          }
        } else {
          message.error("Something went wrong");
        }
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => (
        <b>
          {user.name === text ? (
            <span className="tag-name-match">
              {text}
              <Tag color="#DEF7F5">You </Tag>
            </span>
          ) : (
            text
          )}
        </b>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, row) => <b>{text}</b>,
    },
    {
      title: "Permission",
      dataIndex: "permission",
      key: "permission",
      render: (text, row) => (
        <Form.Select
          value={text}
          aria-label="Default select example"
          onChange={(v) => editeam({ permission: v.target.value }, row)}
        >
          <option value="analyst">Analyst</option>
          <option value="owner">Owner</option>
          <option value="viewer">Viewer</option>
          <option value="editor">Editor</option>
        </Form.Select>
      ),
    },
  ];

  const columns2 = [
    {
      title: "Date Invited",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, row) => <b>{moment(text).format("MMMM Do yy")}</b>,
    },
    {
      title: "Email",
      dataIndex: "invite_to",
      key: "invite_to",
      render: (text, row) => <b>{text}</b>,
    },
    {
      title: "Permission",
      dataIndex: "permission",
      key: "permission",
      render: (text, row) => (
        <Form.Select
          value={text}
          aria-label="Default select example"
          onChange={(v) => editeamInvite({ permission: v.target.value }, row)}
        >
          <option value="analyst">Analyst</option>
          <option value="owner">Owner</option>
          <option value="viewer">Viewer</option>
          <option value="editor">Editor</option>
        </Form.Select>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => (
        <span>
          {text === "pending" ? <Tag color="#9c28ff">Pending</Tag> : null}
          {text === "accepted" ? <Tag color="green">Accepted</Tag> : null}
          {text === "rejected" ? <Tag color="red">Rejected</Tag> : null}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "02",
      render: (text, row) => (
        <span>
          {row._id === RLoadig ? (
            <Spin />
          ) : (
            <Tag
              onClick={() => setResendInvite(row)}
              className="hover"
              color="black"
            >
              Resend
            </Tag>
          )}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "01",
      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            Delete
          </Tag>
        </span>
      ),
    },
  ];

  const deleteInvite = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/invite/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isDeleted) {
          const deleted = teamInvite.inviteList.filter((f) => f._id !== id);
          setTeamInvite({ ...teamInvite, inviteList: deleted });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  const checkForm = () => {
    if (
      invite.invite_to.trim().length === 0 ||
      invite.permission.trim().length === 0 ||
      invite.name.trim().length === 0
    ) {
      setErr(true);
    } else {
      inviteNew();
    }
  };

  const setResendInvite = (r) => {
    setRLoadig(r._id);
    const obj = {
      email_target: r.invite_to,
      organization_name: organization.business_name,
      sender_email: r.invite_by,
      target_name: r.name,
      template_id: "invite_user",
      user_id: r.user_id,
    };
    console.log(r, obj);
    sendMail(obj, true);
  };
  const inviteNew = () => {
    const obj = invite;
    obj.user_id = user.user_id;
    obj.invite_by = user.email;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/invite`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isCreated) {
          setTeamInvite({
            ...teamInvite,
            inviteList: [...teamInvite.inviteList, data.invite_payload],
          });
          sendMail(invite);
        }
        setInvite();
        setErr();
      });
  };
  const sendMail = (row, r) => {
    let obj = {};
    if (r) {
      obj = row;
    } else {
      obj = {
        target_name: row.name,
        email_target: row.invite_to,
        sender_email: user.email,
        template_id: "invite_user",
        organization_name: organization.business_name,
        user_id: user.user_id,
      };
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/email/send`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (r) {
          if (data && data.isCreated) {
            message.success("Email Sent Successfully");
          } else {
            message.error("Something Went Wrong");
          }
        }

        setRLoadig(false);
      });
  };

  if (organization.subscription !== "Business") {
    return (
      <div className="bx-cls-bmbs">
              <div
                className="no-heigh-div-c full-h-bx-a"
                style={{
                  width: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://billing.stripe.com/p/login/00gfZz7uLeaH50Y288"
                  target="_blank"
                >
                  <Alert
                    message="Please Upgrade Account"
                    description={
                      <div>
                        <div>
                         Please upgrade account to enable teams.
                        </div>
                      </div>
                    }
                    type="info"
                    showIcon
                    className="brand-alert bg-txt-a"
                  />
                </a>
              </div>
            </div>
    );
  }

  return (
    <div className="compute-tab-c">
      {loadeing ? (
        <div>
          <Spin />
        </div>
      ) : null}
      {team ? (
        <div>
          <div className="add-new-pro-c flex-end">
            <Button
              onClick={() => {
                setInvite({
                  invite_to: "",
                  permission: "",
                  name: "",
                  status: "pending",
                });
              }}
            >
              Invite
            </Button>
          </div>
          <Table
            className="brand-table"
            pagination={{
              pageSize: 15,
              current: pageNo,
              total: team.totalPages * 10,
              onChange: (v) => setPageNo(v),
              size: "small",
              showSizeChanger: false,
            }}
            columns={columns}
            dataSource={team.consumerList}
          />
        </div>
      ) : null}

      {teamInvite ? (
        <div>
          <div className="filter-table-v">
            <div className="status-bx-invt">
              {LoadingS ? (
                <Spin />
              ) : (
                <Form.Select
                  value={status}
                  aria-label="Default select example"
                  onChange={(v) => {
                    setStatus(v.target.value);
                    setPageNo2(1);
                    setLoadingS(true);
                  }}
                >
                  <option value="">All</option>
                  <option value="success">Success</option>
                  <option value="pending">Pending</option>
                </Form.Select>
              )}
            </div>
          </div>
          <Table
            className="brand-table"
            pagination={{
              pageSize: 15,
              current: pageNo2,
              total: teamInvite.totalPages * 10,
              onChange: (v) => {
                setPageNo2(v);
              },
              size: "small",
              showSizeChanger: false,
            }}
            columns={columns2}
            dataSource={teamInvite.inviteList}
          />
        </div>
      ) : null}
      <Modal
        className="no-h-bo-b-modal brand-modal"
        open={invite !== undefined}
        onCancel={() => {
          setInvite();
          setErr();
        }}
        width={450}
      >
        <h5 className="create-btn-h">Invite User</h5>
        {invite ? (
          <div className="invite-bx-x bx-form-brand">
            <p className="invite-message">
              Once your team member signs up, they will be directed to your invitation.
            </p>
            <Form.Control
              value={invite.name}
              onChange={(v) => setInvite({ ...invite, name: v.target.value })}
              type="text"
              placeholder="Name"
              className={`${
                err && invite.name.trim().length === 0 ? "err-input-c" : ""
              }`}
            />
            {err && invite.name.trim().length === 0 ? (
              <p className="err-txt-bx">Name Required</p>
            ) : null}
            <Form.Control
              value={invite.invite_to}
              onChange={(v) =>
                setInvite({ ...invite, invite_to: v.target.value })
              }
              type="email"
              placeholder="Email"
              className={`${
                err && invite.invite_to.trim().length === 0 ? "err-input-c" : ""
              }`}
            />
            {err && invite.invite_to.trim().length === 0 ? (
              <p className="err-txt-bx">Email Required</p>
            ) : null}
            <Form.Select
              value={invite.permission}
              aria-label="Default select example"
              onChange={(v) =>
                setInvite({ ...invite, permission: v.target.value })
              }
              className={`${
                err && invite.permission.trim().length === 0
                  ? "err-input-c"
                  : ""
              }`}
            >
              <option value="" disabled>
                Select Permission
              </option>
              <option value="analyst">Analyst</option>
              <option value="owner">Owner</option>
              <option value="viewer">Viewer</option>
              <option value="editor">Editor</option>
            </Form.Select>
            {err && invite.permission.trim().length === 0 ? (
              <p className="err-txt-bx">Permission Required</p>
            ) : null}
            <div className="invite-btn-bx">
              <ButtonA
                type="primary"
                className="black-button"
                onClick={checkForm}
              >
                Invite
              </ButtonA>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        width={400}
        open={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Invitation</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
import React, { useContext } from "react";
import SearchContext from "../../../../Context/SearchContext";
import Icons from "../../../../components/Icons";
import { Button } from "antd";
import {
  MdInsights,
  MdOutlineCreate,
  MdOutlineImageSearch,
} from "react-icons/md";
import { BsCollectionPlayFill, BsMagic } from "react-icons/bs";
import { FaUser } from "react-icons/fa";

export default function Pricing() {
  const { theme, setOrganization, organization, user } =
    useContext(SearchContext);

  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };

  const logout = () => {
    window.localStorage.removeItem("user");
    window.location.reload();
  };

  return (
    <div className="onb-main-bx">
      <div>
        <div className="onb-main-hv">
          <img
            src={Icons["white-logo"]}
            alt=""
            style={{ width: "30px", height: "30px" }}
          />
        </div>
        <div className="onb-m-new">
          <div>
            <div className="row bx-main-dgbcs">
              <h2>Olm Pro</h2>

              <div className="icons-bx-main-ne mn-bvckks">
                <div>
                  <MdOutlineImageSearch />
                  <b>Search</b>
                </div>
                <div>
                  <MdOutlineCreate />
                  <b>Generate</b>
                </div>

                <div>
                  <BsMagic />

                  <b>Create</b>
                </div>
                <div>
                  <FaUser />
                  <b>Per User</b>
                </div>
              </div>
              <div className="icons-bx-main-ne mn-bvckks">
                <div>
                  <b>Unlimited Queries</b>
                </div>
                <div>
                  <b>100 GB of Assets</b>
                </div>

              
                <div>
                  <b>API - $0.005 Per Response</b>
                </div>
                <div>
                  <b>$3/GB After 100GB</b>
                </div>
              </div>
              <b>
                <span style={{ fontSize: "24px" }}>$25.00 Per Month</span>
              </b>
            </div>
          </div>
        </div>
      </div>
      <div className="onb-m-bot">
        <Button
          onClick={() => {
            logout();
          }}
          type="primary"
        >
          Sign Out
        </Button>
        <div>
          <a style={{marginRight:"20px"}} href="https://buy.stripe.com/7sI6px7ai8Wj71SdR5" target="_blank">
            <Button className="glow-on-hover" type="primary">
              Get Started
            </Button>
          </a>
          <Button
            onClick={() => {
              updateOrganization("onboard", "thanks");
              setOrganization({ ...organization, onboard: "thanks" });
            }}
            type="primary"
          >
            Skip
          </Button>
        </div>
      </div>
    </div>
  );
}

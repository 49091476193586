import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import SearchContext from "../../../Context/SearchContext";
import {
  AiOutlineBgColors,
  AiOutlineClose,
  AiOutlineUpload,
} from "react-icons/ai";
import {
  MdClose,
  MdMenuBook,
  MdOutlineCode,
  MdHighlightOff,
  MdOutlineCheckCircleOutline,
} from "react-icons/md";
import { BiSolidVideos } from "react-icons/bi";
import { FaVideoSlash, FaUpload, FaMusic } from "react-icons/fa";
import { Alert, Card, Modal, Spin, Switch, Tooltip } from "antd";
import {
  BsDatabaseCheck,
  BsFillCassetteFill,
  BsFillCloudUploadFill,
  BsGlobe,
} from "react-icons/bs";
import UploadAssets from "./UploadAssets";
import { RiAccountBoxFill, RiPhoneLine } from "react-icons/ri";

import GeneratedVideos from "./GeneratedVideos";
import telemitLogo from "../../../images/telemitlogo.png";
import ReactPlayer from "react-player";
import { GrFormClose } from "react-icons/gr";
import ReactAudioPlayer from "react-audio-player";

export default function ThemeSelection() {
  const {
    type,
    setTheme,
    left,
    setLeft,
    setRight,
    right,
    setFormType,
    user,
    setDisplay,
    setassist,
    assist,
  } = useContext(SearchContext);
  const [display, setDispla1] = useState(false);
  const [open, setOpen] = useState(false);
  const [play, setPlay] = useState();

  const [colors, setColors] = useState([
    { n: "black", c: " #171717" },
    { n: "grey", c: "grey" },
    { n: "brown", c: "#C4A484" },
    { n: "purple", c: "#E39FF6" },
    { n: "green", c: "#F0FFF0" },
    { n: "blue", c: "#E3F2FD" },
    { n: "white", c: "#F6EEE3" },
  ]);
  return user && user.name ? (
    <div className="theme-main-divs theme-main-divs21">
      {display ? (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="colors-bx-main-d"
        >
          {colors.map((c, ci) => {
            return (
              <div
                onClick={() => {
                  setTheme(c.n);
                  window.localStorage.setItem("theme", c.n);
                }}
                style={{ backgroundColor: c.c }}
                key={ci}
              />
            );
          })}{" "}
          <MdClose onClick={() => setDispla1(false)} />
        </motion.div>
      ) : (
        <div>
          {assist.selectedGame ? (
            <Alert
              message={
                <Tooltip color="white" title=" Selected Game">
                  <div>
                    <img
                      width={15}
                      style={{ marginRight: "5px" }}
                      src={telemitLogo}
                      alt=""
                    />
                    {assist.selectedGame.title}
                  </div>
                </Tooltip>
              }
              type="info"
              closable
              className="my-closeable-alert"
              onClose={() =>
                setassist({
                  ...assist,
                  selectedGame: undefined,
                })
              }
            />
          ) : null}

          <Tooltip color="white" title="Your Connected Games">
            <b
              onClick={() => {
                setLeft({
                  ...left,
                  flow: "games",
                  gameTab: "",
                });
                setRight({ ...right, upOpen: undefined });
              }}
            >
              <img width={30} src={telemitLogo} alt="" />
            </b>
          </Tooltip>

          {right.upOpen ? (
            <UploadAssets setOpen={setOpen} type2={type} />
          ) : (
            <Tooltip color="white" title="Upload">
              <b
                onClick={() => {
                  if (user && user.name) {
                    setRight({ ...right, upOpen: true });
                  } else {
                    setFormType("signup");
                  }
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <FaUpload
                    className="hide-mobile"
                    style={{ fontSize: "1.8em", color: "#ffbe5a" }}
                  />
                </div>
              </b>
            </Tooltip>
          )}

          {right.upOpen ? null : (
            <Tooltip color="white" title="Assets">
              <b
                onClick={() => {
                  if (user && user.name) {
                    setLeft({
                      ...left,
                      flow: "assets",
                    });
                  } else {
                    setFormType("signup");
                  }
                }}
              >
                <BsDatabaseCheck
                  className="hide-mobile"
                  style={{ fontSize: "1.8em", color: "#ffbe5a" }}
                />
              </b>
            </Tooltip>
          )}

          <Tooltip color="white" title="Your Account Details">
            <b onClick={() => setDisplay("account")}>
              <RiAccountBoxFill
                style={{ fontSize: "1.8em", color: "#ffbe5a" }}
              />
            </b>
          </Tooltip>
          {assist.selAssetID2Load ||
          assist.selAssetID2 ||
          assist.upImage ||
          assist.selAssetID3 ||
          assist.selAssetID ? (
            //    && onboard temp stop
            // (organization &&
            // organization.onboard === "done" || organization === undefined)
            <div className="img-active-popcs">
              <div className="img-active-popcs2">
                {assist.selAssetID2Load ? (
                  <div className="sel-img-id-ass">
                    <Card className="card-bx-fl">
                      <div className="vd-nm-pr">
                        <ReactPlayer
                          url={assist.selAssetID2Load.file_url}
                          controls={false}
                        />
                      </div>
                      <div className="play-btn-vdc ">
                        <Spin size="large" className="brand-icon " />
                      </div>
                    </Card>
                  </div>
                ) : null}

                {assist.selAssetID2 ? (
                  <div className="sel-img-id-ass">
                    <div className="vd-nm-pr">
                      <ReactPlayer
                        url={assist.selAssetID2.file_url}
                        controls={false}
                      />
                      <MdClose
                        className="close-icon-vd"
                        style={{ color: "white" }}
                        onClick={() =>
                          setassist({
                            ...assist,

                            selAssetID2: undefined,
                          })
                        }
                      />
                    </div>
                  </div>
                ) : null}

                {assist.upImage ? (
                  <div className="sel-img-id-ass">
                    <img className="" src={assist.upImage[0].file_url} alt="" />
                    <MdClose
                      style={{ color: "white" }}
                      onClick={() =>
                        setassist({
                          ...assist,

                          upImage: undefined,
                        })
                      }
                    />
                  </div>
                ) : null}
                {assist.selAssetID3 ? (
                  <div className="sel-img-id-ass">
                    <div
                      // onClick={() => {
                      //   adjustClickA(im, generate.selectedAUD);
                      // }}
                      className="aud-nm-pr"
                      style={{
                        backgroundColor: "#1a1a1a",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        aspectRatio: "1 / 1",
                        borderRadius: "8px",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                    >
                      <FaMusic
                        onClick={(e) => {
                          e.stopPropagation();
                          setPlay(assist.selAssetID3);
                        }}
                        style={{ fontSize: "20px", color: "white" }}
                      />
                    </div>
                    <AiOutlineClose
                      style={{ color: "white" }}
                      onClick={() =>
                        setassist({
                          ...assist,

                          selAssetID3: undefined,
                        })
                      }
                    />
                  </div>
                ) : null}
                {assist.selAssetID ? (
                  <div className="sel-img-id-ass">
                    <img className="" src={assist.selAssetID.file_url} alt="" />
                    <AiOutlineClose
                      style={{ color: "white" }}
                      onClick={() =>
                        setassist({
                          ...assist,

                          selAssetID: undefined,
                        })
                      }
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      )}
      <Modal
        className={`no-h-bo-b-modal top-20-x brand-modal `}
        open={play !== undefined}
        onCancel={() => setPlay()}
        width={600}
      >
        {play ? (
          <div className="video-top-right video-proper-v">
            <ReactAudioPlayer src={play.file_url} autoPlay controls />
          </div>
        ) : null}
      </Modal>
    </div>
  ) : null;
}

import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Dropdown, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import RoamTime from "./RoamTime";
import SearchContext from "../Context/SearchContext";
import NormalLoginForm from "./login/components/LoginForm";
import ForgetPassword from "./login/components/ForgetPassword";
import SignUpForm from "./login/components/SignupForm";
import MyAccount from "./account/components/MyAccount";
import MyOrganization from "./account/components/MyOrganization";
import MyTeam from "./team/MyTeam";
import Pricing from "./Pricing";
import Credits from "./Credits";

export default function HandleLogin({ isIndex }) {
  const {
    formType,
    setFormType,
    user,
    setUser,
    resetAll,
    setDisplay,
    setOrganization,
    organization,
    display,
    setassist,
    assist,
    theme,
    language,
    setLanguage,
  } = useContext(SearchContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      setUser(u);
    }
    setLoading(false);
  }, []);

  const items = [
    {
      key: "1",
      label: <span onClick={() => setDisplay("account")}>Account</span>, // New 'Account' option
    },
    {
      key: "2",
      label: <span onClick={() => logout()}>Log Out</span>,
    },
  ];

  const logout = () => {
    window.localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
    setUser();
    setOrganization();
    resetAll();
    setFormType();
  };

  const handleDocsRedirect = () => {
    window.open("https://docs.dendric.com", "_blank");
  };

  const changeLanguage = (v) => {
    window.localStorage.setItem("language", v);
    setLanguage(v);
  };

  return loading ? null : (
    <div className={`bx-r-mr-cs hdr-tps ${isIndex ? " hm-top-r" : ""} `}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {user && user.name ? (
          <div className="roadm-t-div" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {organization ? (
              <>
                <a
                  href="https://docs.dendric.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: "10px" }}
                >
                  <button className="custom-button "> Docs </button>
                </a>

                <div
                  onClick={() => setDisplay("Pricing")}
                  className="custom-button orange-btn-cs"
                >
                  {organization.subscription === 'Pro' ? 'Community Plan' :
                   organization.subscription === 'Plus' ? 'Plus Plan' :
                   organization.subscription}
                  <FaArrowRight style={{ marginLeft: '8px' }} />
                </div>
              </>
            ) : null}
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
            >
              <span className="btn-appr signop-btn">
                {user.name}
                <span style={{ marginLeft: '8px' }} className="popup">
                  <input type="checkbox" />
                  <div tabIndex="0" className="burger">
                    <svg
                      viewBox="0 0 24 24"
                      fill="black"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2c2.757 0 5 2.243 5 5.001 0 2.756-2.243 5-5 5s-5-2.244-5-5c0-2.758 2.243-5.001 5-5.001zm0-2c-3.866 0-7 3.134-7 7.001 0 3.865 3.134 7 7 7s7-3.135 7-7c0-3.867-3.134-7.001-7-7.001zm6.369 13.353c-.497.498-1.057.931-1.658 1.302 2.872 1.874 4.378 5.083 4.972 7.346h-19.387c.572-2.29 2.058-5.503 4.973-7.358-.603-.374-1.162-.811-1.658-1.312-4.258 3.072-5.611 8.506-5.611 10.669h24c0-2.142-1.44-7.557-5.631-10.647z"
                      ></path>
                    </svg>
                  </div>
                </span>
              </span>
            </Dropdown>
          </div>
        ) : (
          <>
            <span
              onClick={() => {
                navigate("/login");
              }}
              style={{ marginRight: "10px" }}
              className="custom-button "
            >
              Login
            </span>
            <span
              onClick={handleDocsRedirect}
              className="custom-button signop-btn hide-mobile"
              style={{ marginRight: "10px" }}
            >
              Docs
            </span>
            <span
              onClick={() => {
                navigate("/signup");
              }}
              style={{ marginRight: "10px" }}
              className="custom-button orange-btn-cs"
            >
              Start For Free
            </span>
          </>
        )}
        <Modal
          width={400}
          className={`no-h-bo-b-modal brand-modal2 brand-modal chat-mmmd ${theme}-theme`}
          open={formType !== undefined}
          onCancel={() => setFormType()}
        >
          <div className="login-form-btnc">
            {formType === "login" ? (
              <NormalLoginForm />
            ) : formType === "forget" ? (
              <ForgetPassword />
            ) : formType === "signup" ? (
              <SignUpForm />
            ) : null}
          </div>
        </Modal>
        <Modal
          className={`no-h-bo-b-modal top-20-x brand-modal white-bg-modal ${theme}-theme`}
          open={display !== undefined}
          onCancel={() => setDisplay()}
          width={900}
        >
          <h5 className="create-btn-h"> {user && user.name}</h5>

          <div className="bx-form-brand">
            <div style={{ marginBottom: "10px" }} className="bx-main-sel-prf">
              <span
                onClick={() => setDisplay("account")}
                className={`btn-appr select-tcbn-a ${
                  display === "account" ? " btn-apr-s" : ""
                }`}
              >
                <span>Account {display === "account" ? <TiTick /> : ""}</span>
              </span>
              <span
                onClick={() => setDisplay("Organization")}
                className={`btn-appr select-tcbn-a ${
                  display === "Organization" ? " btn-apr-s" : ""
                }`}
              >
                <span>
                  Organization {display === "Organization" ? <TiTick /> : ""}
                </span>
              </span>
              <span
                onClick={() => setDisplay("Team")}
                className={`btn-appr select-tcbn-a ${
                  display === "Team" ? " btn-apr-s" : ""
                }`}
              >
                <span>Team {display === "Team" ? <TiTick /> : ""}</span>
              </span>
              <span
                onClick={() => setDisplay("Billing")}
                className={`btn-appr select-tcbn-a ${
                  display === "Billing" ? " btn-apr-s" : ""
                }`}
              >
                <span>Billing {display === "Billing" ? <TiTick /> : ""}</span>
              </span>
              <span
                onClick={() => setDisplay("Pricing")}
                className={`btn-appr select-tcbn-a ${
                  display === "Pricing" ? " btn-apr-s" : ""
                }`}
              >
                <span>Pricing {display === "Pricing" ? <TiTick /> : ""}</span>
              </span>

              <span
                onClick={() => setDisplay("Minutes")}
                className={`btn-appr select-tcbn-a ${
                  display === "Minutes" ? " btn-apr-s" : ""
                }`}
              >
                <span> Minutes {display === "Minutes" ? <TiTick /> : ""}</span>
              </span>
            </div>
            {display === "account" ? <MyAccount /> : null}
            {display === "Organization" ? <MyOrganization /> : null}
            {display === "Team" ? <MyTeam /> : null}
            {display === "Pricing" ? <Pricing /> : null}
            {display === "Minutes" ? <Credits /> : null}
            {display === "Billing" ? (
              <div className="bx-cls-bmbs">
                <div
                  className="no-heigh-div-c full-h-bx-a"
                  style={{
                    width: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://billing.stripe.com/p/login/00gfZz7uLeaH50Y288"
                    target="_blank"
                  >
                    <Alert
                      message="Billing"
                      description={
                        <div>
                          <div>
                            Click here to access the billing portal. Securely
                            managed by Stripe. You can manage your account's
                            billing and payment information, change your payment
                            method or billing address.
                          </div>
                        </div>
                      }
                      type="info"
                      showIcon
                      className="brand-alert bg-txt-a"
                    />
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        </Modal>
      </div>
    </div>
  );
}
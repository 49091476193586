import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Alert, Tooltip } from "antd";
import SearchContext from "../../../../Context/SearchContext";
import Paragraph from "antd/es/typography/Paragraph";

export default function GeneratedLive() {
  const { left } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return loading ? null : (
    <div className={` chat-bx-mn  `}>
      <div className="act-logs-fcv">
        <Row>
          {left.chatData?.generatedLive &&
            left.chatData.generatedLive.map((im, imi) => {
              return (
                <Col key={imi} span={22}>
                  <div className="img-txt-bx-bs">
                    <div className={`img-bx-imgsc `}>
                      <div className="img-bx-mnb">
                        <div>
                          <img src={im.file_url} alt="" />
                        </div>
                      </div>
                      <div>{im.generations}</div>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
        {left.chatData?.generatedLive &&
        left.chatData.generatedLive.length === 0 ? (
          <div className="no-heigh-div-c ">
            <Alert
              message="No Assets Exist"
              type="info"
              showIcon
              className="brand-alert bg-txt-a"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

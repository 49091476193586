const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);
  
    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);
  
    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }
  
    return hours + ":" + minuts + ":" + remainder;
  };
  
  const timeToseconds = (time) => {
    let hours = time.slice(0, 2);
    hours = hours * 3600;
    let minuts = time.slice(3, 5);
    minuts = minuts * 60;
    let seconds = time.slice(6);
    seconds = Number(seconds) + Number(minuts) + Number(hours);
    return seconds;
  };
  
  export { timeToseconds, TimeCorrection };
  
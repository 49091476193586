import {
  Button,
  Form,
  Input,
  Modal,
  Spin,
  Select,
  message,
  Popconfirm,
  Row,
  Col,
  Alert,
  Collapse,
  Upload,
  Switch,
  Checkbox,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { ImLocation2 } from "react-icons/im";
import {
  FaArrowRight,
  FaChevronDown,
  FaEdit,
  FaPlus,
  FaShopify,
  FaVideo,
} from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";
import { FcAssistant } from "react-icons/fc";
import { TiTick } from "react-icons/ti";
import {
  MdClose,
  MdDelete,
  MdLanguage,
  MdOutlineStorefront,
} from "react-icons/md";
import { RiInformationFill } from "react-icons/ri";
import { HiHome } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineInfoCircle,
  AiOutlinePlus,
} from "react-icons/ai";
import SearchContext from "../../../Context/SearchContext";
import GreenTick from "../../../components/GreenTick";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;
const { Panel } = Collapse;
const { Dragger } = Upload;

export default function Profiles() {
  const {
    profile,
    setProfile,
    pActive,
    setPActive,
    user,
    setFormType,
    setassist,
    assist,

    loginChange,
    setLoginChange,
    setUser,
    resetAll,
  } = useContext(SearchContext);
  let location = useLocation();
  const [add, setAdd] = useState();
  const [UploadedFile, setUplaoded] = useState([]);
  const [UploadedFile2, setUplaoded2] = useState([]);

  const [loadingS, setloadingS] = useState();
  const [loadingD, setloadingD] = useState();
  const [truth, setTruth] = useState();
  const [openC, setOpenC] = useState();
  const [tLoader, setTLoader] = useState(false);
  const [lang, setLang] = useState([
    "Arabic",
    "Bengali",
    "Chinese (Simplified)",
    "Chinese (Traditional)",
    "Croatian",
    "Czech",
    "Danish",
    "Dutch",
    "English",
    "Estonian",
    "Finnish",
    "French",
    "French (Canada)",
    "German",
    "Greek",
    "Hebrew",
    "Indonesian",
    "Irish",
    "Italian",
    "Japanese",
    "Korean",
    "Lithuanian",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Romanian",
    "Russian",
    "Serbian",
    "Sinhala",
    "Slovak",
    "Somali",
    "Spanish",
    "Swedish",
    "Tamil",
    "Urdu",
    "Vietnamese",
  ]);

  useEffect(() => {
    getVideoTask();
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 15,
      }}
      spin
    />
  );
  const getVideoTask = (path, isH) => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/person/?page=1&limit=15&sort_by=${-1}&profile_id=${
        u ? u.profile_id : "onboard"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setProfile({
            ...profile,
            data: data.personList,
            totalPages: data.totalPages,
          });
          if (data.personList.length === 0 && isH) {
            setAdd({
              name: "",
              location: "",
              language: "",
              status: "private",
              color_design: "",
              bubble_color: "",
              public_url: "",
              agent_size: "",
              earn: "pending",
              network: false,
            });
          } else if (pActive === undefined) {
            setPActive(data.personList[0]);
          }
        }
      });
  };
  const getProfitAgent = () => {
    const u = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/person/?page=1&limit=15&sort_by=${-1}&profile_id=internal`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setProfile({
            ...profile,
            dataP: data.personList,
          });
        }
      });
  };

  const handleSubmit = () => {
    const u = JSON.parse(window.localStorage.getItem("user"));

    const obj = { ...add };
    obj.profile_id = u ? u.profile_id : "onboard";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/person`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setAdd();
        setloadingS(false);
        if (data && data.isSuccess) {
          const all = profile.data ? profile.data : [];
          all.unshift(data.data);

          setProfile({
            ...profile,
            data: all,
          });

          setassist({ demo_id: assist.demo_id, pageNo: 1 });
          resetAll();
          setPActive(data.data);

          if (UploadedFile2.length || UploadedFile.length) {
            addImage(data.data);
          }
        } else {
          message.error("Something went wrong");
        }
      });
  };

  const addImage = (data) => {
    const formData = new FormData();
    formData.append("person_id", data._id);
    if (UploadedFile.length) {
      formData.append("logo", UploadedFile[0].originFileObj);
    }
    if (UploadedFile2.length) {
      formData.append("public_image", UploadedFile2[0].originFileObj);
    }

    formData.append("person_id", data._id);
    const requestOptions = {
      method: "POST",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(`https://int.display.video/api/person/image`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setUplaoded([]);
        setUplaoded2([]);
        if (data && data.isSuccess) {
          let all = profile.data ? profile.data : [];
          all = all.filter((f) => f._id !== data.personData._id);

          all.unshift(data.personData);
          setProfile({
            ...profile,
            data: all,
          });
        }
      });
  };

  const updateProfile = () => {
    const u = JSON.parse(window.localStorage.getItem("user"));

    const obj = {
      ...add,
    };
    delete obj._id;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/person/${add._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const all = profile.data.map((m) =>
            m._id === data.personData._id ? data.personData : m
          );
          setProfile({
            ...profile,
            data: all,
          });
          setPActive(data.personData);
          setAdd();
          setloadingS(false);
        } else {
          message.error("Something went wrong");
        }
      });
  };

  const deleteProfile = () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://int.display.video/api/person/${add._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const all = profile.data.filter((f) => f._id !== add._id);
          setProfile({
            ...profile,
            data: all,
          });
          setassist({ demo_id: assist.demo_id, pageNo: 1 });
          setPActive(all[0]);
          setAdd();
          setloadingD(false);
          resetAll();
        } else {
          message.error("Something went wrong");
        }
      });
  };
  const logout = () => {
    window.localStorage.removeItem("user");
    setUser();
    setassist({ demo_id: assist.demo_id, pageNo: 1 });
    setAdd();
    setProfile({
      ...profile,
      data: undefined,
    });

    // setPActive();
    setLoginChange(!loginChange);
  };
  const getTruth = (p) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/truth/?page=1&limit=15&sort_by=${-1}&person_id=${
        p._id
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setTruth(data.truthList);
        }
      });
  };

  const closeModal = () => {
    setAdd();
    setTruth();
    setOpenC();
  };

  const props = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
      // console.log(info.file.originFileObj);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };
  const props2 = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile2,
    onChange(info) {
      setUplaoded2(info.fileList);
      // console.log(info.file.originFileObj);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };
  return (
    <div>
      <div className="prfd-t-bcx">
        <div className="bx-main-sel-prf">
          <span
            onClick={() => {
              // console.log(profile);
              setAdd({
                name: "",
                location: "US",
                language: "English",
                status: "private",
                color_design: "",
                bubble_color: "",
                public_url: uuidv4(),
                default: "",
                agent_size: "",
                earn: "pending",
                network: false,
              });
            }}
            className={`btn-appr select-tcbn-a `}
          >
            <span>
              <AiOutlinePlus />
            </span>
          </span>
          <span
            onClick={() => {
              if (profile.dataP) {
                setProfile({
                  ...profile,
                  dataP: undefined,
                });
              } else {
                getProfitAgent();
              }
            }}
            style={{ backgroundColor: "rgb(72 76 125)" }}
            className={`btn-appr pp-prof select-tcbn-a ${
              profile.dataP ? " btn-apr-s" : ""
            }`}
          >
            <span>Prebuilt Agents {profile.dataP ? <TiTick /> : ""}</span>
          </span>
        </div>
      </div>
      {profile.data && profile.data.length === 0 ? (
        <div className="no-heigh-div-c">
          <Alert
            message="Agents are personalized Visual AI models that you can build that will chat with you about your videos and images in a specific way. "
            className="brand-alert bg-txt-a"
          />
        </div>
      ) : null}
      {profile.dataP &&
        profile.dataP.map((p, pi) => {
          return (
            <div key={pi} className="main-prf-bxksc profit-mm-bc">
              <div
                className={`bx-main-proflc btn-appr select-tcbn-a pp-prof ${
                  p._id === pActive._id ? " btn-apr-s" : ""
                }`}
                style={{ marginBottom: "10px", marginRight: "0px" }}
                onClick={() => {
                  if (p._id !== pActive._id) {
                    setPActive(p);
                    resetAll();
                  }
                }}
              >
                <span>
                  <span style={{ display: "flex" }}>
                    {p.name} {p._id === pActive._id ? <TiTick /> : ""}
                  </span>
                </span>
              </div>
            </div>
          );
        })}
      {profile.data &&
        profile.data.map((p, pi) => {
          return (
            <div key={pi} className="main-prf-bxksc ">
              <div
                className={`bx-main-proflc btn-appr select-tcbn-a ${
                  p._id === pActive._id ? " btn-apr-s" : ""
                }`}
                style={{ marginBottom: "10px", marginRight: "0px" }}
                onClick={() => {
                  if (p._id !== pActive._id) {
                    setPActive(p);
                    resetAll();
                  }
                }}
              >
                <span>
                  <span style={{ display: "flex" }}>
                    {p.name} {p._id === pActive._id ? <TiTick /> : ""}
                  </span>
                </span>
              </div>
              <div className="del-edit-bc">
                <AiFillEdit
                  onClick={() => {
                    setAdd(p);
                    console.log(p);
                  }}
                />
                {/* <AiFillDelete
                  onClick={() => {
                    setAdd(p);
                  }}
                  style={{fontSize:"18px"}}
                /> */}
              </div>
            </div>
          );
        })}
      <Modal
        className="no-h-bo-b-modal brand-modal24 top-20-x brand-modal"
        open={add !== undefined}
        onCancel={() =>
          profile.data && profile.data.length === 0 ? null : closeModal()
        }
        width={profile.data && profile.data.length === 0 ? 800 : 470}
      >
        {add ? (
          <div className="profile-add-olm">
            <h5 className="create-btn-h ">
              {add._id ? "Edit Agent" : "New Agent"}
            </h5>
            <div className=" bx-form-brand">
              <Row>
                <Col span={profile.data.length === 0 ? 14 : 24}>
                  <Form
                    onSubmit={() => {
                      if (user) {
                        setloadingS(true);
                        handleSubmit();
                      } else {
                        setAdd();
                        setFormType("signup");
                      }
                    }}
                    className="login-form"
                  >
                    <Form.Item>
                      <Input
                        onChange={(e) =>
                          setAdd({ ...add, name: e.target.value })
                        }
                        size="large"
                        prefix={
                          <img
                            src={pActive && pActive.logo ? pActive.logo : ""}
                            alt=""
                            className="input-icon-l"
                          />
                        }
                        placeholder="Name"
                        value={add.name}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Select
                        size="large"
                        value={add.agent_size}
                        onChange={(e) => setAdd({ ...add, agent_size: e })}
                        showSearch
                      >
                        <Option value="" disabeled={true}>
                          <MdLanguage className="input-icon-l" /> Agent Size
                        </Option>

                        <Option value="small">Small</Option>
                        <Option value="medium">Medium</Option>
                        <Option value="large">Large</Option>
                      </Select>
                      <a href="https://docs.olm.ai/" target="blank">
                        <AiOutlineInfoCircle className="info-icn" />
                      </a>
                    </Form.Item>
                    <Form.Item>
                      <Input.TextArea
                        placeholder="Default"
                        autoSize={{ minRows: 3, maxRows: 4 }}
                        value={add.default}
                        onChange={(e) =>
                          setAdd({ ...add, default: e.target.value })
                        }
                      />
                    </Form.Item>
                    {/* {add._id ? (
                      <Collapse
                        onChange={(v) => {
                          if (truth === undefined) {
                            setOpenC("1");
                            getTruth(add);
                          }
                        }}
                        activeKey={openC}
                        className="colps-ad-vd"
                        bordered={false}
                      >
                        <Panel header="Universal Truth" key="1">
                          {truth ? (
                            <div className="truth-input0c">
                              {truth &&
                                truth.map((t, ti) => {
                                  return (
                                    <div className="ti-input-truds">
                                      <Input.TextArea
                                        placeholder="Truth"
                                        autoSize={{ minRows: 1, maxRows: 2 }}
                                        value={t.truth}
                                        key={ti}
                                        onChange={(e) =>
                                          changeField(t, e.target.value)
                                        }
                                        onBlur={() =>
                                          updateTruth({ truth: t.truth }, t._id)
                                        }
                                      />
                                      <MdClose
                                        onClick={() => detTruth(t)}
                                        className="del-icn-sctr"
                                      />
                                      {t.status === "approved" ? (
                                        <span className="titi-titck">
                                          <TiTick />
                                        </span>
                                      ) : null}
                                    </div>
                                  );
                                })}
                              <div>
                                {tLoader ? null : (
                                  <FaPlus
                                    onClick={() => {
                                      setTLoader(true);
                                      addTruth(add);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          ) : null}
                        </Panel>
                      </Collapse>
                    ) : null} */}

                    {add.status === "public" ? (
                      <Collapse className="colps-ad-vd bg-txt-clp">
                        <Panel header="Branding" key="1">
                          <Form.Item>
                            <div className="color-changer-bx123">
                              <span>Bubble Color:</span>
                              <input
                                type="color"
                                id="colorpicker"
                                value={add.bubble_color}
                                onChange={(e) => {
                                  setAdd({
                                    ...add,
                                    bubble_color: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Form.Item>

                          <Dragger maxCount={1} {...props}>
                            <UploadOutlined />
                            Drag or click for upload Logo
                          </Dragger>
                        </Panel>
                      </Collapse>
                    ) : null}
                    {add.status === "public" ? (
                      <Collapse className="colps-ad-vd bg-txt-clp">
                        <Panel header="Public Details" key="1">
                          <Form.Item>
                            <div className="color-changer-bx123">
                              <h6>Public Name:</h6>
                              <Input
                                onChange={(e) =>
                                  setAdd({
                                    ...add,
                                    public_name: e.target.value,
                                  })
                                }
                                size="large"
                                placeholder="public name"
                                value={add.public_name}
                              />
                            </div>
                          </Form.Item>

                          <Form.Item>
                            <div className="color-changer-bx123">
                              <h6>Public URL:</h6>
                              <Input
                                onChange={(e) =>
                                  setAdd({ ...add, public_url: e.target.value })
                                }
                                size="large"
                                placeholder="public_url"
                                value={add.public_url}
                              />
                              <a
                                href={`${
                                  window.location.href.replace(
                                    "private",
                                    "public"
                                  ) +
                                  "/" +
                                  add.public_url
                                }`}
                                target="blank"
                              >
                                <FaArrowRight />
                              </a>
                            </div>
                          </Form.Item>
                          <Form.Item>
                            <div>
                              Connect to Yield{" "}
                              <Switch
                                onChange={(v) => {
                                  setAdd({
                                    ...add,
                                    earn:
                                      add.earn === "pending"
                                        ? "progress"
                                        : "pending",
                                  });
                                }}
                                checked={add.earn === "pending" ? false : true}
                                defaultChecked={
                                  add.earn === "pending" ? false : true
                                }
                                size="small"
                                className="brand-switch"
                              />
                            </div>
                            {add.earn === "progress" ? (
                              <Spin indicator={antIcon} />
                            ) : add.earn === "approved" ? (
                              <GreenTick width="15px" height="15px" />
                            ) : null}
                          </Form.Item>
                          <Form.Item>
                            <Checkbox
                              onChange={(v) => {
                                setAdd({
                                  ...add,
                                  network: v.target.checked,
                                });
                              }}
                              checked={add.network}
                            >
                              Enable Olm Network
                            </Checkbox>
                          </Form.Item>
                        </Panel>
                      </Collapse>
                    ) : null}
                    {/* {add.purpose == "store" && (
                  <Form.Item>
                    <Input
                      onChange={(e) =>
                        setAdd({ ...add, shop_name: e.target.value })
                      }
                      size="large"
                      prefix={<FaShopify className="input-icon-l" />}
                      placeholder="Shop URL (shop-name.myshopify.com)"
                      value={add.shop_name}
                    />
                  </Form.Item>
                )} */}

                    <Form.Item className="last-frm-fhkx">
                      {add._id ? (
                        <Popconfirm
                          title="Are you sure to delete this old?"
                          onConfirm={() => {
                            setloadingD(true);
                            deleteProfile();
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          {loadingD ? (
                            <Spin className="black-loader-spin" />
                          ) : (
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="login-form-button red-delete-buton"
                            >
                              Delete
                            </Button>
                          )}
                        </Popconfirm>
                      ) : profile.data.length === 0 ? (
                        <Button
                          onClick={logout}
                          type="primary"
                          htmlType="submit"
                          className="login-form-button red-delete-buton"
                        >
                          Log Out
                        </Button>
                      ) : (
                        <span />
                      )}
                      <div className=" cereat-prf-btnc">
                        {/* <Button
                      onClick={() => {
                        window.open(
                          `https://app-name.com/?shop=${add.shop_name}&user_id=${user.id}`,
                          "_blank"
                        );
                      }}
                      type="primary"
                      htmlType="button"
                      className="login-form-button mx-2"
                      size="large"
                    >
                      Connect to store
                    </Button> */}
                        {loadingS ? (
                          <Spin className="black-loader-spin" />
                        ) : (
                          <Button
                            onClick={() => {
                              if (user) {
                                setloadingS(true);
                                add._id ? updateProfile() : handleSubmit();
                              } else {
                                setAdd();
                                setFormType("signup");
                              }
                            }}
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                          >
                            {add._id ? "Update" : "Create"}
                          </Button>
                        )}
                      </div>
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={profile.data.length === 0 ? 10 : 0}>
                  <div className="no-heigh-div-c p-20alrt-c full-h-bx-a">
                    <Alert
                      message="What is an Olm?"
                      description={
                        <div className="olm-ponits-m-ds">
                          <ul>
                            <li>
                              Olms are optical language models, that allow you
                              to be more productive in a conversational way.
                            </li>
                            <li>
                              You can use language to reason with the Olm or you
                              can upload visuals such as videos and images.
                            </li>
                            <li>
                              Olms can create content, provide business
                              intelligence for your specific business needs and
                              give more insights on a variety of topics. Start
                              by naming your Olm.
                            </li>
                          </ul>
                        </div>
                      }
                      type="info"
                      showIcon
                      className="brand-alert bg-txt-a"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}

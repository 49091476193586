import {
  Button,
  Input,
  message,
  Modal,
  Upload,
  Collapse,
  Select,
  Spin,
  Popover,
  notification,
} from "antd";
import React, { useContext, useRef, useState } from "react";
import { FaArrowLeft, FaMinus } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

import { BsArrowDownShort, BsFillCloudUploadFill } from "react-icons/bs";
import ReactPlayer from "react-player";
import { MdClose } from "react-icons/md";
import SearchContext from "../../../Context/SearchContext";
const { Option } = Select;
const { Dragger } = Upload;
const { Panel } = Collapse;

let all = [];
export default function UploadAssets({ isHome, type2 }) {
  const playerRef = useRef();

  const {
    setFormType,
    setassist,
    assist,
    organization,
    setOrganization,
    pActive,
    right,
    setRight,
    user,
    images,
    setImages,
    setDisplay,
  } = useContext(SearchContext);
  const [loading, setLoading] = useState(false);
  const [purpose, setPurpose] = useState();
  const [UploadedFile, setUplaoded] = useState([]);
  const [type, setType] = useState("image");
  const [mData, setMData] = useState("");
  const [videoErr, setVideoErr] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [lengthS, setLengthS] = useState();
  const [remainingUploads, setRemainingUploads] = useState(0);
  const [completedUploads, setCompletedUploads] = useState(0);

  const saveFile = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.originFileObj);
    formData.append("assistant_id", pActive ? pActive._id : "");

    formData.append("purpose", "misc");
    formData.append("headclip_id", id);
    formData.append("meta_data", mData);
    formData.append("media_type", type === "audio" ? "audio" : "visual");
    formData.append("user_id", user ? user.user_id : "map");

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/upload`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setUplaoded([]);
        setFileList([]);
        setImageUrl();
        setMData("");
        setLoading(false);
        if (data.message === "Compute is not available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        if (data && data.isSuccess) {
          if (i + 1 === UploadedFile.length) {
            const allAssets = images && images.data ? images.data : [];
            allAssets.unshift(data.data);
            setImages({ ...images, data: allAssets });
            all.push(data.data);
            setassist({
              ...assist,
              upImage: undefined,
              uploadView: undefined,
              selAssetID: data.data,
              selAssetID2: undefined,
              isPopup: false,
            });

            setRight({
              ...right,
              activeIcon: right.upOpen ? right.activeIcon : "chat",
              isUpload: undefined,
              activeAI: right.upOpen ? right.activeAI : "message",
            });
            if (organization) {
              setOrganization({ ...organization, compute: data.compute });
            }
          } else {
            all.push(data.data);
          }
        } else if (data.message === "Detected as adult or racy content") {
          notification.error({
            message:
              "We couldn't accept this content at this moment. Please contact us if there are any questions ",
          });
        } else if (data.message === "Insufficient compute available") {
          setDisplay("Pricing");
        }
      });
  };

  const saveVideo = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.originFileObj);
    formData.append("assistant_id", pActive ? pActive._id : "");

    formData.append("video_duration", lengthS);
    formData.append("purpose", "video");
    formData.append("meta_data", mData);
    formData.append("user_id", user ? user.user_id : "map");

    const vidId = Math.random();

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/image/upload/video`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message === "Insufficient compute available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        if (data.message === "video_duration is required") {
          message.success("Upload Failed. Please try again.");
        }

        if (data && data.isSuccess) {
          if (i + 1 === UploadedFile.length) {
            const allvid = images && images.videos ? images.videos : [];
            allvid.unshift(data.data);
            setImages({ ...images, videos: allvid });
            setassist({
              ...assist,
              isPopup: false,
              selAssetID2: undefined,
              selAssetID: undefined,
              selAssetID2Load: data.data,
            });
            setRight({
              ...right,
              activeIcon: right.upOpen ? right.activeIcon : undefined,
              isUpload: undefined,
              activeAI: right.upOpen ? right.activeAI : "message",
            });

            setTimeout(
              () =>
                setassist({
                  ...assist,
                  isPopup: false,
                  selAssetID2: data.data,
                  selAssetID2Load: undefined,
                  selAssetID: undefined,
                }),
              12000
            );
            if (organization) {
              setOrganization({ ...organization, compute: data.compute });
            }

            setUplaoded([]);
            setFileList([]);
            setLengthS();
            setMData("");
            setLoading(false);
            message.success("Uploading Your Video...");
          }
        } else if (data.message === "Insufficient compute available") {
          setDisplay("Pricing");
        }
      });
  };

  const getVideoLength = (vLength) => {
    setLengthS(vLength);
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const props = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
    },
  };

  const handleBeforeUpload = (file, fileList) => {
    setFileList([file]);
    return false;
  };

  const renderFileName = () => {
    if (fileList.length > 0) {
      const file = fileList[0];
      return (
        <>
          {imageUrl && type === "image" ? (
            <img className="img-prev-ibc" src={imageUrl} alt="" />
          ) : imageUrl && type === "video" ? (
            <div className="vd-nm-pr img-prev-ibc">
              <ReactPlayer url={imageUrl} controls={false} />
            </div>
          ) : imageUrl && type === "audio" ? (
            <audio controls src={imageUrl} className="audio-prev-ibc" />
          ) : (
            <span>{file.name}</span>
          )}
        </>
      );
    } else {
      return <span>Drag or click to upload</span>;
    }
  };

  const removeFile = () => {
    setFileList([]);
  };

  function handleRemove() {
    setImageUrl(null);
    message.success("File removed successfully!");
  }

  return (
    <div>
      <div className={`upload-pop-mbc `}>
        {right.upOpen ? (
          <div className="close-as-bn">
            <MdClose onClick={() => setRight({ ...right, upOpen: false })} />
          </div>
        ) : null}
        {isHome ? (
          <div className="ty-nw-bx-tnx">
            <b>Try Now</b>
            <BsArrowDownShort className="arrow-dwn" />
          </div>
        ) : null}

        <div className="upload-bx-mnn01">
          <div className="upload-bx-mnn">
            <div
              className="bx-main-sel-prf-attach"
              style={{ gridColumn: "1 / span 1" }}
            >
              <div className="bx-main-sel-prf-attach21">
                <span
                  onClick={() => {
                    setType("image");
                    setImageUrl();
                    setUplaoded([]);
                    setFileList([]);
                    setLengthS();
                  }}
                  className={`btn-appr select-tcbn-a ${
                    type === "image" ? " btn-apr-s" : ""
                  }`}
                >
                  <span>Images</span>
                </span>
                <span
                  onClick={() => {
                    setType("video");
                    setUplaoded([]);
                    setImageUrl();
                    setFileList([]);
                    setLengthS();
                  }}
                  className={`btn-appr select-tcbn-a ${
                    type === "video" ? " btn-apr-s" : ""
                  } `}
                >
                  <span>Videos</span>
                </span>
                <span
                  onClick={() => {
                    setType("audio");
                    setUplaoded([]);
                    setImageUrl();
                    setFileList([]);
                    setLengthS();
                  }}
                  className={`btn-appr select-tcbn-a ${
                    type === "audio" ? " btn-apr-s" : ""
                  } `}
                >
                  <span>Audio</span>
                </span>
              </div>
            </div>
            <div
              className="vid-ad-vx tag-grou-lake-c"
              style={{ gridColumn: "2 / span 1", marginBottom: "1px" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {user && user.id ? (
                  <Dragger
                    showUploadList={false}
                    maxCount={1}
                    beforeUpload={handleBeforeUpload}
                    {...props}
                  >
                    {renderFileName()}
                  </Dragger>
                ) : (
                  <div
                    onClick={() => {
                      setFormType("signup");
                    }}
                    className="no-login-up"
                  >
                    Drag or click to upload
                  </div>
                )}
              </div>
              <div className="up-bx-mnbcs">
                {UploadedFile.length ? (
                  <Button danger type="link" onClick={removeFile}>
                    Change
                  </Button>
                ) : null}
                {UploadedFile.length ? (
                  loading ? (
                    <Spin />
                  ) : (
                    <span>
                      {type === "video" ? (
                        lengthS ? (
                          <button
                            className="custom-button"
                            onClick={() => {
                              setLoading(true);
                              all = [];

                              let id = uuidv4();
                              for (let i = 0; i < UploadedFile.length; i++) {
                                saveVideo(UploadedFile[i], i, id);
                              }
                            }}
                          >
                            Attach
                          </button>
                        ) : null
                      ) : (
                        <button
                          className="custom-button"
                          onClick={() => {
                            setLoading(true);
                            all = [];

                            let id = uuidv4();
                            for (let i = 0; i < UploadedFile.length; i++) {
                              saveFile(UploadedFile[i], i, id);
                            }
                          }}
                        >
                          Attach
                        </button>
                      )}
                    </span>
                  )
                ) : null}
              </div>
              <br />
            </div>
            {UploadedFile.length && type === "video" ? (
              <div className="pic-2vid0c">
                <ReactPlayer
                  url={URL.createObjectURL(UploadedFile[0].originFileObj)}
                  className=""
                  controls
                  playing
                  muted
                  ref={playerRef}
                  onProgress={(e) => getVideoLength(e.loadedSeconds)}
                />
              </div>
            ) : null}
            {UploadedFile.length && type === "audio" ? (
              <div className="pic-2vid0c">
                <audio
                  controls
                  src={URL.createObjectURL(UploadedFile[0].originFileObj)}
                  className="audio-player"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
import React, { useState, useContext, useEffect } from "react";
import Chat from "./components/Chat";
import "./newhome.css";
import "./theme.css";
import SearchContext from "../../Context/SearchContext";
import SearchThubnail from "./components/flow/SearchThubnail";
import { Col, Drawer, Row } from "antd";
import { v4 as uuidv4 } from "uuid";

import { AiOutlineClose } from "react-icons/ai";
import GeneratedLive from "./components/flow/GeneratedLive";
import WebGenerated from "./components/flow/WebGenerate";
import Header from "../../components/Header";
import ThemeSelection from "./components/ThemeSelection";
import Assests from "./components/flow/Assests";
import {
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill,
} from "react-icons/bs";
import { BsImages } from "react-icons/bs";
import Call from "./components/Call";
import { isMobile } from "react-device-detect";
import Onboard from "./components/onboard/Onboard";

export default function Landing() {
  const {
    setUser,
    loginChange,
    left,
    setLeft,
    theme,
    setFormType,
    setTheme,
    setOrganization,
    setLanguage,
  } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    let obj;
    if (u) {
      obj = u;
      setUser(u);
      getOrganization(u);
    } else {
      let isID = JSON.parse(window.localStorage.getItem("isID"));
      let user_id;
      if (isID) {
        user_id = isID.user_id;
      } else {
        user_id = uuidv4();
        // window.localStorage.setItem("isID", JSON.stringify({ user_id }));
      }
      obj = { user_id };
      setUser(obj);
    }
    setLoading(false);
  }, [loginChange]);

  useEffect(() => {
    const t = window.localStorage.getItem("theme");
    const lang = window.localStorage.getItem("language");
    if (lang) {
      setLanguage(lang);
    }
    if (t) {
      setTheme(t);
    }
  }, []);

  const getOrganization = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/organization/${u.user_id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let org = response.organization;
          setOrganization(org);
        }
      });
  };

  const [selectedOption, setSelectedOption] = useState("Create");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const optionGifs = {
    Create: "/Mountains.gif",
    Understand: "/Mountains.gif",
    Edit: "/Mountains.gif",
    Observe: "/Mountains.gif",
  };

  return !loading ? (
    <div className={`main-bx-2fc-m ${theme}-theme`}>
      {/* <Onboard /> */}
      {/* //onboard temp stop */}
      <div className="heeader-landing-container">
        <Header />
      </div>
      <div className="home-main-lanidng">
        {/* Centered container */}
        <div className="centered-container">
          {/* Large title */}
          <h1 className="large-title">Ideas Are All You Need</h1>

          <p className="subtitle landing-title-sub">
          Dendric is more than an assistant; it's your strategic partner in game development, 
          providing support for every stage of the process.
          </p>

          {/* Card buttons */}
          <div className="card-buttons">
            <a
              href="https://docs.olm.ai/product/getting-started"
              target="_blank"
              className="card-button"
            >
              Product
            </a>
            <a
              href="https://docs.olm.ai/product/use-cases"
              target="_blank"
              className="card-button"
            >
              Use Cases
            </a>

            <a
              href="https://docs.olm.ai/company/safety"
              target="_blank"
              className="card-button"
            >
              Safety
            </a>
            <a
              href="https://docs.olm.ai/developers/getting-started"
              target="_blank"
              className="card-button"
            >
              Developers
            </a>
          </div>
        </div>

        {/* Large video container */}
        {/* Large image container for GIF */}
<div className="centered-container image-container">
  <img src="/message_dendrite.gif" alt="Dendric AI Demo" width="100%" />
</div>
        {/* Try For Free and Book a Demo buttons 
        <div className="button-container">
          <button className="card-button" onClick={() => setFormType("signup")}>
            Get Started
          </button>
          <button className="card-button">
            <a
              href="https://calendly.com/olm_ai/30min"
              target="_blank"
              rel="noopener noreferrer"
            >
              Book a Demo
            </a>
          </button>
        </div>
      */}
      </div>
    </div>
  ) : null;
}

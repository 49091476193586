import React, { useContext } from "react";
import SearchContext from "../../../../Context/SearchContext";
import Icons from "../../../../components/Icons";
import { Button } from "antd";
import {
  MdInsights,
  MdOutlineCreate,
  MdOutlineImageSearch,
} from "react-icons/md";
import { BsCollectionPlayFill, BsMagic } from "react-icons/bs";

export default function New() {
  const { theme, setOrganization, organization, user } =
    useContext(SearchContext);

  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };

  const logout = () => {
    window.localStorage.removeItem("user");
    window.location.reload();
  };

  return (
    <div className="onb-main-bx">
      <div>
      <div className="logo-login-c">

<img
      src={Icons["white-logo"]}
      alt=""
      style={{ width: "30px", height: "30px" }}
    />
 
</div>
        <div className="onb-m-new">
          <div className="wrapper-2">
            <h1>Thank You  {user?.name}</h1>
          </div>
          <div className="icons-bx-main-ne">
            <div>
              
              <b>Ways To Use</b>
            </div>
            <div>
             
              <b>Console</b>
            </div>
            <div>
             
              <b>API</b>
            </div>
            {/* <div>
              <MdInsights />
              <b>Analyze</b>
            </div> */}
            <div>
              
              <b>Services</b>
            </div>
          </div>
        </div>
      </div>
      <div className="onb-m-bot">
        <span />
        <Button className="custom-button orange-btn-cs"
          onClick={() => {
            updateOrganization("onboard", "done");
            setOrganization({ ...organization, onboard: "done" });
          }}
          
        >
          Start
        </Button>
      </div>
    </div>
  );
}

import React, { useState, useContext } from "react";
import { Button } from "antd";
import SearchContext from "../Context/SearchContext";

export default function Pricing() {
  const [activeTab, setActiveTab] = useState("monthly");
  const {
    setOrganization,
    organization,
    display,
    setassist,
    assist,
    theme,
    language,
    setLanguage,
  } = useContext(SearchContext);

  const panels = {
    monthly: [
      {
        title: "Community",
        price: 0,
        features: [
          "Daily",
          "25 Messages",
          "15 Image Generations",
          "10s Audio Generation",
          "10s Video Generation",
          "5 Game Generations",
          "Unlimited Asset Search",
          "1 GB Asset Storage"
        ],
      },
      {
        title: "Plus",
        price: 25,
        link: "https://pay.telemit.com/b/4gw9BJdyGa0ndqgcNo",
        features: [
          "Daily",
          "300 Messages",
          "100 Image Generations",
          "45s Audio Generation",
          "10s Video Generation",
          "15 Game Generations",
          "Unlimited Asset Search",
          "100 GB Asset Storage"
        ],
      },
      {
        title: "Business",
        price: 40,
        link: "https://pay.telemit.com/b/14kcNVamufkH9a09Bd",
        features:  [
          "Daily",
          "1000 Messages",
          "250 Image Generations",
          "240s Audio Generation",
          "120s Video Generation",
          "30 Game Generations",
          "Unlimited Asset Search",
          "1TB Asset Storage"
        ],
      },
    ],
    yearly: [
      {
        title: "Community",
        price: 0,
        features: [
          "Daily",
          "25 Messages",
          "15 Image Generations",
          "10s Audio Generation",
          "10s Video Generation",
          "5 Game Generations",
          "Unlimited Asset Search",
          "1 GB Asset Storage"
        ],
      },
      {
        title: "Plus",
        price: 204,
        link: "https://pay.telemit.com/b/dR69BJdyG8Wj5XO5kY",
        features:  [
          "Daily",
          "300 Messages",
          "100 Image Generations",
          "45s Audio Generation",
          "10s Video Generation",
          "15 Game Generations",
          "Unlimited Asset Search",
          "100 GB Asset Storage"
        ],
      },
      {
        title: "Business",
        price: 324,
        link: "https://pay.telemit.com/b/fZebJRcuCb4rbi87t7",
        features: [
          "Daily",
          "1000 Messages",
          "250 Image Generations",
          "240s Audio Generation",
          "120s Video Generation",
          "30 Game Generations",
          "Unlimited Asset Search",
          "1TB Asset Storage"
        ],
      },
    ],
  };

  const isActivePlan = (panelTitle) => {
    if (!organization.subscription || !organization.term || 
        (organization.subscription !== "Plus" && organization.subscription !== "Business")) {
      return panelTitle === "Community";
    }
    const isActive = organization.subscription === panelTitle && 
           ((organization.term === "monthly" && activeTab === "monthly") ||
            (organization.term === "annual" && activeTab === "yearly"));
    return isActive;
  };

  const hasPaidPlan = organization.subscription === "Plus" || organization.subscription === "Business";

  const handleButtonClick = (panel) => {
    if (panel.title === "Community") {
      if (hasPaidPlan) {
        window.open("https://pay.telemit.com/p/login/00gfZz7uLeaH50Y288", "_blank");
      }
    } else if (hasPaidPlan) {
      window.open("https://pay.telemit.com/p/login/00gfZz7uLeaH50Y288", "_blank");
    } else if (panel.link) {
      window.open(panel.link, "_blank");
    }
  };

  const getButtonText = (panelTitle) => {
    if (panelTitle === "Community") {
      return hasPaidPlan ? "Switch" : "Active";
    } else if (isActivePlan(panelTitle)) {
      return "Active";
    } else if (hasPaidPlan) {
      return "Switch";
    } else {
      return "Subscribe";
    }
  };

  return (
    <div className="pricing-container">
      <div className="tab-buttons">
        <Button custom-button orange-btn-cs
          type={activeTab === "yearly" ? "primary" : "default"}
          onClick={() => setActiveTab("yearly")}
        >
          Annual (Save 32%)
        </Button>
        <Button custom-button orange-btn-cs
          type={activeTab === "monthly" ? "primary" : "default"}
          onClick={() => setActiveTab("monthly")}
        >
          Month
        </Button>
      </div>
      <div className="pricing-panels">
        {panels[activeTab].map((panel, index) => (
          <div key={index} className={`pricing-panel ${panel.title === "Plus" ? "highlighted" : ""}`}>
            <h2>{panel.title}</h2>
            <div className="price">
              {panel.price === 0 ? "Free" : `$${panel.price}`}
              {panel.price !== 0 && <span className="price-period">/ {activeTab === "monthly" ? "month" : "year"}</span>}
            </div>
          
            <Button 
              className={`subscribe-button ${isActivePlan(panel.title) ? "active" : ""}`}
              onClick={() => handleButtonClick(panel)}
              disabled={panel.title === "Community" && !hasPaidPlan}
            >
              {getButtonText(panel.title)}
            </Button>
           
            <div className="featuress">
              {panel.features.map((feature, i) => (
                <div key={i} className="feature-itemm">
                  {feature}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .pricing-container {
          font-family: Arial, sans-serif;
          padding: 20px;
          max-width: 1200px;
          margin: 0 auto;
          background-color: #1e1e1e;
          color: white;
        }
        .tab-buttons {
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
        }
        .tab-buttons button {
          margin: 0 5px;
        }
        .pricing-panels {
          display: flex;
          justify-content: center;
          gap: 20px;
        }
        .pricing-panel {
          width: 100%;
          max-width: 300px;
          border: 1px solid #444;
          border-radius: 10px;
          padding: 20px;
          text-align: center;
          transition: all 0.3s ease;
          background-color: #2a2a2a;
        }
        .pricing-panel.highlighted {
          border: 2px solid #1890ff;
        }
        h2 {
          font-size: 24px;
          margin-bottom: 10px;
        }
        .price {
          font-size: 32px;
          font-weight: bold;
          margin: 20px 0 5px;
        }
        .price-period {
          font-size: 16px;
          font-weight: normal;
        }
        .billing-period {
          font-size: 14px;
          color: #999;
          margin-bottom: 20px;
        }
        .subscribe-button {
          width: 100%;
          margin-bottom: 10px;
        }
        .subscribe-button.active {
          background-color: #ff4d4f;
          border-color: #ff4d4f;
        }
        .annual-saving {
          font-size: 14px;
          color: #1890ff;
          margin-bottom: 20px;
        }
        .featuress {
          text-align: left;
        }
        .feature-itemm {
          margin: 15px 0;
          font-size: 14px;
        }
      `}</style>
    </div>
  );
}
import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Alert, Select, Button, message, Spin } from "antd";
import Game from "./GameCode/Game";
import SearchContext from "../../../../Context/SearchContext";
import axios from "axios";
import AceEditor from "react-ace";
// import Editor from '@monaco-editor/react';
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import GameAssets from "./gameComponents/GameAssets";
import Versions from "./gameComponents/Versions";

const { Option } = Select;

export default function SearchThumbnail() {
  const { left } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("assets");
  const [selectedFile, setSelectedFile] = useState(null);
  const [codeContent, setCodeContent] = useState("");
  const [originalCode, setOriginalCode] = useState("");
  const [isCodeChanged, setIsCodeChanged] = useState(false);
  const [vectorId, setVectorId] = useState(null);
  const [images, setImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (activeTab === "assets" && left.chatData?._id) {
      fetchImages();
    } else if (
      activeTab === "code" &&
      left.chatData?.files &&
      left.chatData.files.length > 0
    ) {
      const firstFile = left.chatData.files[0];
      setSelectedFile(firstFile);
      fetchCodeContent(firstFile);
    }
  }, [activeTab, left.chatData]);

  const fetchImages = async () => {
    setImageLoading(true);
    try {
      const response = await axios.get("https://embed.axv.ai/api/block", {
        params: {
          data_id: left.chatData._id,
          status: "complete",
        },
      });

      if (response.data?.blockList) {
        setImages(response.data?.blockList);
      } else {
        setImages([]);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
      message.error("Failed to fetch images");
    }
    setImageLoading(false);
  };

  const fetchCodeContent = async (fileName) => {
    try {
      const response = await axios.get("https://embed.axv.ai/api/vector", {
        params: {
          data_id: left.chatData._id,
        },
      });
      if (
        response.data &&
        response.data.vectorList &&
        response.data.vectorList.length > 0
      ) {
        const vector = response.data.vectorList[0];
        if (vector.code) {
          setCodeContent(vector.code);
          setOriginalCode(vector.code);
          setVectorId(vector._id);
        } else {
          setCodeContent(`// No code content available for ${fileName}`);
          setOriginalCode(`// No code content available for ${fileName}`);
          setVectorId(null);
        }
      } else {
        setCodeContent(`// No data available for ${fileName}`);
        setOriginalCode(`// No data available for ${fileName}`);
        setVectorId(null);
      }
      setIsCodeChanged(false);
    } catch (error) {
      console.error("Error fetching code content:", error);
      setCodeContent(`// Error fetching code content for ${fileName}`);
      setOriginalCode(`// Error fetching code content for ${fileName}`);
      setVectorId(null);
    }
  };

  const handleCodeChange = (newValue) => {
    setCodeContent(newValue);
    setIsCodeChanged(newValue !== originalCode);
  };

  const handleSaveCode = async () => {
    if (!vectorId) {
      message.error("Cannot save: Vector ID is missing");
      return;
    }
    try {
      const response = await axios.put(
        `https://embed.axv.ai/api/vector/${vectorId}`,
        {
          code: codeContent,
        }
      );
      if (response.status === 200) {
        message.success("Code saved successfully");
        setOriginalCode(codeContent);
        setIsCodeChanged(false);
      } else {
        message.error("Failed to save code");
      }
    } catch (error) {
      console.error("Error saving code:", error);
      message.error("Error saving code");
    }
  };

  const renderAssets = () => <GameAssets data={images} />;
  const renderVersions = () => <Versions data={images} />;
  
  const renderCode = () => (
    <div className="code-content">
      {isCodeChanged && (
        <button
          className="custom-button save-btn-cs"
          onClick={handleSaveCode}
          style={{ marginTop: "10px" }}
        >
          Save Changes
        </button>
      )}
      {selectedFile ? (
        <>
          {/* <Editor
      height="100vh"
      defaultLanguage="html"
      theme="vs-dark"
      value={codeContent}
      onChange={handleCodeChange}
      options={{
        selectOnLineNumbers: true,
        automaticLayout: true,
      }}
    /> */}
          <AceEditor
            mode="html"
            theme="monokai"
            name="code-editor"
            // editorProps={{ $blockScrolling: true }}
            value={codeContent}
            onChange={handleCodeChange}
            width="100%"
            height="100%"
            onCursorChange={(a, b) => console.log(a, b)}
          />
        </>
      ) : (
        <div>Select a file to view its content</div>
      )}
    </div>
  );

  const renderLive = () => (
    <div className="live-content">
      <Game chatData={left.chatData} />
    </div>
  );

  const renderContent = () => {
    switch (activeTab) {
      case "assets":
        return renderAssets();
      case "code":
        return renderCode();
      case "live":
        return renderLive();
      case "version":
        return renderVersions();

      default:
        return null;
    }
  };

  return loading ? null : (
    <div className="chat-bx-mn">
      <div className="act-logs-fcvhjg">
        <div className="bx-main-sel-prf">
          <span
            onClick={() => setActiveTab("version")}
            className={`btn-appr select-tcbn-a ${
              activeTab === "version" ? "btn-apr-s" : ""
            }`}
          >
            <span>Version</span>
          </span>
          <span
            onClick={() => setActiveTab("code")}
            className={`btn-appr select-tcbn-a ${
              activeTab === "code" ? "btn-apr-s" : ""
            }`}
          >
            <span>Code</span>
          </span>
          <span
            onClick={() => setActiveTab("assets")}
            className={`btn-appr select-tcbn-a ${
              activeTab === "assets" ? "btn-apr-s" : ""
            }`}
          >
            <span>Assets</span>
          </span>
        </div>
      </div>
      {renderContent()}
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Radio,
  Spin,
  Tooltip,
  message,
} from "antd";
import { TiTick } from "react-icons/ti";
import { FaQuestionCircle } from "react-icons/fa";
import moment from "moment";
import ClassifyLabelsArray from "./ClassifyLabelsArray";
import GenerateImage from "./GenerateImage";

const { RangePicker } = DatePicker;
let length = 0;
let oldData = [];
const dateFormat = "YYYY-MM-DD";

export default function Generate() {
  const { generate, setGenerate, assist, setType, user, setassist } =
    useContext(SearchContext);
  const [loading, setLoading] = useState(false);
  const [asyncModal, setAsyncModal] = useState(false);
  const [search, setSearch] = useState("");
  const [ivType, setIVtype] = useState("image");
  const [date, setDate] = useState({});
  const [Description, setDescription] = useState();

  // useEffect(() => {
  //   setDate({
  //     start: moment(new Date()).format("YYYY-MM-DD"),
  //     end: moment(new Date()).format("YYYY-MM-DD"),
  //   });
  // }, []);

  const clickGenerate = () => {
    if (generate.activeType === undefined) {
      message.warning("Please select Type");
      setLoading(false);
      return;
    }
    if ((date.start && date.end) || generate.isMoments) {
      getDate();
    } else {
      const r1 = generate.selectedIMG
        ? "image"
        : generate.selectedVID
        ? "video"
        : undefined;
      if (r1 === undefined) {
        message.warning("Please select a file");
        setLoading(false);
        return;
      }

      oldData = [];
      length = 1;
      let conditions;
      if (generate.activeType === "classify" && Description) {
        for (let i = 0; i < Description.length; i++) {
          conditions = conditions
            ? conditions + " /n " + Description[i].label
            : Description[i].label;
        }
      }
      if (generate.selectedIMG && generate.selectedIMG.length) {
        for (let i = 0; i < generate.selectedIMG.length; i++) {
          getData(
            generate.selectedIMG[i]._id,
            generate.selectedIMG.length,
            "selectedIMG",
            conditions
          );
        }
      } else if (generate.selectedVID && generate.selectedVID.length) {
        for (let i = 0; i < generate.selectedVID.length; i++) {
          getData(
            generate.selectedVID[i]._id,
            generate.selectedVID.length,
            "selectedVID",
            conditions
          );
        }
      }
    }
  };

  const getData = (id, l, key, conditions) => {
    const r1 = generate.selectedIMG
      ? "image"
      : generate.selectedVID
      ? "video"
      : "";
    const r2 = id;
    const a = generate.async || l > 19 ? "/async" : "";
    if (a === "/async") {
      setAsyncModal(
        "Your generation has started in the background and you can check the status anytime in the Async tab"
      );
    }
    const obj = { conditions, user_id: user.user_id };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://int.display.video/api/list/generation${a}/${r1}/${generate.activeType}/${r2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Insufficient compute available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        if (data && data.isSuccess && data.message !== "Process Started") {
          if (length === l) {
            let d = data.listData;

            setGenerate({
              ...generate,
              data: d.concat(oldData),
              [key]: undefined,
              clips: undefined,
            });
            setLoading(false);
            setType("Generate");
          } else {
            oldData = oldData.concat(data.listData);
            length = length + 1;
          }
        } else if (data.message !== "Process Started") {
          message.warning("Please Try Again");
        } else {
          setLoading(false);
        }
      });
  };

  const getVideos = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));
    if (assist.selAssetID2 === undefined) {
      message.warning("Select a video ");
    } else {
      const requestOptions = {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      };
      fetch(
        `https://int.display.video/api/image?page=${1}&limit=40&clip=long&startTime=${1}&headclip_id=${
          assist.selAssetID2 ? assist.selAssetID2._id : "no"
        }`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          if (data.message === "Insufficient compute available") {
            setassist({
              ...assist,
              isUpgrade: true,
            });
          }
          if (data && data.isSuccess) {
            setGenerate({
              ...generate,
              clips: data.imageList,
              data: undefined,
            });
            setType("Generate");
          } else {
            message.warning("Something wentt wrong. Please try again");
          }
        });
    }
  };
  const getDate = () => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const r1 = ivType;

    let start = moment(new Date()).format("YYYY-MM-DD");
    let end = moment(new Date()).subtract(60, "days").format("YYYY-MM-DD");
    start = generate.isDate && date.start ? date.start : start;
    end = generate.isDate && date.end ? date.end : end;
    console.log(generate, date);
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/list/generation/date/${r1}/${
        generate.activeType
      }?user_id=${
        user && user.user_id
      }&dateTo=${start}&dateFrom=${end}&search=${
        generate.isMoments ? search : ""
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.message === "Insufficient compute available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        setLoading(false);
        if (
          response &&
          response.isSuccess &&
          response.message === "Process Started"
        ) {
          setGenerate({
            ...generate,
            selectedIMG: undefined,
            selectedVID: undefined,
          });
          setSearch("");
          setAsyncModal(asyncModal ? asyncModal : "this is date selection");
        }
      });
  };
  const changeDate = (e) => {
    const start = moment(e[0].$d).format("YYYY-MM-DD");
    const end = moment(e[1].$d).format("YYYY-MM-DD");
    setDate({ start, end });
  };
  return (
    <div className="right-bx-mn-crd chat-bx-mn">
      <div className="bx-main-sel-prf">
        <span
          onClick={() =>
            setGenerate({
              ...generate,
              activeRight: "Data Science",
              activeType: undefined,
            })
          }
          className={`btn-appr select-tcbn-a ${
            generate.activeRight === "Data Science" ? " btn-apr-s" : ""
          }`}
        >
          <span>Classification</span>
        </span>
        {generate.activeRight === "Data Science" ? (
          <div className="inner-tabs-bx-tp">
            <div style={{ flexDirection: "row" }} className="bx-main-sel-prf">
              <span
                onClick={() => {
                  setGenerate({
                    ...generate,
                    activeType: "classify",
                    isLabel: true,
                    isMoments: false,
                    isDate: false,
                    async: false,
                  });
                  setDate({});
                  setDescription([
                    {
                      content: "",
                      id: 0,
                    },
                  ]);
                }}
                className={`btn-appr select-tcbn-a pp-prof `}
              >
                <span>
                  Classify{" "}
                  {generate.activeType === "classify" ? <TiTick /> : ""}
                </span>
              </span>
            </div>
          </div>
        ) : null}
        <span
          onClick={() =>
            setGenerate({
              ...generate,
              activeRight: "Video Editing",
              activeType: undefined,
            })
          }
          className={`btn-appr select-tcbn-a ${
            generate.activeRight === "Video Editing" ? " btn-apr-s" : ""
          }`}
        >
          <span>Video Only</span>
        </span>
        {generate.activeRight === "Video Editing" ? (
          <div className="inner-tabs-bx-tp">
            <div style={{ flexDirection: "row" }} className="bx-main-sel-prf">
              <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "clips" })
                }
                className={`btn-appr select-tcbn-a pp-prof`}
              >
                <span>
                  Clips {generate.activeType === "clips" ? <TiTick /> : ""}
                </span>
              </span>
             
              <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "video_ideas" })
                }
                className={`btn-appr select-tcbn-a pp-prof`}
              >
                <span>
                  Idea Generation{" "}
                  {generate.activeType === "video_ideas" ? <TiTick /> : ""}
                </span>
              </span>
              <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "audio_description" })
                }
                className={`btn-appr select-tcbn-a pp-prof`}
              >
                <span>
                  Audio Description{" "}
                  {generate.activeType === "audio_description" ? (
                    <TiTick />
                  ) : (
                    ""
                  )}
                </span>
              </span>
              <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "video_short_ideas" })
                }
                className={`btn-appr select-tcbn-a pp-prof`}
              >
                <span>
                  Short Video Ideas{" "}
                  {generate.activeType === "video_short_ideas" ? (
                    <TiTick />
                  ) : (
                    ""
                  )}
                </span>
              </span>
            </div>
          </div>
        ) : null}
        <span
          onClick={() =>
            setGenerate({
              ...generate,
              activeRight: "Marketing",
              activeType: undefined,
            })
          }
          className={`btn-appr select-tcbn-a ${
            generate.activeRight === "Marketing" ? " btn-apr-s" : ""
          }`}
        >
          <span>Marketing</span>
        </span>
        {generate.activeRight === "Marketing" ? (
          <div className="inner-tabs-bx-tp">
            <div style={{ flexDirection: "row" }} className="bx-main-sel-prf">
              <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "description" })
                }
                className={`btn-appr select-tcbn-a pp-prof`}
              >
                <span>
                  Description{" "}
                  {generate.activeType === "description" ? <TiTick /> : ""}
                </span>
              </span>
              <span
                onClick={() => setGenerate({ ...generate, activeType: "ad" })}
                className={`btn-appr select-tcbn-a pp-prof `}
              >
                <span>
                  Advertising {generate.activeType === "ad" ? <TiTick /> : ""}
                </span>
              </span>
              <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "buyer_persona" })
                }
                className={`btn-appr select-tcbn-a pp-prof`}
              >
                <span>
                  Buyer Persona{" "}
                  {generate.activeType === "buyer_persona" ? <TiTick /> : ""}
                </span>
              </span>
              <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "linkedin_post" })
                }
                className={`btn-appr select-tcbn-a linkedin-c`}
              >
                <span>
                  LinkedIn{" "}
                  {generate.activeType === "linkedin_post" ? <TiTick /> : ""}
                </span>
              </span>

              <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "facebook_ad" })
                }
                className={`btn-appr select-tcbn-a facebook-c  `}
              >
                <span>
                  Facebook Ad{" "}
                  {generate.activeType === "facebook_ad" ? <TiTick /> : ""}
                </span>
              </span>
              <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "instagram_caption" })
                }
                className={`btn-appr select-tcbn-a instagram-c`}
              >
                <span>
                  Instagram Caption{" "}
                  {generate.activeType === "instagram_caption" ? (
                    <TiTick />
                  ) : (
                    ""
                  )}
                </span>
              </span>
              <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "twitter" })
                }
                className={`btn-appr select-tcbn-a twitter-c`}
              >
                <span>
                  Twitter {generate.activeType === "twitter" ? <TiTick /> : ""}
                </span>
              </span>
            </div>
          </div>
        ) : null}
        <span
          onClick={() =>
            setGenerate({
              ...generate,
              activeRight: "Image Generation",
              activeType: undefined,
            })
          }
          className={`btn-appr select-tcbn-a ${
            generate.activeRight === "Image Generation" ? " btn-apr-s" : ""
          }`}
        >
          <span>Image Generation</span>
        </span>
        {generate.activeRight === "Image Generation" ? (
          <div className="inner-tabs-bx-tp">
            <div style={{ flexDirection: "row" }} className="bx-main-sel-prf">
             {/* <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "custom_image" })
                }
                className={`btn-appr select-tcbn-a pp-prof`}
              >
                <span>
                  Custom{" "}
                  {generate.activeType === "custom_image" ? <TiTick /> : ""}
                </span>
              </span> */}
              <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "non_image" })
                }
                className={`btn-appr select-tcbn-a pp-prof`}
              >
                <span>
                  Variation{" "}
                  {generate.activeType === "non_image" ? <TiTick /> : ""}
                </span>
              </span>
              
              
            </div>
          </div>
        ) : null}
         <span
          onClick={() =>
            setGenerate({
              ...generate,
              activeRight: "Video Generation",
              activeType: undefined,
            })
          }
          className={`btn-appr select-tcbn-a ${
            generate.activeRight === "Video Generation" ? " btn-apr-s" : ""
          }`}
        >
          <span>Video Generation</span>
        </span>
        {generate.activeRight === "Video Generation" ? (
          <div className="inner-tabs-bx-tp">
            <div style={{ flexDirection: "row" }} className="bx-main-sel-prf">
             {/* <span
                onClick={() =>
                  setGenerate({ ...generate, activeType: "custom_image" })
                }
                className={`btn-appr select-tcbn-a pp-prof`}
              >
                <span>
                  Custom{" "}
                  {generate.activeType === "custom_image" ? <TiTick /> : ""}
                </span>
              </span> */}
              <span
        className={`btn-appr select-tcbn-a pp-prof ${
          generate.activeType === "non_image" ? "btn-apr-s" : "disabled"
        }`}
      >
        <span>
          Variation - Coming Soon{" "}
          
        </span>
      </span>
            </div>
          </div>
        ) : null}
      </div>
      {generate.activeRight === "Image Generation" ? (
        <GenerateImage />
      ) : (
        <div className="load-gen-bx1">
          <div className="load-gen-bx">
            {loading ? (
              <div class="loader32"></div>
            ) : generate.activeType === "clips" ? (
              <button
                onClick={() => {
                  setLoading(true);
                  getVideos();
                }}
                size="small"
                className="glow-on-hover"
              >
                Generate
              </button>
            ) : (
              <span className="async-bxcs">
                {generate.activeType !== "classify" ? (
                  <div
                    onClick={() =>
                      setGenerate({
                        ...generate,
                        async: generate.async ? false : true,
                      })
                    }
                    className={`btn-appr select-tcbn-a ${
                      generate.async ? " btn-apr-s" : " red-vsc"
                    }  `}
                  >
                    <span>
  Async
  {generate.async ? (
    <TiTick />
  ) : (
    <Tooltip
      className=" "
      placement="right"
      title="Async allows you to run generation jobs in the background. Follow up later using the Async tab. "
    >
      <FaQuestionCircle
        style={{ marginLeft: "5px", fontSize: "10px" }}
        className="aut-q-lf q-btn-ascncs"
      />
    </Tooltip>
  )}
</span>

                  </div>
                ) : null}
                {generate.activeType !== "classify" ? (
                  <div
                    onClick={() => {
                      setGenerate({
                        ...generate,
                        isDate: generate.isDate ? false : true,
                      });
                      setDate({});
                    }}
                    className={`btn-appr select-tcbn-a  ml-0span${
                      generate.isDate ? " btn-apr-s" : " red-vsc"
                    }  `}
                  >
                    <span>
  Date
  {generate.isDate ? (
    <TiTick />
  ) : (
    <Tooltip
      className=" "
      placement="right"
      title="Date allows you to create generations based on when the data was created on AxV"
    >
      <FaQuestionCircle
        style={{ marginLeft: "5px", fontSize: "10px" }}
        className="aut-q-lf q-btn-ascncs"
      />
    </Tooltip>
  )}
</span>

                  </div>
                ) : null}
                {generate.activeType !== "classify" ? (
                  <div
                    onClick={() => {
                      setGenerate({
                        ...generate,
                        isMoments: generate.isMoments ? false : true,
                      });
                      setDate({});
                    }}
                    className={`btn-appr select-tcbn-a  ml-0span${
                      generate.isMoments ? " btn-apr-s" : " red-vsc"
                    }  `}
                  >
                   <span>
  Moments
  {generate.isMoments ? (
    <TiTick />
  ) : (
    <Tooltip
      className=" "
      placement="right"
      title="Moment allows you to generate data based on moment or object inside your visual data"
    >
      <FaQuestionCircle
        style={{ marginLeft: "5px", fontSize: "10px" }}
        className="aut-q-lf q-btn-ascncs"
      />
    </Tooltip>
  )}
</span>
                  </div>
                ) : null}

                {generate.activeType === "classify" ? (
                  <div
                    onClick={() => {
                      setGenerate({
                        ...generate,
                        isLabel: generate.isLabel ? false : true,
                      });
                      setDate({});
                    }}
                    className={`btn-appr select-tcbn-a  ml-0span${
                      Description ? " btn-apr-s" : " red-vsc"
                    }  `}
                  >
                    <span>
                      Label
                      {Description ? (
                        <TiTick />
                      ) : (
                        <Tooltip
                          className=" "
                          placement="right"
                          title="To change the email on your account, please contact us."
                        >
                          <FaQuestionCircle
                            style={{ marginLeft: "5px", fontSize: "10px" }}
                            className="aut-q-lf q-btn-ascncs"
                          />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                ) : null}
                <ClassifyLabelsArray
                  Description={Description}
                  setDescription={setDescription}
                />
                <button
                  onClick={() => {
                    setLoading(true);
                    clickGenerate();
                  }}
                  className="glow-on-hover"
                  style={{ marginLeft: "10px" }}
                >
                  Generate
                </button>
              </span>
            )}
          </div>
          {(generate.isDate || generate.isMoments) && !loading ? (
            <div>
              <Radio.Group
                onChange={(e) => setIVtype(e.target.value)}
                value={ivType}
              >
                <Radio value="image">Images</Radio>
                <Radio value="video">Video</Radio>
              </Radio.Group>
            </div>
          ) : null}
          <div>
            {generate.isDate && !loading ? (
              <RangePicker
                className="gen-mom-input"
                onChange={(e) => changeDate(e)}
                format={dateFormat}
                // defaultValue={[
                //   moment(date.start, dateFormat),
                //   moment(date.end, dateFormat),
                // ]}
              />
            ) : null}
          </div>
          <div>
            {generate.isMoments && !loading ? (
              <Input
                value={search}
                placeholder="Search"
                className="gen-mom-input"
                onChange={(e) => setSearch(e.target.value)}
              />
            ) : null}
          </div>
        </div>
      )}
      <Modal
        width={400}
        className="no-h-bo-b-modal brand-modal2 brand-modal chat-mmmd "
        open={asyncModal}
        onCancel={() => setAsyncModal(false)}
      >
        <h5>Process Started</h5>
        <div>{asyncModal}</div>
        <div className="ok-button-bx-m">
          <Button
            onClick={() => setAsyncModal(false)}
            type="primary"
            size="small"
          >
            Ok
          </Button>
        </div>
      </Modal>
    </div>
  );
}

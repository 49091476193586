import React, { useContext } from "react";
import SearchContext from "../../../../Context/SearchContext";
import Icons from "../../../../components/Icons";
import { Button } from "antd";
import {
  MdInsights,
  MdOutlineCreate,
  MdOutlineImageSearch,
} from "react-icons/md";
import { BsCollectionPlayFill, BsMagic } from "react-icons/bs";

export default function New() {
  const { theme, setOrganization, organization, user } =
    useContext(SearchContext);

  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };

  const logout = () => {
    window.localStorage.removeItem("user");
    window.location.reload();
  };

  return (
    <div className="onb-main-bx">
      <div>
      <div className="logo-login-c">

<img
      src={Icons["white-logo"]}
      alt=""
      style={{ width: "30px", height: "30px" }}
    />
 
</div>
        <div className="onb-m-new" style={{ fontFamily: 'Helvetica, Arial, sans-serif' }}>

        <h1 className="large-title"> Welcome To Dendric</h1>
  <p style={{ fontSize: '20px' }}>
  "Where we want to empower you to bring your vision to life at lightning speed. 
  Dendric helps you craft stunning visuals, immersive sounds, and dynamic content in minutes, for your game freeing you to focus on what truly matters: creating unforgettable experiences."
  </p>
          <div className="icons-bx-main-ne">
            <div>
              <MdOutlineImageSearch />
              <b>Plan</b>
            </div>
            <div>
              <MdOutlineCreate />
              <b>Create</b>
            </div>
            <div>
              <BsMagic />
              <b>Refine</b>
            </div>
            {/* <div>
              <MdInsights />
              <b>Analyze</b>
            </div> */}
            <div>
              <BsCollectionPlayFill />
              <b> Market </b>
            </div>
          </div>
        </div>
      </div>
      <div className="onb-m-bot">
        <Button className="custom-button orange-btn-cs"
          onClick={() => {
            logout();
          }}
          
        >
          Sign Out
        </Button>
        <Button className="custom-button orange-btn-cs"
          onClick={() => {
            updateOrganization("onboard", "verification");
            setOrganization({ ...organization, onboard: "verification" });
          }}
        
        >
          Next
        </Button>
      </div>
    </div>
  );
}

import {
    Alert,
    Button,
    Col,
    message,
    Modal,
    Popconfirm,
    Input,
    Progress,
    Row,
    Spin,
  } from "antd";
  import moment from "moment";
  import React, { useContext, useEffect, useState } from "react";
  import ReactPlayer from "react-player";
  import ReactAudioPlayer from "react-audio-player";
  import { MdClose, MdKeyboardBackspace } from "react-icons/md";
  import Paragraph from "antd/lib/typography/Paragraph";
  import UploadAssets from "../UploadAssets";
  import SearchContext from "../../../../Context/SearchContext";
  import { FaMinus, FaPlay, FaUpload, FaMusic, FaInfo } from "react-icons/fa";
  import { TiTick } from "react-icons/ti";
  import AssetsEdit from "./AssetsEdit";
  
  export default function AssestsGame({ active, setActive }) {
    const {
      images,
      setImages,
      theme,
      setassist,
      assist,
      right,
      setRight,
      generate,
      user,
      type,
      setType,
      setGenerate,
    } = useContext(SearchContext);
    const playerRef = React.useRef();
  
    const [loading2, setLoading2] = useState(true);
    const [callApi, setCallApi] = useState(1);
    const [callApi2, setCallApi2] = useState(1);
    const [callApi3, setCallApi3] = useState(1);
    const [refresh, setRefresh] = useState(1);
    const [loading, setLoading] = useState(false);
    const [play, setPlay] = useState();
    const [act, setAct] = useState("");
    const [loadingP, setLoadingP] = useState(true);
    const [isGen, setIsGen] = useState(false);
  
    useEffect(() => {
      setTimeout(() => {
        setLoadingP(false);
      }, 500);
    }, []);
    
    useEffect(() => {
      if (images.pageNo !== 1) {
        getImages();
      }
      if (images.data && images.pageNo === 1) {
        setLoading2(false);
      }
    }, [callApi]);
  
    useEffect(() => {
      if (images.pageNoV !== 1) {
        getVideos();
      }
    }, [callApi2]);
  
    useEffect(() => {
      if (images.pageNoA !== 1) {
        getAudios();
      }
    }, [callApi3]);
  
    useEffect(() => {
      if (type !== "video" && type !== "audio" && images.data === undefined) {
        getImages();
      }
      if (type === "video" && images.videos === undefined) {
        getVideos();
      }
      if (type === "audio" && images.audios === undefined) {
        getAudios();
      }
    }, [type, isGen, refresh]);
  
    const getVideos = () => {
      const isID = JSON.parse(window.localStorage.getItem("isID"));
  
      const requestOptions = {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      };
      
      fetch(
        `https://embed.axv.ai/api/image?page=${images.pageNoV}&limit=15&sort_by=${-1}&user_id=${user ? user.user_id : "map"}&media_type=original&status=${isGen ? "generated" : ""}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setLoading2(false);
          if (data && data.isSuccess) {
            setImages({
              ...images,
              videos: images.videos
                ? images.videos.concat(data.imageList)
                : [].concat(data.imageList),
              totalPages2: data.totalPages,
            });
          } else {
            message.warning("Something went wrong");
          }
        });
    };
  
    const getImages = () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      };
      fetch(
        `https://embed.axv.ai/api/image?page=${images.pageNo}&limit=12&sort_by=${-1}&user_id=${user ? user.user_id : "map"}&media_type=image&status=${isGen ? "generated" : ""}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setLoading2(false);
          if (data && data.isSuccess) {
            setImages({
              ...images,
              data: images.data
                ? images.data.concat(data.imageList)
                : [].concat(data.imageList),
              totalPages: data.totalPages,
            });
          } else {
            message.warning("Something went wrong");
          }
        });
    };
  
    const getAudios = () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      };
      fetch(
        `https://embed.axv.ai/api/image?page=${images.pageNoA}&limit=15&sort_by=${-1}&user_id=${user ? user.user_id : "map"}&media_type=sound&status=${isGen ? "generated" : ""}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setLoading2(false);
          if (data && data.isSuccess) {
            setImages({
              ...images,
              audios: images.audios
                ? images.audios.concat(data.imageList)
                : [].concat(data.imageList),
              totalPages3: data.totalPages,
            });
          } else {
            message.warning("Something went wrong");
          }
        });
    };
  
    const loadMore = (v, key) => {
      if (
        v.target.scrollHeight === v.target.offsetHeight + v.target.scrollTop &&
        images.totalPages > images.pageNo &&
        !loading2
      ) {
        setImages({
          ...images,
          pageNo: images.pageNo + 1,
          scrollHeight: v.target.scrollHeight,
        });
        setLoading2(true);
        setCallApi(callApi + 1);
      }
    };
  
    const loadMore2 = (v, key) => {
      if (
        v.target.scrollHeight === v.target.offsetHeight + v.target.scrollTop &&
        images.totalPages2 > images.pageNoV &&
        !loading2
      ) {
        setImages({
          ...images,
          pageNoV: images.pageNoV + 1,
          scrollHeight: v.target.scrollHeight,
        });
        setLoading2(true);
        setCallApi2(callApi2 + 1);
      }
    };
  
    const loadMore3 = (v, key) => {
      if (
        v.target.scrollHeight === v.target.offsetHeight + v.target.scrollTop &&
        images.totalPages3 > images.pageNoA &&
        !loading2
      ) {
        setImages({
          ...images,
          pageNoA: images.pageNoA + 1,
          scrollHeight: v.target.scrollHeight,
        });
        setLoading2(true);
        setCallApi3(callApi3 + 1);
      }
    };
  
    const deleteTask = (row, key) => {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      };
      fetch(`https://embed.axv.ai/api/image/${row._id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Deleted");
            const all = images[key].filter((p) => p._id !== row._id);
            setImages({ ...images, [key]: all });
          } else {
            message.error("Something Went Wrong");
          }
        });
    };
  
    const adjustClick = (im) => {
      if (
        right.activeRight !== "Generate" ||
        generate.activeType === "custom_image"
      ) {
        setassist({
          ...assist,
          selAssetID: im,
        });
  
        setRight({
          ...right,
          activeAI: "message",
          activeIcon: undefined,
        });
      } else {
        const all = generate.selectedIMG ? [...generate.selectedIMG] : [];
        const find = all.filter((f) => f._id === im._id);
  
        if (find.length) {
          setGenerate({
            ...generate,
            selectedIMG: all.filter((f) => f._id !== im._id),
            selectedVID: undefined,
            selectedAUD: undefined,
          });
        } else {
          setGenerate({
            ...generate,
            selectedIMG: [...all, im],
            selectedVID: undefined,
            selectedAUD: undefined,
          });
        }
      }
    };
  
    const adjustClickV = (im) => {
      if (
        right.activeRight !== "Generate" ||
        generate.activeType === "clips" ||
        generate.activeType === "custom_image"
      ) {
        setassist({
          ...assist,
          selAssetID2: im,
          selAssetID: undefined,
          upImage: undefined,
        });
      } else {
        const all = generate.selectedVID ? [...generate.selectedVID] : [];
        const find = all.filter((f) => f._id === im._id);
  
        if (find.length) {
          setGenerate({
            ...generate,
            selectedVID: all.filter((f) => f._id !== im._id),
            selectedIMG: undefined,
            selectedAUD: undefined,
          });
        } else {
          setGenerate({
            ...generate,
            selectedVID: [...all, im],
            selectedIMG: undefined,
            selectedAUD: undefined,
          });
        }
      }
    };
  
    const adjustClickA = (im) => {
      setassist({
        ...assist,
        selAssetID3: im,
        selAssetID: undefined,
        selAssetID2: undefined,
        upImage: undefined,
      });
    };
  
    return loadingP ? null : (
      <div className="activity-log-main-c guide-main-chat-r">
        <div className="act-logs-fcvhjg">
          <div className="bx-main-sel-prf">
            <span
              onClick={() => setType("image")}
              className={`btn-appr select-tcbn-a ${type === "image" ? " btn-apr-s" : ""}`}
            >
              <FaInfo alt="Images" /> {/* Icon for Images */}
            </span>
            <span
              onClick={() => setType("video")}
              className={`btn-appr select-tcbn-a ${type === "video" ? " btn-apr-s" : ""}`}
            >
              <FaPlay alt="Videos" /> {/* Icon for Videos */}
            </span>
            <span
              onClick={() => setType("audio")}
              className={`btn-appr select-tcbn-a ${type === "audio" ? " btn-apr-s" : ""}`}
            >
              <FaMusic alt="Audio" /> {/* Icon for Audio */}
            </span>
          </div>
        </div>
  
        {type === "image" ? (
          <div onScroll={loadMore} className="act-logs-fcv">
            <Row gutter={[16, 16]}>
              {images.data && images.data.map((im, imi) => (
                <Col key={imi} lg={12} md={12} sm={24} xs={24}>
                  <div className={`img-bx-imgsc ${assist.selAssetID?._id === im._id ? " sel-asset-c" : ""}`}>
                    <div className="img-bx-mnb">
                      <img
                        onClick={() => adjustClick(im)}
                        src={im.file_url}
                        alt=""
                      />
                    </div>
  
                    <div className="close-icn-asst-c">
                      <AssetsEdit row={im} type="data" />
                      <Popconfirm
                        title="Delete Image"
                        description="Are you sure you want to delete this image?"
                        onConfirm={() => deleteTask(im, "data")}
                        okText="Yes"
                        cancelText="No"
                      >
                        <MdClose />
                      </Popconfirm>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            {images.data.length === 0 && (
              <div className="no-heigh-div-c ">
                <Alert message="No Assets Exist" type="info" showIcon className="brand-alert bg-txt-a" />
              </div>
            )}
            {loading2 && <Spin />}
          </div>
        ) : null}
  
        {type === "video" ? (
          <div onScroll={loadMore2} className="act-logs-fcv">
            {images.videos && images.videos.length === 0 && (
              <div className="no-heigh-div-c ">
                <Alert message="No Assets Exist" type="info" showIcon className="brand-alert bg-txt-a" />
              </div>
            )}
            <Row gutter={[16, 16]}>
              {images.videos && images.videos.map((im, imi) => (
                <Col key={imi} lg={12} md={12} sm={24} xs={24}>
                  <div className={`img-bx-imgsc vd-bx-nn ${assist.selAssetID2?._id === im._id ? " sel-asset-c" : ""}`}>
                    <div onClick={() => adjustClickV(im)} className="vd-nm-pr">
                      <ReactPlayer url={im.file_url} controls={false} />
                      <div className="icons-bx-ri-vd">
                        <FaPlay onClick={() => setPlay(im)} />
                      </div>
                    </div>
                    <div className="close-icn-asst-c">
                      <AssetsEdit row={im} type="videos" />
                      <Popconfirm
                        title="Delete Video"
                        description="Are you sure to delete this video?"
                        onConfirm={() => deleteTask(im, "videos")}
                        okText="Yes"
                        cancelText="No"
                      >
                        <MdClose />
                      </Popconfirm>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            {loading2 && <Spin />}
          </div>
        ) : null}
  
        {type === "audio" ? (
          <div onScroll={loadMore3} className="act-logs-fcv">
            {images.audios && images.audios.length === 0 && (
              <div className="no-heigh-div-c ">
                <Alert message="No Assets Exist" type="info" showIcon className="brand-alert bg-txt-a" />
              </div>
            )}
            <Row gutter={[16, 16]}>
              {images.audios && images.audios.map((im, imi) => (
                <Col key={imi} lg={12} md={12} sm={24} xs={24}>
                  <div className={`img-bx-imgsc aud-bx-nn ${assist.selAssetID3?._id === im._id ? " sel-asset-c" : ""}`}>
                    <div onClick={() => adjustClickA(im)} className="aud-nm-pr">
                      <FaMusic style={{ fontSize: "40px", color: "white" }} />
                    </div>
                    <div className="close-icn-asst-c">
                      <AssetsEdit row={im} type="audios" />
                      <Popconfirm
                        title="Delete Audio"
                        description="Are you sure to delete this audio?"
                        onConfirm={() => deleteTask(im, "audios")}
                        okText="Yes"
                        cancelText="No"
                      >
                        <MdClose />
                      </Popconfirm>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            {loading2 && <Spin />}
          </div>
        ) : null}
      </div>
    );
  }
import React, { useContext } from "react";
import HandleLogin from "./HandleLogin";
import { Link } from "react-router-dom";
import Icons from "./Icons";
import SearchContext from "../Context/SearchContext";
export default function Header() {
  const { load } = useContext(SearchContext);
  return (
    <div className="headi-tbc-mb">
      <div className="visual-t-hln">
        <Link to="/">
        <img
        className={`${load ? "spin-logo" : ""}`}
        src={Icons["white-logo"]}
        alt=""
        style={{ width: "50px", height: "50px" }}
      />
        </Link>
      </div>
      <HandleLogin />
    </div>
  );
}

import React, { useEffect, useRef } from 'react';

const Game = () => {
  const canvasRef = React.useRef(null);
  const [gameState, setGameState] = React.useState({
      pacman: { x: 1, y: 1, direction: 'right' },
      ghosts: [
          {x: 18, y: 1, color: 'red'},
          {x: 18, y: 18, color: 'pink'},
          {x: 1, y: 18, color: 'cyan'}
      ]
  });

  const cellSize = 20;
  const boardSize = 20;

  const maze = [
      [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
      [1,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,1],
      [1,0,1,1,0,1,1,1,0,1,1,0,1,1,1,0,1,1,0,1],
      [1,0,1,1,0,1,1,1,0,1,1,0,1,1,1,0,1,1,0,1],
      [1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1],
      [1,0,1,1,0,1,0,1,1,1,1,1,1,0,1,0,1,1,0,1],
      [1,0,0,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,1],
      [1,1,1,1,0,1,1,1,0,1,1,0,1,1,1,0,1,1,1,1],
      [1,1,1,1,0,1,0,0,0,0,0,0,0,0,1,0,1,1,1,1],
      [1,1,1,1,0,1,0,1,1,0,0,1,1,0,1,0,1,1,1,1],
      [0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0],
      [1,1,1,1,0,1,0,1,1,1,1,1,1,0,1,0,1,1,1,1],
      [1,1,1,1,0,1,0,0,0,0,0,0,0,0,1,0,1,1,1,1],
      [1,1,1,1,0,1,0,1,1,1,1,1,1,0,1,0,1,1,1,1],
      [1,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,1],
      [1,0,1,1,0,1,1,1,0,1,1,0,1,1,1,0,1,1,0,1],
      [1,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,1],
      [1,1,0,1,0,1,0,1,1,1,1,1,1,0,1,0,1,0,1,1],
      [1,0,0,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,1],
      [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
  ];

  React.useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      function drawMaze() {
          for (let y = 0; y < boardSize; y++) {
              for (let x = 0; x < boardSize; x++) {
                  if (maze[y][x] === 1) {
                      ctx.fillStyle = 'blue';
                      ctx.fillRect(x * cellSize, y * cellSize, cellSize, cellSize);
                  } else {
                      ctx.fillStyle = 'black';
                      ctx.fillRect(x * cellSize, y * cellSize, cellSize, cellSize);
                      ctx.fillStyle = 'white';
                      ctx.beginPath();
                      ctx.arc(x * cellSize + cellSize / 2, y * cellSize + cellSize / 2, 2, 0, Math.PI * 2);
                      ctx.fill();
                  }
              }
          }
      }

      function drawPacman() {
          ctx.fillStyle = 'yellow';
          ctx.beginPath();
          const x = gameState.pacman.x * cellSize + cellSize / 2;
          const y = gameState.pacman.y * cellSize + cellSize / 2;
          const radius = cellSize / 2;
          const startAngle = 0.2 * Math.PI;
          const endAngle = 1.8 * Math.PI;
          ctx.arc(x, y, radius, startAngle, endAngle);
          ctx.lineTo(x, y);
          ctx.fill();
      }

      function drawGhosts() {
          gameState.ghosts.forEach(ghost => {
              ctx.fillStyle = ghost.color;
              ctx.beginPath();
              const x = ghost.x * cellSize + cellSize / 2;
              const y = ghost.y * cellSize + cellSize / 2;
              ctx.arc(x, y, cellSize / 2, 0, Math.PI * 2);
              ctx.fill();
          });
      }

      function update() {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          drawMaze();
          drawPacman();
          drawGhosts();
      }

      function moveGhosts() {
          const newGhosts = gameState.ghosts.map(ghost => {
              const directions = [
                  {dx: -1, dy: 0},
                  {dx: 1, dy: 0},
                  {dx: 0, dy: -1},
                  {dx: 0, dy: 1}
              ];
              const validMoves = directions.filter(dir => {
                  const newX = ghost.x + dir.dx;
                  const newY = ghost.y + dir.dy;
                  return newX >= 0 && newX < boardSize && newY >= 0 && newY < boardSize && maze[newY][newX] !== 1;
              });
              if (validMoves.length > 0) {
                  const move = validMoves[Math.floor(Math.random() * validMoves.length)];
                  return { ...ghost, x: ghost.x + move.dx, y: ghost.y + move.dy };
              }
              return ghost;
          });
          setGameState(prevState => ({ ...prevState, ghosts: newGhosts }));
      }

      function checkCollision() {
          gameState.ghosts.forEach(ghost => {
              if (ghost.x === gameState.pacman.x && ghost.y === gameState.pacman.y) {
                  alert('Game Over! Pac-Man was caught by a ghost.');
                  resetGame();
              }
          });
      }

      function gameLoop() {
          moveGhosts();
          update();
          checkCollision();
          requestAnimationFrame(gameLoop);
      }

      gameLoop();

      function handleKeyDown(event) {
          let newX = gameState.pacman.x;
          let newY = gameState.pacman.y;

          switch (event.key) {
              case 'ArrowUp':
                  newY--;
                  break;
              case 'ArrowDown':
                  newY++;
                  break;
              case 'ArrowLeft':
                  newX--;
                  break;
              case 'ArrowRight':
                  newX++;
                  break;
          }

          if (newX >= 0 && newX < boardSize && newY >= 0 && newY < boardSize && maze[newY][newX] !== 1) {
              setGameState(prevState => ({
                  ...prevState,
                  pacman: { ...prevState.pacman, x: newX, y: newY }
              }));
          }
      }

      window.addEventListener('keydown', handleKeyDown);

      return () => {
          window.removeEventListener('keydown', handleKeyDown);
      };
  }, [gameState]);

  function resetGame() {
      setGameState({
          pacman: { x: 1, y: 1, direction: 'right' },
          ghosts: [
              {x: 18, y: 1, color: 'red'},
              {x: 18, y: 18, color: 'pink'},
              {x: 1, y: 18, color: 'cyan'}
          ]
      });
  }

  return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#000' }}>
          <canvas ref={canvasRef} width={400} height={400} style={{ border: '2px solid blue' }} />
      </div>
  );
};

export default Game;
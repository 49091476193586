import React, { useContext, useState, useEffect } from "react";
import { Input, Button, Spin, notification, Modal, Form, Select, Popover, Tooltip } from "antd";
import { TbDeviceGamepad2, TbMessageCircle } from "react-icons/tb";
import { FaPlus, FaArrowRight, FaDatabase, FaBolt, FaTimes, FaCheckCircle, FaTrash, FaShareAlt, FaPaperPlane } from "react-icons/fa";
import axios from "axios";
import SearchContext from "../../../Context/SearchContext";
import Header from "../../../components/Header";
import GameTab from "../components/flow/gameComponents/GameTab";
import AssetsGame from "../components/flow/AssetsGame";
import { LoadingOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

const { Search } = Input;
const { Option } = Select;
const customSpinnerStyle = {
  fontSize: 24,
  color: '#ffbe5a',
  border: '2px solid currentColor',
  borderRadius: '50%',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  textAlign: 'center',
  lineHeight: '1em'
};

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#ffbe5a' }} spin />;
const CustomOutlinedSpinner = () => <div style={customSpinnerStyle}><Spin indicator={antIcon} /></div>;

export default function Engine() {
  const {
    images,
    setImages,
    theme,
    setassist,
    assist,
    right,
    setRight,
    generate,
    user,
    type,
    setType,
    setGenerate,
    setPlusFeed,
    plusFeed,
  } = useContext(SearchContext);

  const [activeTab, setActiveTab] = useState("1");
  const [leftSearchQuery, setLeftSearchQuery] = useState("");
  const [rightSearchQuery, setRightSearchQuery] = useState("");
  const [gameData, setGameData] = useState(null);
  const [refreshGameTab, setRefreshGameTab] = useState(false);
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [selectedTaskDetails, setSelectedTaskDetails] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [activeRightTab, setActiveRightTab] = useState("game");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [isTaskInProgress, setIsTaskInProgress] = useState(false);
  const [isBuildingNewGame, setIsBuildingNewGame] = useState(false);
  const [taskMessage, setTaskMessage] = useState(""); // State for task message
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (assist.selectedGame?.title) {
      setTabs([{ id: "game", title: assist.selectedGame.title }]);
    }
  }, [assist.selectedGame]);

  useEffect(() => {
    if (activeTab === "1") {
      setRefreshGameTab((prev) => !prev);
    }
  }, [activeTab]);

  useEffect(() => {
    fetchVersions();
    const interval = setInterval(fetchNewTasks, 10000);
    return () => clearInterval(interval);
  }, [assist.selectedGame]);

  useEffect(() => {
    if (activeTab === "4") {
      fetchTasks();
    }
  }, [activeTab]);

  const fetchVersions = async () => {
    if (assist.selectedGame?.game_id) {
      try {
        const response = await axios.get(`https://embed.axv.ai/api/vector?data_id=${assist.selectedGame.game_id}`);
        const sortedVersions = response.data.vectorList.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setVersions(sortedVersions);
        setSelectedVersion(sortedVersions[0] || null);
        setIsBuildingNewGame(false);
      } catch (error) {
        console.error("Error fetching versions:", error);
        setIsBuildingNewGame(false);
      }
    }
  };

  const handleSearch = async () => {
    const baseUrl = "https://embed.axv.ai/api/create/image/fx";
    const postData = {
      query: leftSearchQuery,
      user_id: user.profile_id,
    };

    setLoadingSearch(true);
    try {
      const response = await axios.post(baseUrl, postData);
      if (response.data.isSuccess) {
        notification.success({ message: "Task Received" });
        setLeftSearchQuery("");
      } else {
        notification.error({ message: "Task Failed. Try Again." });
      }
    } catch (error) {
      console.error("Error during task creation:", error);
      notification.error({ message: "An error occurred. Please try again." });
    } finally {
      setLoadingSearch(false);
    }
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleGameSearch = async () => {
    const baseUrl = "https://embed.axv.ai/api/image/game";
    const postData = {
      query: rightSearchQuery,
      ...(selectedVersion ? { version_id: selectedVersion._id } : { new_id: assist.selectedGame.game_id }),
    };

    setLoadingSearch(true);
    setIsTaskInProgress(true);

    if (!selectedVersion) {
      setIsBuildingNewGame(true);
    }

    try {
      const response = await axios.post(baseUrl, postData);
      if (response.data.isSuccess) {
        notification.success({ message: "Task Received" });
        setTaskMessage(response.data.data.message || ""); // Store the message
        setRightSearchQuery("");
        startPollingTask(response.data.data.task_id);
      } else {
        notification.error({ message: "Task Failed. Try Again." });
        setIsTaskInProgress(false);
      }
    } catch (error) {
      console.error("Error fetching game data:", error);
      notification.error({ message: "An error occurred. Please try again." });
      setIsTaskInProgress(false);
      if (!selectedVersion) {
        setIsBuildingNewGame(false);
      }
    } finally {
      setLoadingSearch(false);
    }
  };

  const startPollingTask = (taskId) => {
    const pollTaskDetails = async () => {
      try {
        const taskDetails = await fetchTaskDetails(taskId);

        if (taskDetails && taskDetails.status === "complete") {
          updateTaskTabToComplete(taskId);
          fetchVersions();
          setIsTaskInProgress(false);
          clearInterval(pollingInterval);
        }
      } catch (error) {
        console.error("Error polling task details:", error);
      }
    };

    const pollingInterval = setInterval(pollTaskDetails, 3000);
  };

  const fetchTasks = async () => {
    const baseUrl = "https://embed.axv.ai/api/task";
    try {
      const response = await axios.get(`${baseUrl}?user_id=${user.profile_id}`);
      if (response.data.isSuccess) {
        setTasks(response.data.taskList);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const fetchNewTasks = async () => {
    const baseUrl = "https://embed.axv.ai/api/task";
    const params = {
      user_id: user.profile_id,
      view: "new",
    };

    try {
      const response = await axios.get(baseUrl, { params });
      if (response.data.isSuccess && response.data.taskList.length > 0) {
        const newTasks = response.data.taskList;
        newTasks.forEach((task) => {
          if (task.task_type === "create") {
            handleTaskDetailsOpen(task._id);
            updateTaskView(task._id, "delivered");
          }
        });
      }
    } catch (error) {
      console.error("Error fetching new tasks:", error);
    }
  };

  const fetchTaskDetails = async (taskId) => {
    try {
      const response = await axios.get(`https://embed.axv.ai/api/task/${taskId}`);
      return response.data.task;
    } catch (error) {
      console.error("Error fetching task details:", error);
      return null;
    }
  };

  const updateTaskTabToComplete = (taskId) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) => {
        if (tab.id === `task-${taskId}`) {
          return { ...tab, title: `✓ Task ${taskId}` };
        }
        return tab;
      })
    );

    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task._id === taskId ? { ...task, status: "complete" } : task
      )
    );
  };

  const updateTaskView = async (taskId, newView) => {
    try {
      await axios.put(`https://embed.axv.ai/api/task/${taskId}`, { view: "delivered" });
    } catch (error) {
      console.error("Error updating task view:", error);
    }
  };

  const handleVersionSelect = (version) => {
    setSelectedVersion(version);
    setIsDropdownOpen(false);
  };

  const handleTaskDetailsOpen = async (taskId) => {
    try {
      const taskDetails = await fetchTaskDetails(taskId);
      const goals = taskDetails?.goals || [];
      if (taskDetails) {
        setSelectedTaskDetails((prev) => ({
          ...prev,
          [taskId]: goals,
        }));
        if (!tabs.some((tab) => tab.id === `task-${taskId}`)) {
          setTabs((prev) => [
            ...prev,
            {
              id: `task-${taskId}`,
              title: taskDetails.status === "complete" ? `✓ Task ${taskId}` : `Task ${taskId}`,
            },
          ]);
        }
      }
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };

  const handleDeleteVersion = async (versionId, closePopover) => {
    try {
      const response = await axios.delete(`https://embed.axv.ai/api/vector/${versionId}`);
      if (response.data.isSuccess) {
        notification.success({ message: "Version deleted successfully" });
        fetchVersions();
        if (closePopover) closePopover();
      }
    } catch (error) {
      console.error("Error deleting version:", error);
      notification.error({ message: "Failed to delete version" });
    }
  };

  const handlePublishVersion = async (versionId) => {
    try {
      const response = await axios.put(`https://embed.axv.ai/api/vector/${versionId}`, {
        status: "active",
      });
      if (response.data.isSuccess) {
        notification.success({ message: "Version published successfully" });
        fetchVersions();
      }
    } catch (error) {
      console.error("Error publishing version:", error);
      notification.error({ message: "Failed to publish version" });
    }
  };

  const renderTaskStatusIndicator = (task) => {
    if (task.status === "progress") {
      return <Spin size="small" style={{ marginRight: "8px" }} />;
    }
    if (task.status === "complete") {
      return (
        <span style={{ backgroundColor: "orange", borderRadius: "50%", padding: "4px" }}>
          <FaCheckCircle color="black" />
        </span>
      );
    }
    return null;
  };

  const renderTasks = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "16px" }}>
        {tasks.map((task, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "16px",
              borderRadius: "8px",
              width: "100%",
              height: "120px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {renderTaskStatusIndicator(task)}
                <h3 style={{ textAlign: "left", margin: 0, fontSize: "1rem" }}>
                  {task.query.length > 50 ? `${task.query.slice(0, 50)}...` : task.query}
                </h3>
              </div>
              <FaArrowRight
                size={20}
                color="white"
                style={{ cursor: "pointer" }}
                onClick={() => handleTaskDetailsOpen(task._id)}
              />
            </div>
            <p style={{ margin: "8px 0", fontSize: "0.9rem" }}>Type: {task.task_type}</p>
            <p
              style={{
                position: "absolute",
                bottom: "8px",
                right: "8px",
                fontSize: "0.8rem",
                color: "#bbb",
                margin: 0,
              }}
            >
              {new Date(task.createdAt).toLocaleDateString()}
            </p>
          </div>
        ))}
      </div>
    );
  };

  const renderTaskDetails = (taskId) => {
    const taskDetails = selectedTaskDetails[taskId] || [];

    return (
      <div style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "16px" }}>
        {taskDetails.length === 0 ? (
          <p>No details available.</p>
        ) : (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
            {taskDetails.map((detail, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: "#444",
                  color: "white",
                  padding: "16px",
                  borderRadius: "8px",
                  flex: "1 1 calc(33.333% - 16px)",
                  maxWidth: "calc(33.333% - 16px)",
                }}
              >
                <h4 style={{ fontSize: "1.1rem", margin: "0 0 8px 0" }}>{detail.name}</h4>
                <img
                  src={detail.file_url}
                  alt={detail.name}
                  style={{ width: "100%", borderRadius: "8px", marginBottom: "8px" }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const handleTabClick = (tabId) => {
    setActiveRightTab(tabId);
  };

  const handleCloseTab = (tabId, event) => {
    event.stopPropagation();
    if (tabId !== "game") {
      setTabs(tabs.filter((tab) => tab.id !== tabId));
      if (activeRightTab === tabId) {
        setActiveRightTab("game");
      }
    }
  };

  const renderGame = () => {
    if (isBuildingNewGame) {
      return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <div id="search-container" style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
          <div id="main" style={{ display: "flex", flex: "1 1 auto", alignItems: "center" }}>
            <Input
              className="input"
              type="text"
              value={rightSearchQuery}
              disabled={true} // Disable input when building
              style={{ width: "300px", height: "40px" }}
            />
            </div>
          </div>
          
<div className="spinnerNEW">
 
  <div className="spinner1NEW"></div>
</div>

          {taskMessage && (
            <p style={{ color: "#fff", marginTop: "20px", textAlign: "center", maxWidth: "400px" }}> Hey Whats up {taskMessage}</p>
          )}
        </div>
      );
    }

    const iframeSource = selectedVersion
      ? `https://www.u.computer/?_id=${selectedVersion._id}`
      : `https://www.u.computer/?data_id=${assist.selectedGame?.game_id || "48"}`;

      if (versions.length === 0) {
        return (
          <div style={{ textAlign: "center", color: "white", padding: "20px", height: "100%", overflow: "auto" }}>
            {!isBuildingNewGame && (
              <div style={{ marginBottom: "40px" }}>
                <Input
                  className="input"
                  type="text"
                  placeholder="Build Game"
                  value={rightSearchQuery}
                  onChange={(e) => setRightSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") handleGameSearch();
                  }}
                  style={{ width: "300px", height: "40px" }}
                />
              </div>
            )}
            <div className="cardintro" style={{ marginTop: "20px", maxWidth: "400px", margin: "0 auto" }}>
              <div className="card__border"></div>
              <div className="card_title__container">
                <span className="card_title">Keys to Success</span>
                <p className="card_paragraph">Best way to be success in your life.</p>
              </div>
              <hr className="line" />
              <ul className="card__list">
                <li className="card__list_item">
                  <span className="check">
                    <svg
                      className="check_svg"
                      fill="#ffbe5a"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clipRule="evenodd"
                        d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <span className="list_text">Set Clear Goals</span>
                </li>
                <li className="card__list_item">
                  <span className="check">
                    <svg
                      className="check_svg"
                      fill="#ffbe5a"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clipRule="evenodd"
                        d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <span className="list_text">Stay Organized</span>
                </li>
                <li className="card__list_item">
                  <span className="check">
                    <svg
                      className="check_svg"
                      fill="#ffbe5a"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clipRule="evenodd"
                        d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <span className="list_text">Continuous Learning</span>
                </li>
                <li className="card__list_item">
                  <span className="check">
                    <svg
                      className="check_svg"
                      fill="#ffbe5a"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clipRule="evenodd"
                        d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <span className="list_text">Time Management</span>
                </li>
                <li className="card__list_item">
                  <span className="check">
                    <svg
                      className="check_svg"
                      fill="#ffbe5a"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clipRule="evenodd"
                        d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <span className="list_text">Maintain a Positive Attitude</span>
                </li>
              </ul>
              <button className="button1">Get Your Success</button>
            </div>
          </div>
        );
      }
  

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "4px" }}>
          <div className="paste-icons" style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
            <Tooltip title="Delete Version">
            <Popover
      content={
        <div>
          Are you sure? <Button onClick={() => { handleDeleteVersion(selectedVersion._id); setIsPopoverVisible(false); }}>Yes</Button>
          <Button onClick={() => setIsPopoverVisible(false)} style={{ marginLeft: '10px' }}>X</Button>
        </div>
      }
      trigger="click"
      visible={isPopoverVisible}
      onVisibleChange={setIsPopoverVisible}
    >
      <FaTrash style={{ cursor: "pointer", color: "#ffbe5a", marginRight: "10px" }} size={20} />
    </Popover>
            </Tooltip>
            <Tooltip title="Share Version">
              <FaShareAlt style={{ cursor: "pointer", color: "#ffbe5a", marginRight: "10px" }} size={20} onClick={() => window.open(`https://www.u.computer/?_id=${selectedVersion?._id}`, "_blank")} />
            </Tooltip>
            <Tooltip title="Publish Version">
              <FaPaperPlane style={{ cursor: "pointer", color: "#ffbe5a" }} size={20} onClick={() => handlePublishVersion(selectedVersion._id)} />
            </Tooltip>
          </div>

          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
            <Input
              className="input"
              type="text"
              placeholder="Refine Game"
              value={rightSearchQuery}
              onChange={(e) => setRightSearchQuery(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") handleGameSearch();
              }}
              style={{ flexGrow: 1, marginBottom: "8px", width: "300px", height: "40px" }}
            />
            {loadingSearch && <Spin size="small" style={{ marginLeft: "8px" }} />}
          </div>
          <div className="paste-button" onMouseLeave={() => setIsDropdownOpen(false)}>
            <button
              className="button0"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "150px",
              }}
            >
              {selectedVersion
                ? `Version ${versions.length - versions.findIndex((v) => v._id === selectedVersion._id)}`
                : "Select Version"}{" "}
              &nbsp; ▼
            </button>
            {isDropdownOpen && (
              <div className="dropdown-content">
                {versions.map((version, index) => (
                  <a
                    key={version._id}
                    id={index === 0 ? "top" : index === versions.length - 1 ? "bottom" : "middle"}
                    href="#"
                    onClick={() => handleVersionSelect(version)}
                  >
                    Version {versions.length - index}
                    {version.status === "active" && (
                      <span style={{ color: "#00FF00", marginLeft: "5px" }}>(Active)</span>
                    )}
                  </a>
                ))}
              </div>
            )}
          </div>

          {isTaskInProgress ? (
            <div className="spinnert" style={{ marginLeft: "10px" }}>
              <div className="spinner1" />
            </div>
          ) : (
            <div style={{ marginLeft: "10px", color: "#FFA500", fontSize: "24px" }}>
              ✓
            </div>
          )}
        </div>
        <iframe
          src={iframeSource}
          style={{ flex: 1, width: "100%", border: "none", borderRadius: "16px" }}
          title="Game"
        />
      </div>
    );
  };

  const renderRightPanel = () => {
    const tabContent = {
      game: renderGame(),
      tasks: renderTasks(),
      ...Object.keys(selectedTaskDetails).reduce(
        (acc, taskId) => ({
          ...acc,
          [`task-${taskId}`]: renderTaskDetails(taskId),
        }),
        {}
      ),
    };

    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", backgroundColor: "#000000", padding: "8px", borderBottom: "1px solid #333" }}>
          {tabs.map((tab) => (
            <div
              key={tab.id}
              onClick={() => handleTabClick(tab.id)}
              style={{
                flex: 1,
                padding: "8px",
                textAlign: "center",
                cursor: "pointer",
                borderBottom: `2px solid ${activeRightTab === tab.id ? "#FFA500" : "transparent"}`,
                color: "white",
                borderRadius: "8px",
                margin: "0 4px",
                border: `2px solid ${activeRightTab === tab.id ? "#FFA500" : "#666"}`,
              }}
            >
              {tab.title}
              {tab.id !== "game" && (
                <FaTimes
                  size={12}
                  onClick={(e) => handleCloseTab(tab.id, e)}
                  style={{ marginLeft: "8px", cursor: "pointer", color: "red" }}
                />
              )}
              {tasks.some((task) => task._id === tab.id.replace("task-", "") && task.status === "progress") && (
                <Spin size="small" style={{ marginLeft: "8px" }} />
              )}
            </div>
          ))}
        </div>
        <div style={{ flex: 1, overflowY: "auto", padding: "16px", backgroundColor: "#000000" }}>
          {tabContent[activeRightTab] || <div>Select a tab</div>}
        </div>
      </div>
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
    form.setFieldsValue({ game_id: uuidv4() });
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const response = await axios.post("https://embed.axv.ai/api/feed/core", {
        ...values,
        feed_type: "game",
        user_id: user.user_id,
      });

      if (response.data.isSuccess) {
        notification.success({ message: "Game created successfully" });
        setIsModalVisible(false);
        form.resetFields();
        setPlusFeed({ ...plusFeed, creeate: false }); // This will reset the 'create' state after successful creation

        setRefreshGameTab((prev) => !prev); // Trigger GameTab to refresh
      } else {
        notification.error({ message: "Game creation failed" });
      }
    } catch (error) {
      console.error("Error creating game:", error);
      notification.error({ message: "An error occurred. Please try again." });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", height: "100vh", backgroundColor: "#2B2B2B" }}>
      <div style={{ width: "40%", height: "100%", overflow: "hidden", backgroundColor: "#2B2B2B", color: "white", position: "relative" }}>
        <Header />
        <div style={{ padding: "16px", paddingTop: "32px", height: "calc(100% - 64px)", overflowY: "auto" }}>
          <div id="search-container" style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
            <div id="main" style={{ display: "flex", flex: "1 1 auto", alignItems: "center" }}>
              <Input
                className="input"
                type="text"
                placeholder="Move Faster"
                value={leftSearchQuery}
                onChange={(e) => setLeftSearchQuery(e.target.value)}
                onKeyPress={handleSearchKeyPress}
                style={{ flexGrow: 1, marginRight: "16px" }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              {[
                { id: "1", icon: TbDeviceGamepad2 },
                { id: "2", icon: FaDatabase },
                { id: "3", icon: TbMessageCircle },
                { id: "4", icon: FaBolt },
              ].map((tab) => (
                <div
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    padding: "4px 8px",
                    borderRadius: "10px",
                    position: "relative",
                    width: "40px",
                    height: "42px",
                    border: "1px solid #666",
                    backgroundColor: activeTab === tab.id ? "#444" : "transparent",
                  }}
                >
                  {activeTab === tab.id && (
                    <div
                      className="wormhole-border"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderRadius: "10px",
                        background: "linear-gradient(180deg, #ffbe5a, #d99341, #ffde8f)",
                        zIndex: 1,
                      }}
                    />
                  )}
                  <tab.icon
                    size={24}
                    color={activeTab === tab.id ? "#000000" : "#666"}
                    style={{ position: "relative", zIndex: 2 }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="tab-content">
            {activeTab === "1" && (
              <div>
                <Button
                  icon={<FaPlus />}
                  onClick={showModal}
                  className="circular-button"
                  style={{ marginTop: "16px" }}
                />
                <GameTab key={refreshGameTab} />
              </div>
            )}
            {activeTab === "2" && <AssetsGame active={activeTab === "2"} setActive={setActiveTab} />}
            {activeTab === "3" && <div>Chat Content</div>}
            {activeTab === "4" && renderTasks()}
          </div>
        </div>
      </div>

      <div style={{ width: "60%", height: "100%", backgroundColor: "#000000" }}>{renderRightPanel()}</div>

      {/* Modal */}
      <Modal
        title="Create Game"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="game_id"
            label="Game ID"
            rules={[{ required: true, message: 'Please enter a game ID' }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please enter a title' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter a description' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="platform"
            label="Platform"
            rules={[{ required: true, message: 'Please select a platform' }]}
          >
            <Select placeholder="Select a platform">
              <Option value="unity">Unity</Option>
              <Option value="dendric">Dendric</Option>
              <Option value="unreal_engine">Unreal Engine</Option>
              <Option value="godot">Godot</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="style"
            label="Style"
            rules={[{ required: true, message: 'Please select a style' }]}
          >
            <Select placeholder="Select a style">
              <Option value="8-Bit Pixel">8-Bit Pixel</Option>
              <Option value="16-Bit Pixel">16-Bit Pixel</Option>
              <Option value="Cartoon Art">Cartoon Art</Option>
              <Option value="3D Realistic">3D Realistic</Option>
              <Option value="3D Stylized">3D Stylized</Option>
              <Option value="Voxel Art">Voxel Art</Option>
              <Option value="Isometric Art">Isometric Art</Option>
              <Option value="Vector Art">Vector Art</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" onClick={handleOk}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
import { Modal } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../../../Context/SearchContext";
import New from "./New";
import UploadAssets from "../UploadAssets";
import Upload from "./Upload";
import Describe from "./Describe";
import Video from "./Video";
import Create from "./Create";
import EditImage from "./EditImage";
import Thanks from "./Thanks";
import Verification from "./Verification";
import WebSearch from "./WebSearch";
import Describe2 from "./Describe2";
import VisualCapital from "./VisualCapital";
import Pricing from "./Pricings";

export default function Onboard() {
  const { theme, setOrganization, organization } = useContext(SearchContext);
  return (
    <Modal
      width={800}
      className={`no-h-bo-b-modal brand-modal2 brand-modal no-close-m chat-mmmd ${theme}-theme`}
      open={organization && organization.onboard !== "done"}
      //   onCancel={() => setOrganization({ ...organization, display: false })}
    >
      {organization ? (
        <div>
          {/* <h1 onClick={() => console.log(organization)}>sss</h1> */}
          {organization.onboard === "new" ? <New /> : null}
          {organization.onboard === "verification" ? <Verification/> : null}
          {organization.onboard === "pricing" ? <Pricing /> : null}

          {organization.onboard === "thanks" ? <Thanks /> : null}
        </div>
      ) : null}
    </Modal>
  );
}

import {
  Alert,
  Button,
  Card,
  Form,
  Input,
  message,
  Spin,
  Switch,
  Tooltip,
  Modal,
} from "antd";
import { Canvas } from "@react-three/fiber";
import { useGLTF, OrbitControls } from "@react-three/drei";
import moment from "moment";
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  Suspense,
} from "react";
import {
  FaDownload,
  FaEdit,
  FaLaptop,
  FaMinus,
  FaArrowRight,
} from "react-icons/fa";
import Output from "./Output";

import { BiCheckDouble } from "react-icons/bi";
import Avatar from "react-avatar";
import { GrFormClose } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import { RiImageEditFill, RiSendPlaneFill } from "react-icons/ri";
import { SiHey } from "react-icons/si";
import {
  FaInfoCircle,
  FaExclamationTriangle,
  FaTimesCircle,
} from "react-icons/fa";
import ReactPlayer from "react-player";
import {
  MdOutlineCreate,
  MdOutlineImageSearch,
  MdOutlineTravelExplor,
  MdInsights,
  MdClose,
} from "react-icons/md";
import SearchContext from "../../../Context/SearchContext";
import UpgradeModal from "../../home/components/UpgradeModal";
import {
  BsFillDatabaseFill,
  BsGlobe,
  BsMagic,
  BsCollectionPlayFill,
} from "react-icons/bs";
import { motion } from "framer-motion";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import ReactAudioPlayer from "react-audio-player";
import {
  FileImageOutlined,
  SoundOutlined,
  VideoCameraOutlined,
  CodeOutlined,
  CopyOutlined,
  BoxPlotOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import { saveAs } from "file-saver";
import JSZip from "jszip";

function Model({ url }) {
  const { scene } = useGLTF(url);
  return <primitive object={scene} />;
}

export default function Chat() {
  const {
    assist,
    organization,
    setassist,
    chatId,
    user,
    load,
    setLoad,
    callApi,
    setCallApi,
    language,
    setFormType,
    setPlusFeed,
    plusFeed,
    right,
    left,
    setLeft,
    pActive,
    loginChange,
    setDisplay,
  } = useContext(SearchContext);

  const [open, setOpen] = useState(false);
  const [d, setD] = useState(false);
  const [loading, setLoading] = useState(false);
  const [callLates, setCallLatest] = useState(1);
  const [loading2, setLoading2] = useState(true);
  const [callS, setCallScrool] = useState(1);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({ type: "", assets: [] });
  const [currentModelUrl, setCurrentModelUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const downloadSingle = (url, filename) => {
    saveAs(url, filename);
  };

  const downloadAll = async (assets, type) => {
    const zip = new JSZip();
    const promises = assets.map((asset, index) =>
      fetch(asset)
        .then((res) => res.blob())
        .then((blob) => {
          zip.file(
            `${type}_${index}.${
              type === "image" ? "jpg" : type === "sound" ? "mp3" : "mp4"
            }`,
            blob
          );
        })
    );

    await Promise.all(promises);
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `all_${type}s.zip`);
  };

  const getFileExtension = (type) => {
    switch (type) {
      case "image":
        return "jpg";
      case "sound":
        return "mp3";
      case "video":
        return "mp4";
      default:
        return "";
    }
  };

  const showModal = (type, assets) => {
    if (type === "3d") {
      const modelUrl = assets[0];
      console.log("Model URL:", modelUrl); // Log the model URL
      setCurrentModelUrl(modelUrl);
      setModalContent({ type: "3d", assets: [modelUrl] });
    } else {
      setModalContent({ type, assets });
    }
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    if (currentModelUrl) {
      console.log("Current Model URL:", currentModelUrl);
    }
  }, [currentModelUrl]);

  useEffect(() => {
    if (
      (assist.pageNo === 1 && assist.data) ||
      (user && user.name === undefined)
    ) {
      setLoading2(false);
      if (callLates === 1) {
        setCallLatest(callLates + 1);
      }
    } else {
      getVideoHigh();
    }
  }, [callApi, chatId, loginChange]);

  useEffect(() => {
    if (open || load) {
      scrolBottom();
    }
  }, [open, load]);

  useEffect(() => {
    if (assist.data) {
      scrolBottom();
    }
  }, [callS]);

  const [comentNew, setComentNew] = useState("");

  const handleChange = (e) => {
    setComentNew(e.target.value);
  };
  useEffect(() => {
    if (assist.data) {
      getLatestMsg();
    }
  }, [callLates]);
  const getLatestMsg = (h) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/chat_log?page=1&limit=1&user_id=${
        user ? user.profile_id : "map"
      }&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Server error");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.isSuccess) {
          let oldData = assist.data ? assist.data : [];
          let oneMsg = data.chatlogList?.length
            ? data.chatlogList[0]
            : undefined;
          if (oneMsg) {
            let index = oldData.findIndex((item) => item._id === oneMsg._id);
            if (index !== -1) {
              // Replace the object
              oldData[index] = oneMsg;
            } else {
              // Add oneMsg to oldData
              oldData.push(oneMsg);
            }
          }

          setTimeout(() => setCallLatest(callLates + 1), 1400);
        }
      })
      .catch((error) => {
        if (error.message === "Server error") {
          setErrorMessage("System overloaded. Please Refresh");
        }
      });
  };
  const getVideoHigh = (h) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/chat_log?page=${
        assist.pageNo
      }&limit=15&user_id=${user ? user.profile_id : "map"}&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setLoading2(false);
        if (data && data.isSuccess) {
          setassist({
            ...assist,
            data: data.chatlogList
              .reverse()
              .concat(assist.data ? assist.data : []),
            totalPages: data.totalPages,
          });
          if (callApi === 1) {
            setTimeout(() => scrolBottom(), 1000);
          }
          if (callLates === 1) {
            setCallLatest(callLates + 1);
          }
        }
      });
  };
  const findTimeStamp = (q) => {
    let a = q;
    const i = a.indexOf(":");
    if (i !== -1 && a.slice(i + 3, i + 4) === ":") {
      return a.slice(i - 2, i + 6);
    }
  };

  const scrolBottom = () => {
    const element = document.getElementsByClassName("active-tab-com-dfc22");
    if (element && element.length) {
      setTimeout(() => {
        element[0].scroll({
          top: element[0].scrollHeight,
          behavior: "smooth",
        });
      }, 300);
    }
  };
  const loadMore = (v) => {
    // console.log(v.target.scrollHeight, v.target.scrollTop);
    if (
      v.target.scrollTop === 0 &&
      assist.totalPages > assist.pageNo &&
      !loading
    ) {
      setassist({
        ...assist,
        pageNo: assist.pageNo + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading2(true);
      setCallApi(callApi + 1);
    }
  };

  const handleSubmit = (p) => {
    // if (comentNew === "") {
    //   return;
    // }
    const c = p ? p : comentNew;

    const txt = c.trim().length ? c.trim() : assist.upImage ? "Image Sent" : "";
    setLoad(txt);
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const obj = {
      query: txt,
      user_id: user ? user.profile_id : "map",
      web_search: right.isWeb,
      storage: right.isStorage,
      // assistant_id: "645f00b8744d5ff6bc8571e8",
      action_id: pActive ? pActive._id : "645f00b8744d5ff6bc8571e8",
      language,
      edit: assist.editImg,
      video_id: assist.selAssetID2 ? assist.selAssetID2._id : undefined,
      audio_id: assist.selAssetID3 ? assist.selAssetID3._id : undefined,
      startTime: assist.selAssetID2 ? findTimeStamp(txt) : undefined,
      game_id: assist.selectedGame?.game_id,
      image_id: assist.upImage
        ? assist.upImage[0].purpose === "misc"
          ? assist.upImage[0]._id
          : assist.upImage[0].headclip_id
        : assist.selAssetID
        ? assist.selAssetID._id
        : undefined,
      media: assist.selAssetID3
        ? true
        : assist.selAssetID2
        ? true
        : assist.upImage
        ? true
        : assist.selAssetID
        ? true
        : assist.chatMedia
        ? true
        : right.activeRight === "Search"
        ? true
        : right.activeRight === "Analytics"
        ? true
        : undefined,
      insights: right.activeRight === "Analytics" ? true : undefined,
    };
    setComentNew("");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/image/fx`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoad();
        if (data.message === "Upgrade Account") {
          setDisplay("Pricing");
        }
        if (data && data.isSuccess) {
          let neweData = assist.data ? assist.data : [];

          setassist({
            ...assist,
            idClip: chatId ? data.data : undefined,
            data: [
              ...neweData,
              { ...data.data, _id: data.data?.chat_id, status: "progress" },
            ],
            animation: true,
            searchImages:
              d.clip_data && d.clip_data.length
                ? d.clip_data
                : assist.searchImages,
            editImg: undefined,
          });

          setLeft({
            ...left,
            flow:
              data.flow === "general" ||
              data.flow === "create_image" ||
              data.flow === "newImages" ||
              data.flow === "create_video"
                ? left.flow
                : data.flow,
            chatData: d,
          });

          setTimeout(() => {
            setCallScrool(callS + 1);
          }, 500);
        } else if (message === "Upgrade Account") {
          setDisplay("Pricing");
        } else {
          if (
            data.message ===
            "Chat limit has been reached, please create an accountt"
          ) {
            // setFormType("signup");
          } else if (
            data.message === "Please upgrade account. Query limit reached"
          ) {
            if (user && user.name === undefined) {
              setFormType("signup");
            }
            setassist({
              ...assist,
              isUpgrade: true,
            });
          }
        }
      });
  };
  const updateCode = (obj, row) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/vector/game/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Updated");
        } else {
          message.error("something went wrong");
        }
      });
  };

  return (
    <div className={` chat-bx-mn  `}>
      <UpgradeModal />

      {errorMessage && (
        <div className="error-message-container">
          <span className="error-message">{errorMessage}</span>
          <Button onClick={() => window.location.reload()} type="primary">
            Refresh
          </Button>
        </div>
      )}

      <div
        onScroll={(v) => {
          loadMore(v);
        }}
        className="active-tab-com-dfc22"
      >
        {loading2 ? (
          <div className="load-chat-ccs">
            <Spin />
          </div>
        ) : null}

        {(assist.data && assist.data.length === 0 && load === undefined) ||
        (!loading2 && assist.data === undefined && load === undefined) ? (
          <div className="no-heigh-div-c full-h-bx-a">
            <div className="row">
              <div className="col-sm-6">
                <Alert
                  message={
                    <span style={{ fontWeight: "bold" }}>
                      Welcome To Dendric
                    </span>
                  }
                  description={
                    <div style={{ fontFamily: "Dancing Script" }}>
                      <div>An intelligent game designer built for you</div>
                    </div>
                  }
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-a mb-3"
                  icon={<SiHey />}
                />

                <Alert
                  message={
                    <span style={{ fontWeight: "bold" }}> Brainstorm</span>
                  }
                  description="Generate innovative game concepts and mechanics from scratch"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-b mb-3"
                  icon={<MdOutlineImageSearch />}
                />

                <Alert
                  message={
                    <span style={{ fontWeight: "bold" }}>Visualize</span>
                  }
                  description="Create concept art and visual prototypes."
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-c mb-3"
                  icon={<MdOutlineCreate />}
                />
              </div>

              <div className="col-sm-6">
                <Alert
                  message={
                    <span style={{ fontWeight: "bold" }}>Soundscape</span>
                  }
                  description="Design audio elements and music tracks"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-c mb-3"
                  icon={<BsMagic />}
                />

                <Alert
                  message={<span style={{ fontWeight: "bold" }}>Script</span>}
                  description="Develop storylines and character dialogues"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-d mb-3"
                  icon={<MdInsights />}
                />

                <Alert
                  message={
                    <span style={{ fontWeight: "bold" }}> Optimize </span>
                  }
                  description="Send gameplay data for your game"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-d mb-3"
                  icon={<BsCollectionPlayFill />}
                />
              </div>
            </div>
          </div>
        ) : null}
        {assist.data &&
          assist.data.map((cm, cmi) => {
            return (
              <div
                style={{
                  transition: "0.4s",
                }}
                className="assit-dat-cs"
                key={cmi}
              >
                <div
                  className="chat-out-p1"
                  style={{ fontFamily: "Inter", fontSize: 17 }} //this controls font size for users message
                >
                  <div className="user-chat-text">
                    <span>{cm.query}</span>

                    <div className="dbl-tci-dvc">
                      <BiCheckDouble className="double-tick-brand" />
                    </div>
                  </div>
                </div>

                <div className="chat-out-p12">
                  <div
                    className={` ${assist.activeClips ? " clips-m-size" : ""}`}
                    style={{
                      marginBottom:
                        cmi + 1 === assist.data.length ? "0px" : "10px",
                    }}
                    onClick={() => setD(cm._id)}
                  >
                    <div
                      className="flex-bxos"
                      style={{ fontFamily: "Inter", fontSize: 17 }} //This controls sizing for AI message
                    >
                      {" "}
                      <div
                        onClick={() => console.log(cm)}
                        className="ai-chat-txt-c "
                      >
                        <div className="theme-main-divs no-no">
                          <div>
                            <b>
                              {cm.flow ? (
                                cm.flow === "generate" ? (
                                  <MdOutlineCreate
                                    onClick={() => {
                                      setLeft({
                                        ...left,
                                        flow: cm.flow,
                                        chatData: cm,
                                      });
                                    }}
                                  />
                                ) : cm.flow === "search" ? (
                                  <HiOutlineDocumentSearch
                                    onClick={() => {
                                      setLeft({
                                        ...left,
                                        flow: cm.flow,
                                        chatData: cm,
                                      });
                                    }}
                                  />
                                ) : cm.flow === "web_search" ? (
                                  <BsGlobe
                                    onClick={() => {
                                      setLeft({
                                        ...left,
                                        flow: cm.flow,
                                        chatData: cm,
                                      });
                                    }}
                                  />
                                ) : (
                                  <span />
                                )
                              ) : (
                                <span />
                              )}
                            </b>
                          </div>
                        </div>
                        <span>
                          <Output
                            t={assist.data.length}
                            l={cmi}
                            data={cm.output}
                            row={cm}
                          />
                        </span>{" "}
                      </div>
                    </div>

                    {cm.status === "progress" ? (
                      <div className="chat-resp-c1">
                        {!cm.response ? (
                          <p
                            style={{
                              flexDirection: "row",
                              width: "auto",
                              height: "auto",
                            }}
                            className=""
                          >
                            <span>
                              <strong>Thinking</strong>
                              <span className="ellipsis"></span>
                            </span>
                          </p>
                        ) : (
                          <p>
                            {cm.response}
                            <span className="ellipsis"></span>
                          </p>
                        )}
                        {cm.image && cm.image.length ? (
                          <div className="img-up-qur">
                            <FileImageOutlined className="media-icon" />
                            <div className="asset-container">
                              <div className="asset-item full-size">
                                <img src={cm.image[0]} alt="" />
                              </div>
                              {cm.image.length > 1 && (
                                <div
                                  className="more-assets"
                                  onClick={() => showModal("image", cm.image)}
                                >
                                  <span>+{cm.image.length - 1}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}

                        {cm.sound && cm.sound.length ? (
                          <div className="img-up-qur">
                            <SoundOutlined className="media-icon" />
                            <div className="asset-container">
                              <div className="asset-item full-size">
                                <ReactAudioPlayer src={cm.sound[0]} controls />
                              </div>
                              {cm.sound.length > 1 && (
                                <div
                                  className="more-assets"
                                  onClick={() => showModal("sound", cm.sound)}
                                >
                                  <span>+{cm.sound.length - 1}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}

                        {cm?.third_model && cm.third_model.length ? (
                          <div className="img-up-qur">
                            <BoxPlotOutlined className="media-icon" />
                            <div>
                              {cm.third_model.map((modelUrl, index) => (
                                <button
                                  className="custom-button"
                                  key={index}
                                  onClick={() => showModal("3d", [modelUrl])}
                                  style={{ margin: "0 5px 5px 0" }}
                                >
                                  View 3D Model {index + 1}
                                </button>
                              ))}
                            </div>
                          </div>
                        ) : null}

                        {cm.video && cm.video.length ? (
                          <div className="vid-up-qur">
                            <VideoCameraOutlined className="media-icon" />
                            <div className="vd-nm-pr">
                              <ReactPlayer
                                url={cm.video[0]}
                                controls
                                width="100%"
                                height="100%"
                              />
                            </div>
                            {cm.video.length > 1 && (
                              <div
                                className="more-assets"
                                onClick={() => showModal("video", cm.video)}
                              >
                                <span>+{cm.video.length - 1}</span>
                              </div>
                            )}
                          </div>
                        ) : null}
                        {cm.code && cm.code.length ? (
                          <div className="img-up-qur">
                            <CodeOutlined className="media-icon" />
                            {cm.code.map((code, imi) => {
                              return (
                                <pre key={imi}>
                                  <code>{code}</code>
                                </pre>
                              );
                            })}
                            <br />
                          </div>
                        ) : null}
                      </div>
                    ) : cm.response || cm.is_approval || cm.is_approved ? (
                      <div className="chat-resp-c1">
                        <div
                          className=""
                          style={{ whiteSpace: "pre-wrap", fontSize: "14px" }}
                        >
                          <p>
                            {cm.response
                              .split(/(\*\*.*?\*\*|\*(?!\*))/g)
                              .map((segment, index) => {
                                if (
                                  segment.startsWith("**") &&
                                  segment.endsWith("**")
                                ) {
                                  return (
                                    <strong key={index}>
                                      {segment.slice(2, -2)}
                                    </strong>
                                  );
                                } else if (segment === "*") {
                                  return <span key={index}>• </span>;
                                }
                                return segment;
                              })}
                          </p>
                        </div>

                        {cm.image && cm.image.length ? (
                          <div className="img-up-qur">
                            <FileImageOutlined className="media-icon" />
                            <div className="asset-container">
                              <div className="asset-item full-size">
                                <img src={cm.image[0]} alt="" />
                              </div>
                              {cm.image.length > 1 && (
                                <div
                                  className="more-assets"
                                  onClick={() => showModal("image", cm.image)}
                                >
                                  <span>+{cm.image.length - 1}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                        {cm.sound && cm.sound.length ? (
                          <div className="img-up-qur">
                            <SoundOutlined className="media-icon" />
                            <div className="asset-container">
                              <div className="asset-item full-size">
                                <ReactAudioPlayer src={cm.sound[0]} controls />
                              </div>
                              {cm.sound.length > 1 && (
                                <div
                                  className="more-assets"
                                  onClick={() => showModal("sound", cm.sound)}
                                >
                                  <span>+{cm.sound.length - 1}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}

                        {cm?.third_model && cm.third_model.length ? (
                          <div className="img-up-qur">
                            <BoxPlotOutlined className="media-icon" />
                            <div>
                              {cm.third_model.map((modelUrl, index) => (
                                <button
                                  className="custom-button"
                                  key={index}
                                  onClick={() => showModal(modelUrl)}
                                  style={{ margin: "0 5px 5px 0" }}
                                >
                                  View 3D Model {index + 1}
                                </button>
                              ))}
                            </div>
                          </div>
                        ) : null}

                        {cm.video && cm.video.length ? (
                          <div className="img-up-qur">
                            <VideoCameraOutlined className="media-icon" />
                            <div className="vd-nm-pr">
                              <ReactPlayer
                                url={cm.video[0]}
                                controls
                                width="100%"
                                height="100%"
                              />
                            </div>
                            {cm.video.length > 1 && (
                              <div
                                className="more-assets"
                                onClick={() => showModal("video", cm.video)}
                              >
                                <span>+{cm.video.length - 1}</span>
                              </div>
                            )}
                          </div>
                        ) : null}
                        {cm.code && cm.code.length ? (
                          <div className="img-up-qur">
                            <CodeOutlined className="media-icon" />
                            {cm.code.map((code, imi) => {
                              return (
                                <pre key={imi}>
                                  <code>{code}</code>
                                </pre>
                              );
                            })}
                            <br />
                          </div>
                        ) : null}

                        {cm.mode === "game_code" ? (
                          <div className="run-game-card">
                            <a
                              href={`https://u.computer?data_id=${cm._id}`}
                              target="blank"
                            >
                              <button
                                className="custom-button"
                                onClick={() => {
                                  setLeft({
                                    ...left,
                                    flow: "search",
                                    chatData: cm,
                                  });
                                }}
                              >
                                Play
                              </button>
                            </a>
                            <button
                              className="custom-button"
                              onClick={() => {
                                setLeft({
                                  ...left,
                                  flow: "search",
                                  chatData: cm,
                                });
                              }}
                              style={{ margin: "0px 10px" }}
                            >
                              Refine
                            </button>
                            {assist.selectedGame?.game_id === cm._id ? (
                              <button
                                className="custom-button"
                                onClick={() =>
                                  updateCode(
                                    {
                                      data_id: cm._id,
                                      edit: assist.selectedGame?._id,
                                    },
                                    cm
                                  )
                                }
                              >
                                Update
                              </button>
                            ) : (
                              <button
                                className="custom-button"
                                onClick={() => {
                                  setPlusFeed({
                                    title: "",
                                    description: "",
                                    link: `https://u.computer?data_id=${cm._id}`,
                                    platform: "dendric",
                                    game_id: cm._id,
                                    game_type: "generated"
                  
                                  });
                                }}
                              >
                                Save
                              </button>
                            )}
                          </div>
                        ) : null}

                        {/* Copy icon and function */}
                        <div className="copy-icon-container">
                          <CopyOutlined
                            className="copy-icon"
                            onClick={() => {
                              navigator.clipboard.writeText(cm.response);
                              // You can add a notification or state update here to show that the text has been copied
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                    <div className="imgs-bx-crts">
                      <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          duration: assist.animation ? 0.7 : 0,
                          delay: assist.animation ? 1 : 0,
                        }}
                        style={{ width: "100%" }}
                      >
                        {cm.newImages &&
                          cm.newImages.map((cim, cii) => {
                            return (
                              <div
                                className={`${
                                  assist.editImg === cim ? " sel-edit-img" : ""
                                }`}
                              >
                                <img src={cim} alt="" key={cii} />
                                <div className="img-edit-d-icons">
                                  <Tooltip color="white" title="Edit Image">
                                    {assist.editImg === cim ? (
                                      <MdClose
                                        onClick={() => {
                                          setassist({
                                            ...assist,
                                            editImg: undefined,
                                          });
                                        }}
                                      />
                                    ) : (
                                      <RiImageEditFill
                                        onClick={() => {
                                          setassist({
                                            ...assist,
                                            editImg: cim,
                                          });
                                        }}
                                      />
                                    )}
                                  </Tooltip>
                                  <a download href={cim}>
                                    {" "}
                                    <FaDownload />
                                  </a>
                                </div>
                              </div>
                            );
                          })}
                        {cm.newVideo ? (
                          <div
                            className={`  ${
                              assist.editImg === cm.newVideo
                                ? " sel-edit-img"
                                : ""
                            }`}
                            style={{ paddingLeft: "5px" }}
                          >
                            <div className={`vd-nm-pr  `}>
                              <ReactPlayer url={cm.newVideo} controls />
                            </div>
                            <div
                              style={{ marginTop: "10px" }}
                              className="img-edit-d-icons"
                            >
                              <Tooltip color="white" title="Edit Image">
                                {assist.editImg === cm.newVideo ? (
                                  <MdClose
                                    onClick={() => {
                                      setassist({
                                        ...assist,
                                        editImg: undefined,
                                      });
                                    }}
                                  />
                                ) : (
                                  <RiImageEditFill
                                    onClick={() => {
                                      setassist({
                                        ...assist,
                                        editImg: cm.newVideo,
                                      });
                                    }}
                                  />
                                )}
                              </Tooltip>
                              <a download href={cm.newVideo}>
                                {" "}
                                <FaDownload />
                              </a>
                            </div>
                          </div>
                        ) : null}
                      </motion.div>
                    </div>
                    <div className="date-tm-chat flex-bxos">
                      <div></div>
                      <span>
                        {moment(cm.createdAt).format("lll")}
                        {/* {cm.isEdit ? <Create row={cm} /> : <span />} */}
                      </span>
                    </div>
                  </div>
                </div>

                {cm.isCreated ? (
                  <p style={{ flexDirection: "row" }} className="chat-out-p">
                    <b>
                      <FaLaptop className="o-icn-dcp" />
                    </b>
                    <span>
                      <div className="typing-loader"></div>
                    </span>
                  </p>
                ) : null}
              </div>
            );
          })}
        {load ? (
          <div style={{ marginTop: "10px" }}>
            <div className="chat-out-p1">
              <p
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "white",
                }}
                className=""
              >
                <span>
                  {load} <BiCheckDouble className="double-tick-b" />
                </span>
              </p>
            </div>
            <div className="chat-out-p12">
              <p
                style={{ flexDirection: "row", width: "70px", height: "70px" }}
                className=""
              >
                <span>
                  <div className="typing-loader"></div>
                </span>
              </p>
            </div>
          </div>
        ) : null}
        {/* </>
  )} */}
      </div>

      <div className="bottom-chat-c">
        <div className="main-com-bx-1">
          <div className="InputContainer">
            {user && user.name === undefined ? (
              // Show the signup form if the user is not logged in or has no name
              <Form
                onFinish={() => {
                  setFormType("signup"); // Call setFormType with "signup"
                }}
                style={{ marginBottom: "0px", width: "100%" }}
              >
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  className="create-cmt-vdc21"
                >
                  <Input.TextArea
                    style={{
                      marginBottom: "0px",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    placeholder="Write a message"
                    onChange={handleChange}
                    value={comentNew}
                    autoSize={{ minRows: 1, maxRows: 8 }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        setFormType("signup");
                      }
                    }}
                  />
                  <div className="icns-icns-st-rt">
                    {load ? (
                      <Spin />
                    ) : (
                      <RiSendPlaneFill
                        onClick={() => {
                          setFormType("signup"); // Call setFormType with "signup"
                        }}
                      />
                    )}
                  </div>
                </Form.Item>
              </Form>
            ) : (
              // Show the regular form if the user is logged in
              <Form
                onFinish={() => {
                  const message = comentNew.trim();
                  if (message) {
                    setComentNew("");
                    setLoad(message);

                    setTimeout(() => {
                      setLoad(message + " (sent)");
                      setTimeout(() => {
                        setLoad(undefined);
                        handleSubmit(message);
                      }, 200);
                    }, 200);
                  }
                }}
                style={{ marginBottom: "0px", width: "100%" }}
              >
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  className="create-cmt-vdc21"
                >
                  <Input.TextArea
                    style={{
                      marginBottom: "0px",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    placeholder="Write a message"
                    onChange={handleChange}
                    value={comentNew}
                    autoSize={{ minRows: 1, maxRows: 8 }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        const message = comentNew.trim();
                        if (message) {
                          setComentNew("");
                          setLoad(message);

                          setTimeout(() => {
                            setLoad(message + " (Message Received)");
                            setTimeout(() => {
                              setLoad(undefined);
                              handleSubmit(message);
                            }, 100);
                          }, 100);
                        }
                      }
                    }}
                  />
                  <div className="icns-icns-st-rt">
                    {load ? (
                      <Spin />
                    ) : (
                      <RiSendPlaneFill
                        onClick={() => {
                          const message = comentNew.trim();
                          if (message) {
                            setComentNew("");
                            setLoad(message);

                            setTimeout(() => {
                              setLoad(message + " (Message Received)");
                              setTimeout(() => {
                                setLoad(undefined);
                                handleSubmit(message);
                              }, 100);
                            }, 100);
                          }
                        }}
                      />
                    )}

                    <Modal
                      title={
                        modalContent.type
                          ? `All ${modalContent.type}s`
                          : "3D Model Viewer"
                      }
                      visible={modalVisible}
                      onCancel={handleCancel}
                      footer={null}
                      width={800}
                      bodyStyle={{
                        backgroundColor: "#1f1f1f",
                        color: "#ffffff",
                      }}
                      style={{ top: 20 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "10px",
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() =>
                            downloadAll(modalContent.assets, modalContent.type)
                          }
                        >
                          Download All
                        </Button>
                      </div>
                      {modalContent.type === "3d" ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Canvas
                            camera={{ position: [0, 0, 5], fov: 50 }}
                            style={{ width: "100%", height: "100%" }}
                          >
                            <ambientLight intensity={1.0} />
                            <directionalLight
                              intensity={1.5}
                              position={[5, 10, 5]}
                            />
                            <pointLight
                              intensity={1.0}
                              position={[-5, -10, -5]}
                            />
                            <spotLight
                              intensity={1.0}
                              position={[10, 10, 10]}
                              angle={0.3}
                              penumbra={1}
                            />
                            <Suspense fallback={null}>
                              <Model
                                url={modalContent.assets[0]}
                                scale={[2, 2, 2]}
                              />
                            </Suspense>
                            <OrbitControls
                              enableZoom={true}
                              maxDistance={10}
                              minDistance={1}
                              target={[0, 0, 0]}
                            />
                          </Canvas>
                        </div>
                      ) : (
                        <div
                          className={`modal-asset-grid ${
                            modalContent.type === "sound" ? "sound-grid" : ""
                          }`}
                        >
                          {modalContent.assets.map((asset, index) => (
                            <div key={index} className="modal-asset-item">
                              {modalContent.type === "image" && (
                                <img src={asset} alt="" />
                              )}
                              {modalContent.type === "sound" && (
                                <ReactAudioPlayer src={asset} controls />
                              )}
                              {modalContent.type === "video" && (
                                <ReactPlayer
                                  url={asset}
                                  controls
                                  width="100%"
                                  height="auto"
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </Modal>
                  </div>
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  message,
  notification,
  Modal,
  Spin,
  Upload,
} from "antd";

import { Link, json, useNavigate } from "react-router-dom";
import { AiFillMail } from "react-icons/ai";
import bcrypt from "bcryptjs";
import Header from "../../components/Header";
import SearchContext from "../../Context/SearchContext";
import GreenTick from "../../components/GreenTick";
import { v4 as uuidv4 } from "uuid";
import ReactPlayer from "react-player";
import loginimg from "../../images/login-img-1.png";

export default function Signup() {
  const { formType, setFormType, setAfterSU, user } = useContext(SearchContext);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    setImageUrl(info.file.originFileObj);
  };
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    name: "",
    password: "",
    country: "country",
    permission: "owner",
    language: "English",
    business_name: "",
  });
  const [emailErr, setEmailErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [embNameErr, setBNameErr] = useState(false);
  useEffect(() => {
    if (user && user.name) {
      navigate("/");
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const isEmail = testEmail();
    const isName = form.name.length > 0 ? false : true;
    const isBName = form.business_name.length > 0 ? false : true;
    const isPass = form.password.length > 7 ? false : true;
    setBNameErr(isBName);
    setNameErr(isName);
    setEmailErr(isEmail);
    setPassErr(isPass);
    console.log(isBName, isEmail, isName, isPass);
    if (!isEmail && !isName && !isBName && !isPass) {
      // addUser(form);
      save();
    } else {
    }
  };
  const testEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
      return false;
    } else {
      return true;
    }
  };

  const save = () => {
    const formData = new FormData();
    formData.append("business_name", form.business_name);
    formData.append("subscription", "Pro");
    formData.append("color_code", "black");
    formData.append("chat_count", 1);
    formData.append("root_email", form.email);
    formData.append("storage_usage_rate", 0.04);
    formData.append("compute_usage_rate", 10);
    formData.append("balance", 0);
    formData.append("earn", "pending");
    formData.append("onboard", "new");

    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(`https://embed.axv.ai/api/organization`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          addUser(form, response.newOrganization);
        }
      });
  };

  const addUser = (user, org) => {
    user.password = bcrypt.hashSync(
      user.password,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    let id = uuidv4();
    user.profile_id = id;
    user.user_id = org._id;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(user),
    };
    fetch(`https://embed.axv.ai/api/consumer/users`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isCreated) {
          setForm({
            email: "",
            name: "",
            password: "",
            country: "country",
            organization_id: "or id",
            user_id: "",
            permission: "nothing",
            language: "English",
            business_name: "",
          });
          message.success({
            content: "Account Created. Please Log in",
            duration: 10, // Duration in seconds
            style: {
              fontSize: "25px", // Increase font size
            },
          });

          console.log("Sending email verification for:", user.email);
    sendEmailVerification(user.email);
          navigate("/login");
          setAfterSU(user.email);
          
          // setFormType("login");
        } else if (response.isExists) {
          message.warning("This email is already in use");
        }
      });
  };

  const sendEmailVerification = (email) => {
    console.log("Initiating email verification for:", email);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    };
  
    fetch("https://embed.axv.ai/api/verification/send", requestOptions)
      .then((response) => {
        console.log("Email verification response status:", response.status);
        if (!response.ok) {
          throw new Error(`Email verification request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Email verification request sent:", data);
      })
      .catch((error) => {
        console.error("Error sending email verification request:", error);
      });
  };


  const changeField = (key, val, row) => {
    row[key] = val;
    setForm({ ...form, [key]: val });
  };
  return (

    <div className="black-theme">
      <Header />
    <div className="login-page">
      <div className="container">
        <div className="row no-gutter">
        <div className="col-md-6">
  <div className="d-none d-md-flex bg-image login-box-bg">
    <img src={loginimg} className="login-imt-1cs" alt="" style={{objectFit: 'cover', width: '100%', height: '100%'}} />
  </div>
</div>
          <div className="col-md-6 ">
            <div className="login login-box-bg d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 col-xl-7 mx-auto">
                    <h3
                      onClick={() => console.log(imageUrl)}
                      className="display-4"
                      style={{fontSize:"40px"}}
                    >
                      Get Started
                    </h3>
                    <p className=" mb-4">Imagine anything you want.</p>
                    <form>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          placeholder="Name"
                          required=""
                          value={form.name}
                          autofocus=""
                          className={`form-control  border-0 shadow-sm px-4 ${
                            nameErr ? " error-field" : ""
                          }`}
                          onChange={(e) =>
                            changeField(
                              "name",
                              e.target.value,
                              form
                            )
                          }
                        />
                        {nameErr ? (
                          <p className="err-txt-signup">Name Required</p>
                        ) : null}
                      </div>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          placeholder="Business Name"
                          required=""
                          autofocus=""
                          value={form.business_name}
                          className={`form-control  border-0 shadow-sm px-4 ${
                            embNameErr ? " error-field" : ""
                          }`}
                          onChange={(e) =>
                            changeField("business_name", e.target.value, form)
                          }
                        />
                        {embNameErr ? (
                          <p className="err-txt-signup">
                            Business Name Required
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group mb-3">
                        <input
                          id="inputEmail"
                          type="email"
                          value={form.email}
                          placeholder="Email address"
                          required=""
                          autofocus=""
                          className={`form-control  border-0 shadow-sm px-4 ${
                            emailErr ? " error-field" : ""
                          }`}
                          onChange={(e) =>
                            changeField(
                              "email",
                              e.target.value.toLowerCase(),
                              form
                            )
                          }
                        />
                        {emailErr ? (
                          <p className="err-txt-signup">Need Valid Email</p>
                        ) : null}
                      </div>
                      <div className="form-group mb-3">
                        <input
                          id="inputPassword"
                          type="password"
                          value={form.password}
                          placeholder="Password"
                          onChange={(e) =>
                            changeField("password", e.target.value, form)
                          }
                          required=""
                          className={`form-control  border-0 shadow-sm px-4 ${
                            passErr ? " error-field" : ""
                          }`}
                        />
                        {passErr ? (
                          <p className="err-txt-signup">
                            Password Must be Atleast 8 Characters
                          </p>
                        ) : null}
                      </div>
                      {/* <Form.Item>
                        <div className="f-div-logi0s">
                          <Upload
                            listType="picture-circle"
                            // fileList={fileList}

                            onChange={handleChange}
                            maxCount={1}
                          >
                            upload
                          </Upload>
                        </div>
                      </Form.Item> */}
                      <div className="custom-control custom-checkbox mb-3">
                        <div className="login-forget-btn">
                          <div className="for-reg-c">
                            <span style={{color:"white"}}>
                             
                              Already have an account?
                            </span>
                          </div>
                          <Link className="signup-txt-login" to="/login">
                            <span>Login</span>
                          </Link>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "-20px" }}
                        className="login-butotn-d"
                      >
                        <button
                          onClick={handleSubmit}
                          class="custom-button"
                          type="submit"
                        >
                          <strong>Sign Up</strong>
                          
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";

import {
  Modal,
  Button,
  Input,
  Slider,
  message,
  Spin,
  Table,
  Popconfirm,
} from "antd";

import { FaPlus } from "react-icons/fa";
import Paragraph from "antd/es/typography/Paragraph";
import SearchContext from "../../../../../Context/SearchContext";

export default function FeedPlusModal() {
  const { user, organization, plusFeed, setPlusFeed, assist, setassist } =
    useContext(SearchContext);
  const [loading, setloading] = useState(false);

  const [type, setType] = useState();
  const [isChange, setIsChange] = useState(false);

  const savePlay = (row) => {
    const obj = {
      user_id: user?.user_id,

      status: "pending",
      feed_type: "game",
      media_type: plusFeed.media_type,
      title: plusFeed.title,
      device: plusFeed.device,
      recording_type: plusFeed.recording_type,
      description: plusFeed.description,
      link: plusFeed.link,
      version_id: plusFeed.version_id,
      steam_key: plusFeed.steam_key,
      platform: plusFeed.platform,
      time_length: plusFeed.time_length,
      game_id: plusFeed.game_id,
    };
    setPlusFeed();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed/core`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isSuccess) {
          message.success("Added");
        } else if (response.msgError === "Insufficient credits") {
          // setShowPrice(true);
        } else {
          message.error("something went wrong");
        }
      });
  };
  const updaePlay = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed/${plusFeed?._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isPatched) {
          setloading();
          setPlusFeed();
          setassist({
            ...assist,
            updateGameData: data.patchedData,
          });
          // message.success("Deleted");
        } else {
          setloading();

          message.error("Something Went Wrong");
        }
      });
  };
  const addForm = () => {
    return (
      <div className=" form-pf">
        <div className="game-show-p1">
          <div>
            <div>
              <div>
                {plusFeed?.activeCard ? (
                  <div
                    style={{ padding: "5px" }}
                    class="tele-cards tele-more-space-c gold-card-s"
                  >
                    <div>
                      <div class="tele-card tele-blue heigth-tele-card full-w-tele">
                        <img
                          className="card-img-s1"
                          src={plusFeed?.activeCard?.thumbnail}
                          alt=""
                        />
                        <div className="tele-m-c-f1">
                          <div>
                            <p class="tele-tip">
                              {plusFeed?.activeCard?.title}
                            </p>
                            <Paragraph
                              ellipsis={{
                                rows: 4,
                                // expandable: true,
                                symbol: "see more",
                              }}
                              style={{ width: "100%" }}
                              className="desc-p-crdjjkl"
                            >
                              {plusFeed?.activeCard?.description}
                            </Paragraph>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {plusFeed?.activeCard ? null : (
                  <label
                    style={{ margin: "15px 0px" }}
                    for="name"
                    class="label-pf"
                  >
                    <span class="title-pf">Name *</span>
                    <input
                      placeholder="Name"
                      onChange={(e) => {
                        setPlusFeed({
                          ...plusFeed,

                          title: e.target.value,
                        });
                      }}
                      value={plusFeed.title}
                      style={{ width: "100%" }}
                      class="input-field-pf"
                    />
                  </label>
                )}
                {plusFeed?.activeCard ? null : (
                  <label
                    style={{ margin: "15px 0px" }}
                    for="link"
                    class="label-pf"
                  >
                    <span class="title-pf">Link *</span>
                    <input
                      placeholder="Link"
                      onChange={(e) => {
                        setPlusFeed({
                          ...plusFeed,

                          link: e.target.value,
                        });
                      }}
                      value={plusFeed.link}
                      style={{ width: "100%" }}
                      class="input-field-pf"
                    />
                  </label>
                )}

                {plusFeed?.activeCard ? null : (
                  <label
                    style={{ margin: "15px 0px" }}
                    for="Platform"
                    class="label-pf"
                  >
                    <span class="title-pf">Platform</span>
                    <select
                      class="input-field-pf"
                      onChange={(e) => {
                        setPlusFeed({
                          ...plusFeed,

                          platform: e.target.value,
                          steam_key: undefined,
                        });
                      }}
                      value={plusFeed.platform}
                    >
                      <option disabled value={undefined}>
                        Select Platform
                      </option>

                      <>
                        <option value="chrome">Chrome</option>
                        <option value="epic_Store">Epic Store</option>
                        <option value="steam">Steam</option>
                        <option value="none">None</option>
                      </>
                    </select>
                  </label>
                )}

                <label
                  style={{ margin: "15px 0px" }}
                  for="name"
                  class="label-pf"
                >
                  <span class="title-pf">Description </span>
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    placeholder="Description"
                    onChange={(e) => {
                      setPlusFeed({
                        ...plusFeed,
                        description: e.target.value,
                      });
                    }}
                    value={plusFeed.description}
                    style={{ width: "100%" }}
                    size="large"
                  />
                </label>
              </div>
            </div>

            {plusFeed._id ? (
              <div className="save-btn-pl flex-end">
                {loading ? (
                  <Spin />
                ) : (
                  <button
                    onClick={() => {
                      setloading(true);
                      updaePlay({
                        title: plusFeed.title,
                        description: plusFeed.description,
                        link: plusFeed.link,
                        platform: plusFeed.platform,
                      });
                    }}
                    size="large"
                    className="custom-button orange-btn-cs"
                    style={{
                      maxWidth: "300px",
                    }}
                  >
                    Update
                  </button>
                )}
              </div>
            ) : plusFeed?.title &&
              plusFeed?.title.length &&
              plusFeed?.link &&
              plusFeed?.link.length &&
              plusFeed?.platform &&
              plusFeed?.platform.length ? (
              <div className="save-btn-pl flex-end">
                {loading ? (
                  <Spin />
                ) : (
                  <button
                    onClick={() => {
                      // setloading(true);
                      // setPlusFeed({
                      //   ...plusFeed,
                      //   showPlay: undefined,
                      // });
                      setloading(true);
                      savePlay(plusFeed);
                    }}
                    size="large"
                    className="custom-button orange-btn-cs"
                    style={{
                      maxWidth: "300px",
                    }}
                  >
                    Start
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>


      <Modal
        // width={400}
        open={plusFeed !== undefined }
        onCancel={() => {
          if (plusFeed.isFirst) {
          } else {
            setPlusFeed();

            setType();
          }
        }}
        className={`no-f-no-h top-20-x  brand-modal `}
      >
        {plusFeed ? (
          <div>
            <div style={{ minHeight: "50vh" }} className="add-percon-mains">
              {addForm()}
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
}

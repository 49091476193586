import React, { useState, useContext, useEffect } from "react";
import Chat from "./components/Chat";
import "./newhome.css";
import "./theme.css";
import SearchContext from "../../Context/SearchContext";
import SearchThubnail from "./components/flow/SearchThubnail";
import { Col, Drawer, Row } from "antd";
import { v4 as uuidv4 } from "uuid";

import { AiOutlineClose } from "react-icons/ai";
import GeneratedLive from "./components/flow/GeneratedLive";
import WebGenerated from "./components/flow/WebGenerate";
import Header from "../../components/Header";
import ThemeSelection from "./components/ThemeSelection";
import Assests from "./components/flow/Assests";
import {
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill,
} from "react-icons/bs";
import { BsImages } from "react-icons/bs";
import Call from "./components/Call";
import { isMobile } from "react-device-detect";
import Onboard from "./components/onboard/Onboard";
import dendrite from "../../images/dendrite.png";

export default function Image() {
    const {
        setUser,
        loginChange,
        left,
        setLeft,
        theme,
        setFormType,
        setTheme,
        setOrganization,
        setLanguage,
      } = useContext(SearchContext);
      const [loading, setLoading] = useState(true);
    
      useEffect(() => {
        const u = JSON.parse(window.localStorage.getItem("user"));
        let obj;
        if (u) {
          obj = u;
          setUser(u);
          getOrganization(u);
        } else {
          let isID = JSON.parse(window.localStorage.getItem("isID"));
          let user_id;
          if (isID) {
            user_id = isID.user_id;
          } else {
            user_id = uuidv4();
          }
          obj = { user_id };
          setUser(obj);
        }
        setLoading(false);
      }, [loginChange]);
    
      useEffect(() => {
        const t = window.localStorage.getItem("theme");
        const lang = window.localStorage.getItem("language");
        if (lang) {
          setLanguage(lang);
        }
        if (t) {
          setTheme(t);
        }
      }, []);
    
      const getOrganization = (u) => {
        const requestOptions = {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
        fetch(`https://embed.axv.ai/api/organization/${u.user_id}`, requestOptions)
          .then((response) => response.json())
          .then((response) => {
            if (response && response.isSuccess) {
              let org = response.organization;
              setOrganization(org);
            }
          });
      };
    
      const [selectedOption, setSelectedOption] = useState("Create");
    
      const handleOptionClick = (option) => {
        setSelectedOption(option);
      };
    
      const optionGifs = {
        Create: "/Mountains.gif",
        Understand: "/Mountains.gif",
        Edit: "/Mountains.gif",
        Observe: "/Mountains.gif",
      };
    
  return !loading ? (
    <div className={`main-bx-2fc-m ${theme}-theme`}>
      <div className="heeader-landing-container">
        <Header />
      </div>
      <div className="home-main-lanidng">
        {/* Centered container */}
        <div className="centered-container">
          <h1 className="large-title">Ideas Are All You Need</h1>
          <p className="subtitle landing-title-sub">
            Generate new videos from scratch in minutes using Optical Language
            Models that create, reimagine and understand multimedia
          </p>
          <div className="card-buttons">
            <a href="https://docs.olm.ai/product/getting-started" target="_blank" className="card-button">
              Product
            </a>
            <a href="https://docs.olm.ai/product/use-cases" target="_blank" className="card-button">
              Use Cases
            </a>
            <a href="https://docs.olm.ai/company/safety" target="_blank" className="card-button">
              Safety
            </a>
            <a href="https://docs.olm.ai/developers/getting-started" target="_blank" className="card-button">
              Developers
            </a>
          </div>
        </div>

        <div className="centered-container video-container">
          <video autoPlay loop muted width="100%" height="auto">
            <source src="/Mountains.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="button-container">
          <button className="card-button" onClick={() => setFormType("signup")}>
            Get Started
          </button>
          <button className="card-button">
            <a href="https://calendly.com/olm_ai/30min" target="_blank" rel="noopener noreferrer">
              Book a Demo
            </a>
          </button>
        </div>

        {/* New Section 1: Centralize your spend */}
        <section className="centralize-spend">
          <h2>Centralize your spend on one intuitive platform</h2>
          <div className="feature-grid">
            <div className="feature-item">
              <h3>Travel</h3>
              <p>Turn travel policies into guardrails everyone on the team will follow.</p>
            </div>
            <div className="feature-item card">
              <img src={dendrite} alt="Ramp Card" />
            </div>
            <div className="feature-item">
              <h3>Expense management</h3>
              <p>Automate expense management with Ramp's corporate card, reimbursements, and more.</p>
            </div>
            <div className="feature-item">
              <h3>Vendor management</h3>
              <p>Track all your vendor data and discover pricing insights in one convenient place.</p>
            </div>
            <div className="feature-item">
              <h3>Procurement</h3>
              <p>Upgrades the entire procure-to-pay process, eliminates approval bottlenecks, and prevents out-of-policy spend before it happens.</p>
            </div>
            <div className="feature-item">
              <h3>Accounts payable</h3>
              <p>Automates your entire accounts payable workflow to record, approve, and pay every bill without any data entry or repetitive tasks.</p>
            </div>
          </div>
        </section>

        {/* New Section 2: Effortless expenses */}
        <section className="effortless-expenses">
          <h2>Effortless expenses for employees (and managers)</h2>
          <div className="expense-features">
            <div className="expense-feature">
              <h3>Swipe, then submit on the go</h3>
              <p>You can send receipts with an SMS, via mobile app, or auto-route through smart integrations like Gmail, Lyft, and Amazon.</p>
              <img src={dendrite} alt="Mobile receipt submission" />
            </div>
            <div className="expense-feature">
              <h3>Don't sweat a missing receipt</h3>
              <p>Ramp automatically finishes expenses for you by routing transactions to the right limits, generating receipts for purchases, and saving recurring memos.</p>
              <div className="receipt-generated">
                <span className="amount">$25.50</span>
                <span className="receipt-status">Receipt generated</span>
              </div>
            </div>
          </div>
        </section>

        {/* New Section 3: For startups, global enterprises */}
        <section className="for-all-businesses">
          <h2>For startups, global enterprises, and everyone in between.</h2>
          <p>Simple defaults, direct integrations, and advanced customization means Ramp will scale with you.</p>
          <div className="business-features">
            <div className="business-feature">
              <h3>Integrate and stay synced</h3>
              <p>Seamlessly integrate Ramp with your accounting systems and consolidate your finance stack.</p>
            </div>
            <div className="business-feature">
              <h3>Operate globally</h3>
              <p>Send payments to 195 countries in over 40 currencies and reimburse employees in their local currencies within 2 days.</p>
            </div>
            <div className="business-feature">
              <h3>Completely flexible</h3>
              <p>Customize Ramp to fit your business and give you the controls you need with policies, roles, and approval workflows.</p>
            </div>
            <div className="business-feature">
              <h3>An extension of your team</h3>
              <p>Get dedicated support anytime, anywhere. We're always ready to help.</p>
            </div>
          </div>
        </section>

        {/* New Section 4: CTA READY */}
        <section className="cta-section">
        <h2 className="large-title">Ready to transform your business finances?</h2>
          <p>Join thousands of companies using Ramp to streamline their financial operations.</p>
          <button className="cta-button" onClick={() => setFormType("signup")}>
            Start Your Free Trial
          </button>
        </section>

        {/* New Section 5: Features - SECTION READY */}
        <section className="features-section">
          <h2 className="large-title">Powerful Features for Your Business</h2>
          <div className="features-grid">
            <div className="feature">
              <img src={dendrite} alt="Real-time Reporting" />
              <h3>Real-time Reporting</h3>
              <p>Get instant insights into your spending patterns and financial health.</p>
            </div>
            <div className="feature">
              <img src={dendrite} alt="AI-Powered Insights" />
              <h3>AI-Powered Insights</h3>
              <p>Leverage machine learning to identify cost-saving opportunities.</p>
            </div>
            <div className="feature">
              <img src={dendrite} alt="Seamless Integrations" />
              <h3>Seamless Integrations</h3>
              <p>Connect with your favorite tools for a unified financial ecosystem.</p>
            </div>
            <div className="feature">
              <img src={dendrite} alt="Custom Workflows" />
              <h3>Custom Workflows</h3>
              <p>Design approval processes that match your organizational structure.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  ) : null;
}
import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Alert, Button } from "antd";
import SearchContext from "../../../../Context/SearchContext";
import GameCards from "./gameComponents/GamesCards";
import { FaPlus } from "react-icons/fa";


export default function Game() {
  const { setLeft, left, setPlusFeed, plusFeed } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const renderContent = () => {
    if (left.gameTab === "sessions") {
      return (
        <div className="bx-cls-bmbs">
          <div
            className="no-heigh-div-c full-h-bx-a"
            style={{
              width: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              href="https://billing.stripe.com/p/login/00gfZz7uLeaH50Y288"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Alert
                message="Please Contact For Access"
                description={
                  <div>
                    <div>Playtest allows you to automate QA and playtesting needs by using 
                        the Dendric Playtest model.</div>
                  </div>
                }
                type="info"
                showIcon
                className="brand-alert bg-txt-a"
              />
            </a>
          </div>
        </div>
      );
    } else {
      return <GameCards />;
    }
  };

  return loading ? null : (
    <div className={`chat-bx-mn`}>
      <div className="act-logs-fcv">
        <div className="game-tabsc-s">
          <span
            onClick={() => {
              setLeft({
                ...left,
                gameTab: "",
              });
            }}
            className={`btn-appr select-tcbn-a ${
              left.gameTab === "" ? "btn-apr-s" : ""
            }`}
          >
            <span>Your Games</span>
          </span>
          <span
            onClick={() => {
              setLeft({
                ...left,
                gameTab: "sessions",
              });
            }}
            className={`btn-appr select-tcbn-a ${
              left.gameTab === "sessions" ? "btn-apr-s" : ""
            }`}
          >
            <span>Playtest</span>
          </span>{" "}
          <Button
            icon={<FaPlus />}
            onClick={() => {
              console.log("Button clicked for feed");
              setPlusFeed({
                ...plusFeed,
                creeate: true,
                time_length: 1,
                name: "",
                title: "",
                description: "",
                link: "",
                platform: "chrome",
                email: "",
                activeCard: undefined,
              });
            }}
            className="circular-button"
          ></Button>
        </div>
        {renderContent()}
      </div>
    </div>
  );
}
import React, { useContext } from "react";
import { Card, Button } from "antd";
import SearchContext from "../Context/SearchContext";

export default function Credits() {
  const { organization } = useContext(SearchContext);

  const balanceColor = organization.balance === 0 ? "red" : "green";

  return (
    <div className="pricing-container">
      <div className="card-container">
        <Card className="credit-card">
          <h2>Credit Balance</h2>
          <p className="balance" style={{ color: balanceColor }}>
            {organization.balance}
          </p>
          <Button
            className="custom-button-credit"
            onClick={() => window.open("https://pay.telemit.com/b/5kA7tBdyGb4rdqg5kU", "_blank")}
          >
            Add Credits
          </Button>
        </Card>
        <Card className="info-card">
          <h2>How Credits Work</h2>
          <p className="credit-info">
            Dendric has daily usage limits, but credits allow you to bypass these rate limits and scale your usage according to your needs. With credits, you can increase your API call capacity and utilize Dendric's features more extensively, enabling you to build more powerful and responsive applications.
          </p>
          <Button
            className="custom-button-credit"
            onClick={() => window.open("https://docs.dendric.com/building-with-dendric", "_blank")}
          >
            Learn More
          </Button>
        </Card>
      </div>

      <style jsx>{`
        .pricing-container {
          font-family: Arial, sans-serif;
          padding: 20px;
          max-width: 1200px;
          margin: 0 auto;
          background-color: #1e1e1e;
          color: white;
        }
        .card-container {
          display: flex;
          justify-content: center;
          gap: 20px;
        }
        .credit-card,
        .info-card {
          width: 100%;
          max-width: 400px;
          background-color: #2a2a2a;
          border: 1px solid #444;
        }
        h2 {
          font-size: 24px;
          margin-bottom: 10px;
          color: white;
          text-align: center;
        }
        .balance {
          font-size: 32px;
          font-weight: bold;
          margin: 20px 0;
          text-align: center;
        }
        .custom-button-credit {
          background: linear-gradient(to right, #ffbe5a, #ffbe5a);
          color: black;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: fit-content;
          padding: 12px 10px;
          line-height: 1;
          margin: 20px auto 0;
        }
        .credit-info {
          color: white;
          text-align: justify;
        }
      `}</style>
    </div>
  );
}
import {
  Alert,
  Card,
  Col,
  Row,
  Spin,
  Button,
  Modal,
  message,
  Select,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import Object3D from "./Object3D";
import axios from "axios";
import SearchContext from "../../../../../Context/SearchContext";
import { color } from "framer-motion";
const { Option } = Select;

export default function Versions() {
  const { left } = useContext(SearchContext);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [versions, setversions] = useState();

  useEffect(() => {
    fetchImages();
  }, []);
  const fetchImages = async () => {
    try {
      const response = await axios.get("https://embed.axv.ai/api/cache", {
        params: {
          data_id: left.chatData._id,
          limit: 100,
          page: 1,
        },
      });

      if (response.data?.cacheList) {
        setversions(response.data?.cacheList);
      } else {
        setversions([]);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };
  const updateFiled = (obj, d) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/cache/${d._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const all = versions.map((m) => (m._id === d._id ? d : m));
          setversions(all);
        } else {
          message.warning("Something Went Wrong");
        }
      });
  };

  return (
    <div className="act-logs-fcv">
      {versions &&
        versions.map((d, index) => (
          <Card key={index} className="game-card-tbs-cr">
            <div>
              <Select
                value={d.status}
                style={{
                  width: "100px",
            
                }}
                onChange={(v) => updateFiled({ status: v }, d)}
       
              >
                <Option  value="live" >

                  Live
                </Option>
                <Option value="cached" >
                  Cached
                </Option>
              </Select>
            </div>
            <div style={{ marginTop: "10px" }} className="flex-end">
              {moment(d.createdAt).format("MMMM D")}
            </div>
          </Card>
        ))}

      {versions && versions.length === 0 ? (
        <div className="no-heigh-div-c">
          <Alert
            message="No Assets Exist"
            type="info"
            showIcon
            className="brand-alert bg-txt-a"
          />
        </div>
      ) : null}
    </div>
  );
}

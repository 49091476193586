import { Alert, Card, Col, Row, Spin, Button, Modal } from "antd";
import moment from "moment";
import React, { useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import Object3D from "./Object3D"

export default function GameAssets({ data }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);

  const showModal = (modelUrl) => {
    setSelectedModel(modelUrl);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <div className="act-logs-fcv">
      {data &&
        data.map((image, index) => (
          <Card key={image._id} className="game-card-tbs-cr">
            <h5>{image.name}</h5>

            {image.type === "sound_effect" ? (
              <div>
                <div className="audio-container">
                  <ReactAudioPlayer src={image.file_url} controls />
                </div>
              </div>
            ) : null}
            {image.type === "background" ? (
              <div>
                <img
                  className="image-assetps-gamec"
                  src={image.file_url}
                  alt={image.name}
                />
              </div>
            ) : null}
            {image.type === "environment" ? (
              <div>
                <img
                  className="image-assetps-gamec"
                  src={image.file_url}
                  alt={image.name}
                />
              </div>
            ) : null}
             {image.type === "overlay" ? (
              <div>
                <img
                  className="image-assetps-gamec"
                  src={image.file_url}
                  alt={image.name}
                />
              </div>
            ) : null}
            {image.type === "object" ? (
              <div>
                {image.shape === "3D" ? (
                  <>
                    <Object3D image_url={image.file_url} />
                    <Button onClick={() => showModal(image.file_url)}>View 3D Model</Button>
                  </>
                ) : (
                  <img
                    className="image-assetps-gamec"
                    src={image.file_url}
                    alt={image.name}
                  />
                )}
              </div>
            ) : null}
            {image.type === "character" ? (
              <div>
                {image.shape === "3D" ? (
                  <>
                    <Object3D image_url={image.file_url} />
                    <Button onClick={() => showModal(image.file_url)}>View 3D Model</Button>
                  </>
                ) : (
                  <img
                    className="image-assetps-gamec"
                    src={image.file_url}
                    alt={image.name}
                  />
                )}
              </div>
            ) : null}
            {image.type === "start_page" ? (
              <div>
                {image.shape === "3D" ? (
                  <>
                    <Object3D image_url={image.file_url} />
                    <Button onClick={() => showModal(image.file_url)}>View 3D Model</Button>
                  </>
                ) : (
                  <img
                    className="image-assetps-gamec"
                    src={image.file_url}
                    alt={image.name}
                  />
                )}
              </div>
            ) : null}
          </Card>
        ))}

      {data && data.length === 0 ? (
        <div className="no-heigh-div-c">
          <Alert
            message="No Assets Exist"
            type="info"
            showIcon
            className="brand-alert bg-txt-a"
          />
        </div>
      ) : null}

      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        bodyStyle={{ backgroundColor: 'white', height: '600px' }}
      >
        {selectedModel && (
          <div style={{ width: '100%', height: '100%' }}>
            <Object3D image_url={selectedModel} />
          </div>
        )}
      </Modal>
    </div>
  );
}
import { Button, Modal, Slider } from "antd";
import React, { useContext, useState } from "react";
import SearchContext from "../../../Context/SearchContext";

export default function UpgradeModal() {
  const { assist, setassist , formType } = useContext(SearchContext);
  const [total, setTotal] = useState(14);
  const [gb, setGb] = useState(1);
  const [s, setS] = useState(0);

  const changeSlide = (v) => {
    setS(v);
    let value = v === 0 ? 1 : v * 10;
    let pay;
  
    if (value <= 1) {
      pay = 14;
    } else if (value <= 10) {
      pay = 89.99;
    } else if (value <= 100) {
      pay = 299.99;
    } else if (value <= 1000) {
      pay = 799.99;
    } else {
      // Handle values greater than 1000 (if needed)
      // pay = some value;
    }
  
    setGb(value);
    setTotal(pay.toFixed(2));
  };
  const formatter = () => gb + " GB";
  return (
    <Modal
  className="no-h-bo-b-modal brand-modal no-close-model"
  open={assist.isUpgrade && formType === undefined}
  width={500}
>
  <h3 className="price-m-bx-h" style={{ marginBottom: "40px" }}>
    Usage Based Pricing
  </h3>

  {/* Compute */}
  <div style={{ display: "flex", marginBottom: "30px" }}>
    <div style={{ marginRight: "30px", border: "2px solid white", padding: "10px" }}>
      <h4>Compute</h4>
      <table>
        <tbody>
          <tr>
            <td><b>Per Image:</b></td>
            <td>$0.01</td>
          </tr>
          <tr>
            <td><b>Per Video:</b></td>
            <td>$0.10/min</td>
          </tr>
          <tr>
            <td><b>Per Generation:</b></td>
            <td>$0.005</td>
          </tr>
        </tbody>
      </table>
      <div className="btn-m-b-up" style={{ marginTop: "10px" }}>
        <a
          href={ "https://buy.stripe.com/dR68xFbqyfkH4TKdR4" }
          target="_blank"
        >
          <Button type="primary">Buy Credits</Button>
        </a>
      </div>
    </div>
    <hr style={{ borderTop: "2px solid white", marginBottom: "20px" }} />
    <div style={{ border: "2px solid white", padding: "10px" }}>
      <h4>Dialogue</h4>
      <table>
        <tbody>
          <tr>
            <td><b>Chat</b></td>
          </tr>
          <tr>
            <td><b>Analytics</b></td>
          </tr>
          <tr>
            <td><b>Search</b></td>
          
          </tr>
        </tbody>
      </table>
      <div className="btn-m-b-up" style={{ marginTop: "10px" }}>
        <a
          href={"https://buy.stripe.com/3cs4hp66e4G3gCs9AN"}
          target="_blank"
        >
          <Button type="primary">$20/Month</Button>
        </a>
      </div>
    </div>
  </div>

  {/* Learn More button */}
  <div style={{ position: "absolute", bottom: "10px", left: "10px" }}>
    <a href="https://docs.avx.ai" target="_blank" rel="noopener noreferrer">
      <Button className="primary" type="primary">
        Learn More
      </Button>
    </a>
  </div>

  {/* Payment */}
  <div className="btn-m-b-up" style={{ marginTop: "20px" }}>
    <Button
      className="red-button"
      type="primary"
      onClick={() => setassist({ ...assist, isUpgrade: false })}
    >
      Exit
    </Button>
  </div>
</Modal>

  


  );
}

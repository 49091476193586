import "./App.css";
import SearchState from "./Context/SearchState";
import Index from "./pages/home/Index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NewHome from "./pages/newHome/NewHome";
import Landing from "./pages/newHome/Landing";
import Image from "./pages/newHome/Image";
import AfterLogin from "./pages/afterLogin/AfterLogin";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import Engine from "./pages/newHome/components/Engine";






function App() {
  return (
    <SearchState>
      <Router>
        <Routes>
          <Route path="/" element={<NewHome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          {/* <Route path="/cave" element={<NewHome />} />*/}
          <Route path="/landing" element={<Landing />} /> 
          <Route path="/image" element={<Image />} />
          <Route path="/engine" element={<Engine />} />
          {/* <Route path="/console" element={<AfterLogin />} /> */}
          {/* <Route path="/test" element={<Index />} />
          <Route path="/login" element={<Login />} /> */}
        </Routes>
      </Router>
    </SearchState>
  );
}


export default App;

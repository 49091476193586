import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Card, Button, Spin, message, Alert, Upload, Popconfirm } from "antd";
import SearchContext from "../../../../../Context/SearchContext";
import moment from "moment";
import { TiAttachmentOutline, TiUpload } from "react-icons/ti";
import { TbPlugConnected } from "react-icons/tb";
import { FaEdit, FaInfo } from "react-icons/fa";
import { MdClose } from "react-icons/md";

const GameTab = () => {
  const {
    setLeft,
    left,
    user,
    assist,
    setassist,
    plusFeed,
    setPlusFeed,
    organization,
    setOrganization,
    right,
    setRight,
    images,
    setImages,
  } = useContext(SearchContext);
  const [games, setGames] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState();
  const [uploadLoading, setUploadLoading] = useState({});
  
  // Fetch games with page number
  const getGames = async (page) => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    try {
      const response = await axios.get(
        `https://embed.axv.ai/api/feed?page=${page}&limit=15&sort_by=-1&feed_type=game&user_id=${
          user?.user_id
        }`,
        requestOptions
      );
      const data = response.data;

      setGames((prevGames) =>
        page > 1 ? [...prevGames, ...data.feedList] : data.feedList
      );
      setTotalPages(data.totalPages);

      // Set first game as selected by default
      if (page === 1 && data.feedList.length > 0) {
        setassist({
          ...assist,
          selectedGame: data.feedList[0],
        });
      }
    } catch (error) {
      console.error("Error fetching games:", error);
      message.error("Failed to fetch games. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (assist.updateGameData) {
      updateGame(assist.updateGameData);
    }
  }, [assist.updateGameData]);

  const updateGame = (updatedGame) => {
    setGames((prevGames) => {
      const index = prevGames.findIndex((game) => game._id === updatedGame._id);
      if (index !== -1) {
        const updatedGames = [...prevGames];
        updatedGames[index] = updatedGame;
        return updatedGames;
      }
      return prevGames;
    });
    setassist({
      ...assist,
      updateGameData: undefined,
    });
  };

  useEffect(() => {
    if (games) {
      setPageNo(1);
      getGames(1);
    }
  }, [left.gameTab]);

  useEffect(() => {
    if (pageNo > 1) {
      getGames(pageNo);
    }
  }, [pageNo]);

  const loadMoreGames = () => {
    setPageNo((prevPageNo) => prevPageNo + 1);
  };

  const handlePostGameId = async (gameId) => {
    try {
      const response = await axios.post(
        "https://embed.axv.ai/api/vector/store",
        { game_id: gameId },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );
      if (response.status === 200) {
        message.success("Game ID posted successfully");
      } else {
        message.error("Failed to post Game ID");
      }
    } catch (error) {
      console.error("Error posting game_id:", error);
      message.error("Failed to post Game ID. Please try again.");
    } finally {
      setPostLoading();
    }
  };

  const handleUpload = (game) => {
    const saveVideo = async (file) => {
      setUploadLoading((prev) => ({ ...prev, [game._id]: true }));
      const formData = new FormData();
      formData.append("file", file);
      formData.append("data_id", game.game_id);
      formData.append("video_duration", "");
      formData.append("purpose", "video");
      formData.append("media_type", "original");
      formData.append("user_id", user ? user.user_id : "map");

      try {
        const response = await fetch(
          `https://embed.axv.ai/api/image/upload/video`,
          {
            method: "POST",
            body: formData,
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        const data = await response.json();

        if (data.isSuccess) {
          const allvid = images && images.videos ? images.videos : [];
          allvid.unshift(data.data);
          setImages({ ...images, videos: allvid });
          setRight({
            ...right,
            activeIcon: right.upOpen ? right.activeIcon : undefined,
            isUpload: undefined,
            activeAI: right.upOpen ? right.activeAI : "message",
          });

          if (organization) {
            setOrganization({ ...organization, compute: data.compute });
          }

          message.success("Video Data Embedded into model");
        } else if (data.message === "Insufficient compute available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        } else if (data.message === "video_duration is required") {
          message.error("Upload Failed. Please try again.");
        }
      } catch (error) {
        console.error("Error uploading video:", error);
        message.error("Failed to upload video. Please try again.");
      } finally {
        setUploadLoading((prev) => ({ ...prev, [game._id]: false }));
      }
    };

    return (
      <Upload
        accept="video/*"
        showUploadList={false}
        beforeUpload={(file) => {
          saveVideo(file);
          return false;
        }}
      >
        <b className={`attach-bttn-game-c`}>
          {uploadLoading[game.game_id] ? <Spin /> : <TiUpload style={{color:"white"}}/>}
        </b>
      </Upload>
    );
  };

  const deleteTask = (row) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = games.filter((p) => p._id !== row._id);
          setGames(all);
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  return (
    <div>
      {games.map((game) => (
        <Card
          key={game._id}
          className={`game-card-tbs-cr ${
            assist.selectedGame?._id === game?._id ? "sel-game-attached" : ""
          }`}
        >
          <div className="close-icn-game">
            <Popconfirm
              title="Delete Game"
              description={
                <div>
                  <div>Are you sure you want to delete this game?</div>
                </div>
              }
              onConfirm={() => deleteTask(game, "data")}
              okText="Yes"
              cancelText="No"
            >
              <MdClose />
            </Popconfirm>
          </div>
          <h5>{game.title}</h5>
          <p>{game.instruction}</p>

          <div className="space-b-card-b">
            <div className="flex">
              <b
                onClick={() =>
                  setassist({
                    ...assist,
                    selectedGame: game,
                  })
                }
                className={`attach-bttn-game-c ${
                  assist.selectedGame?._id === game?._id
                    ? "sel-game-attached"
                    : ""
                }`}
              >
                <TiAttachmentOutline />
              </b>
              <b
                onClick={() => {
                  if (postLoading === undefined) {
                    setPostLoading(game._id);
                    handlePostGameId(game.game_id);
                  }
                }}
                className={`attach-bttn-game-c ${
                  assist.selectedGame?._id === game?._id
                    ? "sel-game-attached"
                    : ""
                }`}
              >
                {postLoading && postLoading === game._id ? (
                  <Spin />
                ) : (
                  <TbPlugConnected />
                )}
              </b>

              {handleUpload(game)}

              <b
                onClick={() => setPlusFeed(game)}
                className={`attach-bttn-game-c ${
                  plusFeed?._id === game?._id ? "sel-game-attached" : ""
                }`}
              >
                <FaEdit />
              </b>
              <b
                onClick={() => {
                  setLeft({
                    ...left,
                    flow: "search",
                    chatData: { ...game, _id: game.game_id },
                  });
                }}
                className={`attach-bttn-game-c ${
                  plusFeed?._id === game?._id ? "sel-game-attached" : ""
                }`}
              >
                <FaInfo />
              </b>
            </div>
            <div className="game-card-time">{moment(game.date).fromNow()}</div>
          </div>
        </Card>
      ))}
      {games && games.length === 0 ? (
        <div className="no-heigh-div-c ">
          <Alert
            message="No Games Exist"
            type="info"
            showIcon
            className="brand-alert bg-txt-a"
          />
        </div>
      ) : null}
      {pageNo < totalPages && (
        <Button onClick={loadMoreGames} disabled={loading} type="primary">
          {loading ? <Spin /> : "Load More"}
        </Button>
      )}
    </div>
  );
};

export default GameTab;

import React, { useContext, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import Icons from "../../../../components/Icons";
import { Alert, Button, Spin, Upload, notification } from "antd";
import { v4 as uuidv4 } from "uuid";
import { MdInsights, MdOutlineCreate, MdOutlineImageSearch } from "react-icons/md";

const { Dragger } = Upload;
let all = [];
export default function UploadP() {
  const {
    theme,
    setOrganization,
    organization,
    user,
    images,
    setImages,
    assist,
    setassist,
  } = useContext(SearchContext);
  const [UploadedFile, setUplaoded] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const props = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
      // console.log(.url);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };

  const handleBeforeUpload = (file, fileList) => {
    setFileList([file]); // save the file object in state for later use
    return false; // don't actually upload the file
  };
  const renderFileName = () => {
    if (fileList.length > 0) {
      const file = fileList[0];
      return (
        <>
          {imageUrl ? (
            <img className="img-prev-ibc" src={imageUrl} alt="" />
          ) : (
            "ssss"
          )}
        </>
      );
    } else {
      return <span>Upload</span>;
    }
  };

  const removeFile = () => {
    setFileList([]);
  };

  const saveFile = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.originFileObj);

    formData.append("purpose", "misc");
    formData.append("headclip_id", id);
    formData.append("media_type", "visual");
    formData.append("user_id", user ? user.user_id : "map");

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/upload`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setUplaoded([]);
        setFileList([]);
        setImageUrl();
        setLoading(false);

        if (data && data.isSuccess) {
          if (i + 1 === UploadedFile.length) {
            const allimgs = images && images.data ? images.data : [];
            allimgs.unshift(data.data);
            setImages({ ...images, data: allimgs });
            all.push(data.data);
            setassist({
              ...assist,
              upImage: undefined,
              uploadView: undefined,
              selAssetID: data.data,
              selAssetID2: undefined,
              isPopup: false,
            });

            updateOrganization("onboard", "describe");
            setOrganization({
              ...organization,
              onboard: "describe",
              compute: data.compute,
            });
          } else {
            all.push(data.data);
          }
        } else if (data.message === "Detected as adult or racy content") {
          notification.error({
            message:
              "Please upload another image. Unfortunately, this image didn't meet our NSFW guidelines ",
          });
        }
      });
  };

  const logout = () => {
    window.localStorage.removeItem("user");
    window.location.reload();
  };

  return (
    <div className="onb-main-bx">
      <div>
        <div className="onb-main-hv">
          <img
            src={Icons["white-logo"]}
            alt=""
            style={{ width: "30px", height: "30px" }}
          />
          <h3>Visual Understanding</h3>
        </div>
        <div className="upload-bx-cs-nm">
          <div className="icons-bx-main-ne">
            <div className="col">
              <b>Let’s test how good I can see. Upload an image.</b>
              <div className="icons-bx-main-ne">
                <div>
                  <MdOutlineImageSearch/>
                  <b>Search</b>
                </div>
                <div>
                  <MdOutlineCreate />
                  <b>Generate</b>
                </div>
               <div>
              <MdInsights />
              <b>Analyze</b>
            </div> 
              </div>
            </div>
            <div
              className="col"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Dragger
                showUploadList={false}
                maxCount={1}
                beforeUpload={handleBeforeUpload}
                {...props}
              >
                {renderFileName()}
              </Dragger>
            </div>
          </div>
        </div>
      </div>
      <div className="onb-m-bot">
        <Button
          onClick={() => {
            logout();
          }}
          type="primary"
        >
          Sign Out
        </Button>
        <div className="up-bx-mnbcs">
          {UploadedFile.length ? (
            <Button type="link" onClick={removeFile}>
              Change
            </Button>
          ) : null}
          {UploadedFile.length ? (
            loading ? (
              <Spin />
            ) : (
              <span>
                <Button
                  type="primary"
                  onClick={() => {
                    setLoading(true);
                    all = [];

                    let id = uuidv4();
                    for (let i = 0; i < UploadedFile.length; i++) {
                      saveFile(UploadedFile[i], i, id);
                    }
                  }}
                  className="glow-on-hover"
                >
                  Send
                </Button>
              </span>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
}

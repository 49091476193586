import { Button, Input, message, Modal, Spin } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React, { useContext, useState } from "react";
import { FaInfo } from "react-icons/fa";
import SearchContext from "../../../../Context/SearchContext";
import moment from "moment";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";

export default function AssetsEdit({ row, type }) {
  const { images, setImages, theme, setassist, assist } =
    useContext(SearchContext);
  const playerRef = React.useRef();

  const [display, setDisplay] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setloading] = useState(false);

  const updateTask = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setloading(false);
        if (data && data.isSuccess) {
          setIsUpdate(false);
          setDisplay();
          const all = images[type].map((m) =>
            m._id === data.imageData._id ? data.imageData : m
          );
          setImages({
            ...images,
            [type]: all,
          });
        } else {
          message.warning("Something Went Wrong");
        }
      });
  };
  return (
    <div>
      <FaInfo className="info-icon-assetedt" onClick={() => setDisplay(row)} />
      <Modal
        className="no-h-bo-b-modal brand-modal"
        open={display !== undefined}
        onCancel={() => {
          setDisplay();
        }}
        width={450}
      >
        {display ? (
          <div style={{marginTop:"0pxx"}} className=" form-pf">
            <div className="game-show-p1">
              <div>
                <div className="video-top-right video-proper-v">
                  {display.media_type === "sound" ? (
                    <ReactAudioPlayer
                      src={display.file_url}
                      autoPlay
                      controls
                    />
                  ) : display.media_type === "image" ? (
                    <img src={display.file_url} alt="" />
                  ) : (
                    <ReactPlayer
                      url={display.file_url}
                      controls
                      ref={playerRef}
                      playing={display !== undefined}
                      width="100%"
                      height="auto"
                    />
                  )}
                </div>
                {moment(display.createdAt).format("lll")}

                <div>
                  <label
                    style={{ margin: "15px 0px" }}
                    for="name"
                    class="label-pf"
                  >
                    <span class="title-pf">Name *</span>
                    <Input.TextArea
                      autoSize={{ minRows: 1, maxRows: 2 }}
                      placeholder="Name"
                      onChange={(e) => {
                        setDisplay({
                          ...display,

                          name: e.target.value,
                        });
                        setIsUpdate(true);
                      }}
                      value={display.name}
                      style={{ width: "100%" }}
                      size="large"
                    />
                  </label>
                  <label
                    style={{ margin: "15px 0px" }}
                    for="name"
                    class="label-pf"
                  >
                    <span class="title-pf">Description *</span>
                    <Input.TextArea
                      autoSize={{ minRows: 1, maxRows: 6 }}
                      placeholder="Description"
                      onChange={(e) => {
                        setDisplay({
                          ...display,

                          description: e.target.value,
                        });
                        setIsUpdate(true);
                      }}
                      value={display.description}
                      style={{ width: "100%" }}
                      size="large"
                    />
                  </label>
                </div>
                {isUpdate ? (
                  <div className="save-btn-pl flex-end">
                    {loading ? (
                      <Spin />
                    ) : (
                      <button
                        onClick={() => {
                          setloading(true);
                          updateTask(
                            {
                              name: display.name,
                              description: display.description,
                            },
                            display._id
                          );
                        }}
                        size="large"
                        className="custom-button orange-btn-cs"
                        style={{
                          maxWidth: "300px",
                        }}
                      >
                        Update
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}

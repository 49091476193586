import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Alert, Tooltip } from "antd";
import SearchContext from "../../../../Context/SearchContext";
import Paragraph from "antd/es/typography/Paragraph";
import { motion } from "framer-motion";
import { FaPlay } from "react-icons/fa";

export default function WebGenerated() {
  const { left } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return loading ? null : (
    <div className={` chat-bx-mn  `}>
      <div className="act-logs-fcv">
        <Row>
          {left.chatData?.clip_data &&
            left.chatData.clip_data.map((im, imi) => {
              return (
                <Col key={imi} span={22}>
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5, left: -150 }}
                    animate={{ opacity: 1, scale: 1, left: 0 }}
                    transition={{
                      duration: 0.5,
                      delay: (imi + 3) / 10,
                    }}
                    className="img-txt-bx-bs"
                  >
                    <div className={`img-bx-imgsc `}>
                      <div className="img-bx-mnb">
                        <div>
                          <img src={im.file_url} alt="" />
                        </div>
                      </div>
                      <div className="ganerate-web-bcs">
                        <div className="para-play-btvs">
                          <Paragraph
                            ellipsis={{
                              rows: 2,
                              // expandable: true,
                              symbol: "see more",
                            }}
                          >
                            {im.overall_topic
                              ? im.overall_topic
                              : "No Clear Headline"}
                          </Paragraph>
                          <a
                            target="blank"
                            href={
                              im.platform?.toLowerCase() === "spotify"
                                ? im.url + `?t=${im.startTime}`
                                : `` + im.platform?.toLowerCase() === "youtube"
                                ? im.url + `&t=${im.startTime}s`
                                : `` + im.platform?.toLowerCase() !==
                                    "spotify" &&
                                  im.platform?.toLowerCase() !== "youtube"
                                ? im.url
                                : ""
                            }
                          >
                            <div class="container-i">
                              <FaPlay />
                            </div>
                          </a>
                        </div>
                        <div className="main-bx-desc-web">
                          <Tooltip
                            color="rgb(240, 240, 240)"
                            placement="right"
                            title={im.summary}
                          >
                            <b className="create-btn-h43">Describe</b>
                          </Tooltip>
                          <div className="flex-end-bx-b"><span>{im.startTime}</span></div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </Col>
              );
            })}
        </Row>
        {left.chatData?.clip_data && left.chatData.clip_data.length === 0 ? (
          <div className="no-heigh-div-c ">
            <Alert
              message="No Assets Exist"
              type="info"
              showIcon
              className="brand-alert bg-txt-a"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
